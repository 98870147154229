export const default_button_text = (intl) => [
  {
    value: "Learn More",
    text: intl.formatMessage({
      id: "CREATIVE_BTN_LEARN_MORE",
      defaultMessage: "Learn More",
    }),
  },
  {
    value: "Buy Now",
    text: intl.formatMessage({
      id: "CREATIVE_BTN_BUY_NOW",
      defaultMessage: "Buy Now",
    }),
  },
  {
    value: "Install",
    text: intl.formatMessage({
      id: "CREATIVE_BTN_INSTALL",
      defaultMessage: "Install",
    }),
  },
  {
    value: "Order Now",
    text: intl.formatMessage({
      id: "CREATIVE_BTN_ORDER_NOW",
      defaultMessage: "Order Now",
    }),
  },
  {
    value: "Shop Now",
    text: intl.formatMessage({
      id: "CREATIVE_BTN_SHOP_NOW",
      defaultMessage: "Shop Now",
    }),
  },
  {
    value: "Contact Us",
    text: intl.formatMessage({
      id: "CREATIVE_BTN_CONTACT_US",
      defaultMessage: "Contact Us",
    }),
  },
  {
    value: "Apply Now",
    text: intl.formatMessage({
      id: "CREATIVE_BTN_APPLY_NOW",
      defaultMessage: "Apply Now",
    }),
  },
  {
    value: "Sign Up",
    text: intl.formatMessage({
      id: "CREATIVE_BTN_SIGN_UP",
      defaultMessage: "Sign Up",
    }),
  },
  {
    value: "Book Now",
    text: intl.formatMessage({
      id: "CREATIVE_BTN_BOOK_NOW",
      defaultMessage: "Book Now",
    }),
  },
];

export const mraid_types = (intl) => [
  {
    value: "static",
    text: intl.formatMessage({
      id: "MRAID_TYPE_BANNER",
      defaultMessage: "Banner",
    }),
  },
  {
    value: "expandable",
    text: intl.formatMessage({
      id: "MRAID_TYPE_EXPANDABLE",
      defaultMessage: "Expandable",
    }),
  },
  {
    value: "interstitial",
    text: intl.formatMessage({
      id: "MRAID_TYPE_INTERSTITIAL",
      defaultMessage: "Interstitial",
    }),
  },
];

export const ad_servers = (intl) => [
  {
    value: "ATLAS",
    text: "ATLAS",
  },
  {
    value: "DART",
    text: "DFA",
  },
  {
    value: "EYEWONDER",
    text: "EyeWonder",
  },
  {
    value: "MEDIAFORGE",
    text: "mediaFORGE",
  },
  {
    value: "MEDIAPLEX",
    text: "Mediaplex",
  },
  {
    value: "POINTROLL",
    text: "Pointroll",
  },
  {
    value: "MEDIAMIND",
    text: "Sizmek MDX",
  },
  {
    value: "YIELD_MANAGER",
    text: "Yield Manager",
  },
  {
    value: "OTHER",
    text: intl.formatMessage({
      id: "LABEL_OTHER",
      defaultMessage: "Other",
    }),
  },
];

export const tag_types = (intl) => [
  {
    value: "SCRIPT",
    text: intl.formatMessage({
      id: "TAG_TYPE_JAVASCRIPT",
      defaultMessage: "Javascript",
    }),
  },
  {
    value: "IFRAME",
    text: intl.formatMessage({
      id: "TAG_TYPE_IFRAME",
      defaultMessage: "Iframe",
    }),
  },
  {
    value: "IMG",
    text: intl.formatMessage({
      id: "TAG_TYPE_IMAGE",
      defaultMessage: "Image",
    }),
  },
];

export const expansion_triggers = (intl) => [
  {
    value: "AUTOMATIC",
    text: intl.formatMessage({
      id: "EXPANSION_TRIGGER_AUTOMATIC",
      defaultMessage: "Automatic",
    }),
  },
  {
    value: "MOUSEOVER",
    text: intl.formatMessage({
      id: "EXPANSION_TRIGGER_MOUSEOVER",
      defaultMessage: "Mouse-Over to Expand",
    }),
  },
  {
    value: "CLICK",
    text: intl.formatMessage({
      id: "EXPANSION_TRIGGER_CLICK",
      defaultMessage: "Click to Expand",
    }),
  },
];

export const expansion_directions = (intl) => [
  {
    value: "NONRESTRICTED",
    text: intl.formatMessage({
      id: "EXPANSION_DIRECTION_UNRESTRICTED",
      defaultMessage: "Unrestricted",
    }),
  },
  {
    value: "L",
    text: intl.formatMessage({
      id: "EXPANSION_DIRECTION_LEFT",
      defaultMessage: "Left",
    }),
  },
  {
    value: "R",
    text: intl.formatMessage({
      id: "EXPANSION_DIRECTION_RIGHT",
      defaultMessage: "Right",
    }),
  },
  {
    value: "U",
    text: intl.formatMessage({
      id: "EXPANSION_DIRECTION_UP",
      defaultMessage: "Up",
    }),
  },
  {
    value: "D",
    text: intl.formatMessage({
      id: "EXPANSION_DIRECTION_DOWN",
      defaultMessage: "Down",
    }),
  },
  {
    value: "LU",
    text: intl.formatMessage({
      id: "EXPANSION_DIRECTION_UP_LEFT",
      defaultMessage: "Up & Left",
    }),
  },
  {
    value: "RU",
    text: intl.formatMessage({
      id: "EXPANSION_DIRECTION_UP_RIGHT",
      defaultMessage: "Up & Right",
    }),
  },
  {
    value: "LD",
    text: intl.formatMessage({
      id: "EXPANSION_DIRECTION_DOWN_LEFT",
      defaultMessage: "Down & Left",
    }),
  },
  {
    value: "RD",
    text: intl.formatMessage({
      id: "EXPANSION_DIRECTION_DOWN_RIGHT",
      defaultMessage: "Down & Right",
    }),
  },
];

export const dimension_types_expandable_yes = [
  {
    value: "120x600",
    text: "120x600",
  },
  {
    value: "160x600",
    text: "160x600",
  },
  {
    value: "300x250",
    text: "300x250",
  },
  {
    value: "468x60",
    text: "468x60",
  },
  {
    value: "728x90",
    text: "728x90",
  },
  {
    value: "234x60",
    text: "234x60",
  },
  {
    value: "120x60",
    text: "120x60",
  },
  {
    value: "180x150",
    text: "180x150",
  },
  {
    value: "300x600",
    text: "300x600",
  },
];

export const dimension_types_mraid_yes = [
  {
    value: "320x480",
    text: "320x480",
  },
  {
    value: "320x50",
    text: "320x50",
  },
  {
    value: "300x75",
    text: "300x75",
  },
  {
    value: "300x50",
    text: "300x50",
  },
  {
    value: "216x54",
    text: "216x54",
  },
  {
    value: "216x36",
    text: "216x36",
  },
  {
    value: "168x42",
    text: "168x42",
  },
  {
    value: "168x28",
    text: "168x28",
  },
  {
    value: "120x30",
    text: "120x30",
  },
  {
    value: "120x20",
    text: "120x20",
  },
];

export const dimension_types_mraid_no = [
  {
    value: "468x60",
    text: "468x60",
  },
  {
    value: "728x90",
    text: "728x90",
  },
  {
    value: "450x50",
    text: "450x50",
  },
  {
    value: "425x600",
    text: "425x600",
  },
  {
    value: "336x280",
    text: "336x280",
  },
  {
    value: "300x250",
    text: "300x250",
  },
  {
    value: "250x250",
    text: "250x250",
  },
  {
    value: "234x60",
    text: "234x60",
  },
  {
    value: "200x200",
    text: "200x200",
  },
  {
    value: "180x150",
    text: "180x150",
  },
  {
    value: "160x600",
    text: "160x600",
  },
  {
    value: "120x600",
    text: "120x600",
  },
  {
    value: "120x90",
    text: "120x90",
  },
];

export const file_types = (intl) => [
  {
    value: "gif",
    text: "GIF",
  },
  {
    value: "html5",
    text: "HTML5",
  },
  {
    value: "swf",
    text: "SWF",
  },
  {
    value: "jpg",
    text: "JPG",
  },
  {
    value: "unknown",
    text: intl.formatMessage({
      id: "LABEL_UNKNOWN",
      defaultMessage: "Unknown",
    }),
  },
];

export const approval_statuses = (intl) =>
  new Map([
    [
      "approved",
      intl.formatMessage({
        id: "CREATIVE_APPROVAL_STATUS_APPROVED",
        defaultMessage: "Approved",
      }),
    ],
    [
      "rejected",
      intl.formatMessage({
        id: "CREATIVE_APPROVAL_STATUS_REJECTED",
        defaultMessage: "Rejected",
      }),
    ],
  ]);
