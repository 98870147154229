import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { useIntl } from "react-intl";
import { Button, Divider, Dropdown, Form } from "semantic-ui-react";

import { useDebounce, useForm } from "../../../libs/component_utils";
import HttpConnect from "../../../libs/http_connect";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import { Config } from "../../../config/api";
import AgenciesService from "../../../services/agencies";
import DecimalInput from "../../common/decimalInput";

/**
 * Margin Control create/edit form
 * @return {*}
 * @constructor
 */
const MarginForm = ({
  initialData,
  loading,
  onSubmit,
  onCancel,
  formType = "create",
  submitText = "Upload Device ID",
}) => {
  const intl = useIntl();

  const services = useRef(new Map([["agencies", new AgenciesService()]]));
  const editMode = formType.toString() === "edit";

  // loaded page data
  const [pageData, setPageData] = useState(() => {
    return {
      agenciesLoading: !editMode,
      agencies: [],
    };
  });
  const initialAgencies = useRef(null);

  /**
   * form submit handler
   * @return {boolean}
   */
  const addMarginForm = async () => {
    await onSubmit(values);
  };

  const {
    values,
    errors,
    onChange,
    onSubmit: handleSubmit,
  } = useForm(addMarginForm, initialData, () => {
    const errors = {};

    if (!values.agency_id) {
      errors.agency_id = intl.formatMessage({
        id: "ERROR_NULL_AGENCY_ID",
        defaultMessage: "Please select an agency.",
      });
    }

    return errors;
  });

  /**
   * Handle advertiser dropdown × button click.
   * If called directly, just reset advetisers.
   * @param {Event} e
   */
  const resetAgencies = (e) => {
    if (
      initialAgencies.current === null ||
      (e && !(e.target.tagName.toLowerCase() === "i" && e.target.classList.contains("clear")))
    ) {
      return;
    }

    HttpConnect.cancelRequest();

    setPageData({
      ...pageData,
      agenciesLoading: false,
      agencies: initialAgencies.current,
    });
  };

  // Attach reset handlers:
  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on initial render
  useLayoutEffect(() => {
    const agencyDropdown = document.querySelector('.dropdown[name="agency_id"]');
    if (!agencyDropdown) return;

    agencyDropdown.addEventListener("click", resetAgencies);

    return () => {
      agencyDropdown.removeEventListener("click", resetAgencies);
    };
  }, []);

  const performSearch = (query) => {
    const trimmedQuery = query.trim();
    if (trimmedQuery === "" && initialAgencies.current !== null) {
      return resetAgencies();
    }

    /** @type AgenciesService */
    const service = services.current.get("agencies");
    (async () => {
      HttpConnect.cancelRequest();
      setPageData({
        ...pageData,
        agenciesLoading: true,
      });
      const response = await service.fetchAvailableAgencies(trimmedQuery);

      const formatted_data = response.data.map(({ id, title }) => ({
        key: id,
        text: title,
        value: id,
      }));

      if (trimmedQuery === "" && initialAgencies.current === null) {
        initialAgencies.current = formatted_data;
      }

      setPageData({
        ...pageData,
        agenciesLoading: false,
        agencies: formatted_data,
      });
    })();
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on initial render
  useEffect(() => {
    if (!editMode) performSearch("");
  }, []);

  const performSearchDebounced = useDebounce(Config.search_debounce_delay, performSearch);

  return (
    <Form
      onSubmit={handleSubmit}
      size="small"
      loading={loading}
      noValidate
      error={!!Object.keys(errors).length}
      autoComplete="off"
    >
      <Form.Field
        inline
        error={Boolean(errors.agency_id)}
        required
      >
        <label>
          {intl.formatMessage({
            id: "LABEL_AGENCY",
            defaultMessage: "Agency",
          })}
        </label>

        {editMode ? (
          <span>{values.agency_name}</span>
        ) : (
          <Dropdown
            required
            search
            selection
            clearable
            options={pageData.agencies}
            loading={pageData.agenciesLoading}
            placeholder={intl.formatMessage({
              id: "HINT_AGENCY",
              defaultMessage: "Select agency",
            })}
            name="agency_id"
            value={values.agency_id}
            onChange={onChange}
            onSearchChange={(_, { searchQuery }) => {
              performSearchDebounced(searchQuery);
            }}
          />
        )}
        <div className="custom-error">{errors.agency_id}</div>
      </Form.Field>

      <Form.Field
        inline
        error={Boolean(errors.additional_margin_pct)}
        required
      >
        <label>
          {intl.formatMessage({
            id: "LABEL_ADDITIONAL_MARGIN_PCT",
            defaultMessage: "Additional Margin %",
          })}
        </label>
        <DecimalInput
          name="additional_margin_pct"
          label="%"
          labelPosition="right"
          required
          min={0.0001}
          max={1000}
          step={0.0001}
          places={4}
          defaultValue={values.additional_margin_pct}
          onBlur={onChange}
        />
        <div className="custom-error">{errors.additional_margin_pct}</div>
      </Form.Field>

      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Form.Field align="right">
        <Button
          size="tiny"
          type="button"
          onClick={onCancel}
        >
          {intl.formatMessage({
            id: "BTN_CANCEL",
            defaultMessage: "Cancel",
          })}
        </Button>
        <Button
          size="tiny"
          color="green"
          type="submit"
        >
          {submitText}
        </Button>
      </Form.Field>
    </Form>
  );
};
MarginForm.propTypes = {
  formType: PropTypes.string,
};

export default MarginForm;
