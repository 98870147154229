import AbstractService from "../abstract.service";

export default class CreativesService extends AbstractService {
  static URI_STATES = new Map([
    ["creatives.data", "/creatives-data"],
    ["creatives.list_by_agency", "/list-creatives/{0}"],
    ["creatives.concepts_by_agency", "/creatives/{0}"],
    ["creatives.get", "/creative/{0}"],
    ["creatives.put", "/creative/{0}"],
    ["creatives.post", "/creative"],
    ["creatives.preview", "/preview-creative/{0}"],
    ["creatives.video.post", "/video-creative"],
    ["creatives.video.put", "/video-creative/{0}"],
    ["creatives.video.get", "/video-creative/{0}"],
    ["creatives.display.post", "/display-creative"],
    ["creatives.display.put", "/display-creative/{0}"],
    ["creatives.display.get", "/display-creative/{0}"],
    ["local_creatives.list_by_agency", "/build-creatives/{0}"],
    ["local_creatives.get_list", "/build-creatives"],
    ["local_creatives.get_creative", "/build-creative/{0}"],
    ["local_creatives.put_creative", "/build-creative/{0}/review"],
    ["local_creatives.post", "/build-creative"],
    ["local_creatives.get_pending_amount", "/pending-creatives"],
  ]);

  /**
   * get pending creatives count
   * @return {Promise<>}
   */
  async getPendingAmount() {
    const now = new Date();
    const padStr = (num) => String(num).padStart(2, "0");

    const year = now.getFullYear();
    const month = padStr(now.getMonth() + 1);
    const day = padStr(now.getDate());
    const hours = padStr(now.getHours());
    const minutes = padStr(now.getMinutes());
    const seconds = padStr(now.getSeconds());

    const userLocalTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    const url = `${CreativesService.URI_STATES.get("local_creatives.get_pending_amount")}`;
    const params = { timestamp: userLocalTime };
    return await this.api_server.get(url, params);
  }

  /**
   * update local creative
   * @param {number} creative_id
   * @param {object} params
   * @returns {Promise<unknown>}
   */
  async updateLocal(creativeId, params) {
    return await this.api_server.post(
      CreativesService.URI_STATES.get("local_creatives.put_creative").format(creativeId),
      params,
    );
  }

  /**
   * post video-creative
   * @param {object} params
   * @return {Promise<>}
   */
  async createVideo(params) {
    return await this.api_server.post(CreativesService.URI_STATES.get("creatives.video.post"), params);
  }

  /**
   * get creative
   * @param {number} id
   * @return {Promise<>}
   */
  async getVideo(id) {
    return await this.api_server.get(CreativesService.URI_STATES.get("creatives.video.get").format(id));
  }

  /**
   * put creative
   * @param {number} id
   * @param {object} params
   * @return {Promise<>}
   */
  async updateVideo(id, params) {
    return await this.api_server.put(CreativesService.URI_STATES.get("creatives.video.put").format(id), params);
  }

  /**
   * post display creative
   * @param {object} params
   * @return {Promise<>}
   */
  async createDisplay(params) {
    return await this.api_server.post(CreativesService.URI_STATES.get("creatives.display.post"), params);
  }

  /**
   * get display creative
   * @param {number} id
   * @return {Promise<>}
   */
  async getDisplay(id) {
    return await this.api_server.get(CreativesService.URI_STATES.get("creatives.display.get").format(id));
  }

  /**
   * put display creative
   * @param {number} id
   * @param {object} params
   * @return {Promise<>}
   */
  async updateDisplay(id, params) {
    return await this.api_server.put(CreativesService.URI_STATES.get("creatives.display.put").format(id), params);
  }

  /**
   * get video list of creatives by agency
   * @param {number} agency
   * @param {object} params
   * @return {Promise<>}
   */
  async videoList(agency, params) {
    return await this._listByAgency(agency, Object.assign(params, { creative_type: "video" }));
  }

  /**
   * get local creatives (onsite and in-store dooh) by agency
   * @param {number} agency
   * @param {object} params
   * @returns {Promise<unknown>}
   */
  async localCreativesByAgency(agency, params) {
    return await this.api_server.get(
      CreativesService.URI_STATES.get("local_creatives.list_by_agency").format(agency),
      params,
    );
  }

  /**
   * get creative approval list
   * @param {object} params
   * @return {Promise<>}
   */
  async getCreativeApprovalList(params) {
    return await this.api_server.get(CreativesService.URI_STATES.get("local_creatives.get_list"), params);
  }

  /**
   * get creative preview
   * @param {number} id
   * @returns {Promise<unknown>}
   */
  async getCreativePreview(id) {
    return await this.api_server.get(CreativesService.URI_STATES.get("creatives.preview").format(id));
  }

  /**
   * post local creative
   * @param {object} params
   * @returns {Promise<unknown>}
   */
  async createLocal(params) {
    return await this.api_server.post(CreativesService.URI_STATES.get("local_creatives.post"), params);
  }

  /**
   * Get creative approval by id
   * @param {number} id
   * @return {Promise<object>}
   */
  async getCreativeApproval(id) {
    return await this.api_server.get(CreativesService.URI_STATES.get("local_creatives.get_creative").format(id));
  }

  /**
   * get display list of creatives by agency
   * @param {number} agency
   * @param {object} params
   * @return {Promise<>}
   */
  async displayList(agency, params) {
    return await this._listByAgency(agency, Object.assign(params, { creative_type: "display" }));
  }

  /**
   * get list of concepts by agency
   * @param {number} agency
   * @param {object} params
   * @return {Promise<>}
   */
  async conceptsByAgency(agency, params) {
    return await this.api_server.get(
      CreativesService.URI_STATES.get("creatives.concepts_by_agency").format(agency),
      params,
    );
  }

  /**
   * post creative
   * @param {object} params
   * @return {Promise<>}
   */
  async create(params) {
    return await this.api_server.post(CreativesService.URI_STATES.get("creatives.post"), params);
  }

  /**
   * put creative
   * @param {number} id
   * @param {object} params
   * @return {Promise<>}
   */
  async update(id, params) {
    return await this.api_server.put(CreativesService.URI_STATES.get("creatives.put").format(id), params);
  }

  /**
   * Get creative by id
   * @param {number} id
   */
  async get(id) {
    return await this.api_server.get(CreativesService.URI_STATES.get("creatives.get").format(id), {});
  }

  /**
   * get list of creatives by agency
   * @param {number} agency
   * @param {object} params
   * @return {Promise<>}
   */
  async _listByAgency(agency, params) {
    return await this.api_server.get(
      CreativesService.URI_STATES.get("creatives.list_by_agency").format(agency),
      params,
    );
  }
}
