import { omit } from "lodash";
import { formatAPIDate } from "../libs/common_utils";
export default class ReportModel {
  constructor({
    type = null,
    start_date = null,
    end_date = null,
    advertiser_id = 0,
    campaign_ids = [],
    dimensions = [],
    metrics = [],
    output = "json",
    reporting_period = null,
    time_rollup = null,
  } = {}) {
    this.type = type;
    this.advertiser_id = advertiser_id;
    this.campaign_ids = campaign_ids;
    this.dimensions = dimensions;
    this.metrics = metrics;
    this.output = output;
    this.time_rollup = time_rollup;
    this.start_date = start_date;
    this.end_date = end_date;
    this.reporting_period = reporting_period;
  }

  /**
   * convert model into json format
   * reporting period is not needed if start_date and end_date are provided and vice versa
   * @return {object}
   */
  toJson() {
    const json = { ...this };
    const keysToOmit = [];
    if (this.reporting_period === "custom" && this.start_date && this.end_date) {
      json.start_date = formatAPIDate(this.start_date);
      json.end_date = formatAPIDate(this.end_date);
      keysToOmit.push("reporting_period");
    } else {
      keysToOmit.push("start_date");
      keysToOmit.push("end_date");
    }

    return omit(json, keysToOmit);
  }
}
