import PropTypes from "prop-types";
import { memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { isExists, isNil } from "../../../../libs/common_utils";

const CreativeRenderer = memo(({ creative, maxHeight = null, setFirstPartyCreativeDimension, maxWidth = null }) => {
  const [s3Preview, sets3Preview] = useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);

  const scaleFactor = useRef(1);

  if (maxWidth !== null && creative.width) {
    scaleFactor.current = Math.min(maxWidth / creative.width, scaleFactor.current);
  }

  if (maxHeight !== null && creative.height) {
    scaleFactor.current = Math.min(maxHeight / creative.height, scaleFactor.current);
  }

  useLayoutEffect(() => {
    window.addEventListener(
      "message",
      // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: refactor to remove complexity / calculations
      (e) => {
        const message = e.data;
        const iframe = document.getElementsByTagName("iframe")[0];
        const resizedDiv = document.getElementById("creative_resized_id");

        if (!Object.hasOwn(message, "banner")) {
          return;
        }

        if (resizedDiv) {
          resizedDiv.style.display = message.banner.scaleFactor < 1 ? "block" : "none";
        }

        document.querySelector(".creative-preview-container").classList.remove("tree-loading");

        const size = { width: message.banner.width, height: message.banner.height };
        size.width *= message.banner.scaleFactor < 1 ? message.banner.scaleFactor : 1;
        size.height *= message.banner.scaleFactor < 1 ? message.banner.scaleFactor : 1;

        iframe.style.width = size.width ? `${size.width}px` : "auto";
        iframe.style.height = size.height ? `${size.height}px` : "auto"; // sometimes scrollHeight we receive as 0, set default to auto
        iframe.classList.add("fade-in");
      },
      false,
    );
  }, []);

  useEffect(() => {
    if (isExists(creative, "display_asset")) {
      const img = new Image();

      img.onload = () => {
        scaleFactor.current = Math.min(maxHeight / img.height, scaleFactor.current);
        setFirstPartyCreativeDimension({ width: img.width, height: img.height });
        sets3Preview(
          <img
            alt={img.title}
            src={img.src}
            style={{ transform: scaleFactor.current < 1 ? `scale(${scaleFactor.current})` : "none" }}
          />,
        );
      };

      img.onerror = () => {
        sets3Preview(<div>Cannot load preview</div>);
      };

      img.src = creative.display_asset;
    }

    return () => {
      document.getElementById("creative_resized_id").style.display = "none";
    };
  }, [creative, maxHeight, setFirstPartyCreativeDimension]);

  useEffect(() => {
    if (creative?.video_asset) {
      setVideoPreviewUrl(creative.video_asset);
    }
  }, [creative?.video_asset]);

  const resizedDiv = document.getElementById("creative_resized_id");
  if (resizedDiv) {
    resizedDiv.style.display = scaleFactor.current < 1 ? "block" : "none";
  }

  if (!isNil(s3Preview)) {
    document.querySelector(".creative-preview-container").classList.remove("tree-loading");
    return s3Preview;
  }

  if (creative.ad_tag) {
    return (
      <iframe
        srcDoc={`<html lang="en-us"><body style="margin: 0; padding: 0; overflow: hidden;display:flex;align-items: center;justify-content: center;"><script>window.maxHeight=${maxHeight}; window.onload=function() {
        const scaleFactor = Math.min(window.maxHeight / (this.document.body.scrollHeight || 1));
        if (scaleFactor < 1) {
          /*
          window.document.body.style["transform"] = "scale(${scaleFactor})";
          window.document.body.style["-o-transform"] = "scale(${scaleFactor})";
          window.document.body.style["-webkit-transform"] = "scale(${scaleFactor})";
          window.document.body.style["-moz-transform"] = "scale(${scaleFactor})";
          console.log(window.document.body.style.transform);
          */
          window.document.head.insertAdjacentHTML("beforeend", "<style>body{transform-origin: 0 0; transform: scale(" + scaleFactor + ")}</style>");
        }

          parent.postMessage({"banner": {"scaleFactor": scaleFactor, "width": this.document.body.scrollWidth, "height": this.document.body.scrollHeight}}, "*");
        }; </script>${creative.ad_tag}</body></html>`}
        style={{
          border: "none",
          opacity: 0,
          height: "100%",
        }}
        title={`Ad by ${creative.advertiser_name}`}
      />
    );
  }

  if (videoPreviewUrl) {
    document.querySelector(".creative-preview-container").classList.remove("tree-loading");
    return (
      // biome-ignore lint/a11y/useMediaCaption: we need to figure out if/how to show a caption
      <video
        controls
        style={{ height: "95%" }}
      >
        <source
          src={videoPreviewUrl}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    );
  }
});

CreativeRenderer.propTypes = {
  creative: PropTypes.shape({
    // biome-ignore lint/style/useNamingConvention: payload attribute
    display_asset: PropTypes.string,
    // biome-ignore lint/style/useNamingConvention: payload attribute
    ad_tag: PropTypes.string,
    // biome-ignore lint/style/useNamingConvention: payload attribute
    advertiser_name: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    title: PropTypes.string.isRequired,
  }).isRequired,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  setFirstPartyCreativeDimension: PropTypes.func,
};

export { CreativeRenderer };
