import escapeRegExp from "lodash/escapeRegExp";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { Button, Dropdown, Form, Icon, List } from "semantic-ui-react";

import { arrayMove } from "../../../libs/common_utils";

const ColumnPicker = ({ options: columns, defaultOptions, value, onChange }) => {
  const intl = useIntl();

  const dropdownOptions = Object.values(columns).map(({ id, messageId, name }) => ({
    key: id,
    text: intl.formatMessage({
      id: messageId,
      defaultMessage: name,
    }),
    value: id,
  }));

  const customSearch = (options, query) => {
    const re = new RegExp(escapeRegExp(query.trim()), "i");
    return options.filter((opt) => opt.key !== "ALL_COLUMNS" && re.test(opt.text));
  };

  const remainingColumns = dropdownOptions.filter(({ key }) => !value.includes(key));

  const resetToDefault = () => onChange(isEqual(value, defaultOptions) ? [] : defaultOptions);

  return (
    <>
      <Form.Field className="filter-field">
        <label htmlFor="column-dropdown">
          {intl.formatMessage({
            id: "LABEL_COLUMNS",
            defaultMessage: "Columns",
          })}{" "}
          <Button
            compact
            size="mini"
            className="btn-clear"
            type="button"
            onClick={resetToDefault}
            style={{ margin: "-0.589286em 0.5625em" }}
          >
            {isEqual(value, defaultOptions)
              ? intl.formatMessage({
                  id: "BTN_CLEAR",
                  defaultMessage: "Clear",
                })
              : intl.formatMessage({
                  id: "BTN_RESET",
                  defaultMessage: "Reset",
                })}
          </Button>
        </label>
        <Dropdown
          id="column-dropdown"
          fluid
          placeholder={intl.formatMessage(
            {
              id: "LABEL_COLUMNS_COUNTER",
              defaultMessage: `{count, plural,
								=0 {Select metrics and dimensions}
								one {# column}
								other {# columns}
							}`,
            },
            { count: value.length },
          )}
          multiple
          search={customSearch}
          selection
          clearable
          options={
            remainingColumns.length > 1
              ? [
                  {
                    key: "ALL_COLUMNS",
                    text: intl.formatMessage({
                      id: "VALUE_ALL_COLUMNS",
                      defaultMessage: "All columns",
                    }),
                    value: "ALL_COLUMNS",
                  },
                  ...remainingColumns,
                ]
              : remainingColumns
          }
          noResultsMessage={
            remainingColumns.length
              ? intl.formatMessage({
                  id: "EMPTY_SEARCH_RESULTS",
                  defaultMessage: "No results found",
                })
              : intl.formatMessage({
                  id: "EMPTY_COLUMNS",
                  defaultMessage: "No more columns available",
                })
          }
          value={[]}
          onChange={(_e, input) => {
            onChange(
              input.value.includes("ALL_COLUMNS")
                ? value.concat(remainingColumns.map(({ key }) => key))
                : value.concat(input.value),
            );
          }}
        />
      </Form.Field>
      <ColumnList
        items={value}
        options={columns}
        useDragHandle
        lockAxis="y"
        onSortEnd={({ oldIndex, newIndex }) => onChange(arrayMove(value, oldIndex, newIndex))}
        onRemove={(columnId) => onChange(value.filter((x) => x !== columnId))}
      />
    </>
  );
};
ColumnPicker.propTypes = {
  options: PropTypes.object.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ColumnList = sortableContainer(({ items, options, onRemove }) => {
  const intl = useIntl();
  return (
    <List>
      {items.map((columnId, i) => (
        <ColumnItem
          key={columnId}
          index={i}
          text={intl.formatMessage({
            id: options[columnId].messageId,
            defaultMessage: options[columnId].name,
          })}
          onRemove={() => onRemove(columnId)}
        />
      ))}
    </List>
  );
});

ColumnList.propTypes = {
  items: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

/**
 * Wraps children in a draggable handler block with some sane CSS defaults.
 * Takes the same props as regular <div />.
 */
const DragHandle = sortableHandle(({ style = {}, children, ...props }) => (
  <div
    style={{
      ...style,
      userSelect: "none",
      // biome-ignore lint/style/useNamingConvention: <not sure what this is...>
      MozUserSelect: "none",
      cursor: "grab",
    }}
    {...props}
  >
    {children}
  </div>
));

const ColumnItem = sortableElement(({ text, onRemove }) => (
  <List.Item style={{ display: "flex" }}>
    <DragHandle style={{ flex: 1, display: "flex" }}>
      <Icon>
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
          height="16"
        >
          <path
            fill="currentColor"
            d="M96 32H32C14 32 0 46 0 64v64c0 18 14 32 32 32h64c18 0 32-14 32-32V64c0-18-14-32-32-32zm0 160H32c-18 0-32 14-32 32v64c0 18 14 32 32 32h64c18 0 32-14 32-32v-64c0-18-14-32-32-32zm0 160H32c-18 0-32 14-32 32v64c0 18 14 32 32 32h64c18 0 32-14 32-32v-64c0-18-14-32-32-32zM288 32h-64c-18 0-32 14-32 32v64c0 18 14 32 32 32h64c18 0 32-14 32-32V64c0-18-14-32-32-32zm0 160h-64c-18 0-32 14-32 32v64c0 18 14 32 32 32h64c18 0 32-14 32-32v-64c0-18-14-32-32-32zm0 160h-64c-18 0-32 14-32 32v64c0 18 14 32 32 32h64c18 0 32-14 32-32v-64c0-18-14-32-32-32z"
          />
        </svg>
      </Icon>

      <div style={{ flex: 1 }}>{text}</div>
    </DragHandle>

    <Icon
      name="x"
      onClick={onRemove}
      style={{ cursor: "pointer" }}
    />
  </List.Item>
));
ColumnItem.propTypes = {
  text: PropTypes.node.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default ColumnPicker;
