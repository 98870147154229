import { useState } from "react";
import DatePicker from "react-datepicker";
import { useIntl } from "react-intl";
import { Form } from "semantic-ui-react";
import { Config } from "../../config/api";

const StartEndDateFormField = ({ values, updateValues, isEditMode, error, shouldFilterStartPassedTime = true }) => {
  const intl = useIntl();
  const now = new Date();
  const [hasInitialStartDate] = useState(() => Boolean(values.start_date));
  const [hasInitialEndDate] = useState(() => Boolean(values.end_date));

  const filterStartPassedTime = (time) => {
    const selectedDate = new Date(time);
    return now.getTime() < selectedDate.getTime();
  };

  const filterEndPassedTime = (time) => {
    const selectedDate = new Date(time);

    if (values.start_date) {
      return values.start_date.getTime() < selectedDate.getTime();
    }

    return now.getTime() < selectedDate.getTime();
  };

  /**
   * If a Creative was created with a start or end date, the user should not be able to clear it
   * This is to match the behavior of it in T1
   */
  const preventEditing = (e, hasInitialDate, isEditMode) => {
    if (hasInitialDate && isEditMode) {
      if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === "x") {
        e.preventDefault();
      }
      if (e.key === "Backspace" || e.key === "Delete") {
        e.preventDefault();
      }
    }
  };

  return (
    <Form.Field
      inline
      error={Boolean(error)}
    >
      <label>
        {intl.formatMessage({
          id: "LABEL_START_END_DATE",
          defaultMessage: "Start/End Date",
        })}
      </label>
      <div className="ui input multi-fields">
        <DatePicker
          filterTime={shouldFilterStartPassedTime ? filterStartPassedTime : false}
          selected={values.start_date}
          minDate={now}
          maxDate={values.end_date ? values.end_date : null}
          onChange={(date) => {
            // biome-ignore lint/style/useNamingConvention: <other values are in snake_case for api>
            updateValues({ ...values, start_date: date });
          }}
          showTimeSelect
          timeFormat={Config.timeFormat}
          timeIntervals={15}
          timeCaption="time"
          dateFormat={Config.fullDateTimeFormat}
          onKeyDown={(e) => preventEditing(e, hasInitialStartDate, isEditMode)}
          required={isEditMode && hasInitialStartDate}
        />
        <DatePicker
          selected={values.end_date}
          minDate={values.start_date ? values.start_date : now}
          filterTime={filterEndPassedTime}
          onChange={(date) => {
            // biome-ignore lint/style/useNamingConvention: <other values are in snake_case for api>
            updateValues({ ...values, end_date: date });
          }}
          showTimeSelect
          timeFormat={Config.timeFormat}
          timeIntervals={15}
          timeCaption="time"
          dateFormat={Config.fullDateTimeFormat}
          onKeyDown={(e) => preventEditing(e, hasInitialEndDate, isEditMode)}
          required={isEditMode && hasInitialEndDate}
        />
      </div>
      <div className="custom-error">{error}</div>
    </Form.Field>
  );
};

export default StartEndDateFormField;
