import { useIntl } from "react-intl";
import { Icon, Segment } from "semantic-ui-react";

const OnSiteCreativeStatusChangeMessage = () => {
  const intl = useIntl();

  return (
    <Segment secondary>
      <Icon name="attention" />
      {intl.formatMessage({
        defaultMessage: "This is an approved onsite or in-store DOOH creative. Only a status change is permitted.",
        id: "NOTE_ONSITE_INSTORE_DOOH_CREATIVE_STATUS_CHANGE",
      })}
    </Segment>
  );
};

export default OnSiteCreativeStatusChangeMessage;
