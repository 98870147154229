import { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Divider, Header, Message, Segment } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import { useFlags } from "launchdarkly-react-client-sdk";
import { parseAPIDateTime } from "../../../libs/common_utils.js";
import CreativesService from "../../../services/creatives/index.js";
import CreativeDisplayForm from "../form/display";
import CreativeVideoForm from "../form/video";
/**
 * Creative edit page component
 * @param {object} history
 * @param {object} match
 * @param {object} match.params
 * @param {number} match.params.creative_id
 * @param {string} creative_type
 * @return {*}
 * @constructor
 */
const CreativeEditPage = ({ history, match, creative_type }) => {
  const [serverError, setServerError] = useState("");
  const [initialData, setInitialData] = useState(null);
  const [loading, setLoading] = useState(false);

  const _isMounted = useRef(false);
  const { whitelabelDoubleCreativeApproval } = useFlags();
  const isVideo = creative_type === "video";
  const backUrl = isVideo ? "/creatives/video" : "/creatives/display";
  const creativeId = Number.parseInt(match.params.creative_id, 10) || 0;
  const creativesService = new CreativesService();
  const intl = useIntl();

  /**
   * navigate user back
   */
  const handleCancel = useCallback(() => {
    history.push(backUrl);
  }, [backUrl, history]);

  /**
   * handle 404
   */
  const handle404 = () => {
    history.push(backUrl, { action: "error", msg: "No creative found" });
  };

  /**
   * form submit handler
   * @return {boolean}
   */
  const handleSubmit = async (params) => {
    setLoading(true);
    try {
      let r;
      if (isVideo) {
        r = await creativesService.updateVideo(creativeId, params);
      } else {
        r = await creativesService.updateDisplay(creativeId, params);
      }

      history.push(backUrl, { action: "updated", name: r.data?.title || "Unknown" });
    } catch (e) {
      setServerError(e.error.message);
    } finally {
      if (_isMounted.current) {
        setLoading(false);
      }
    }
  };

  if (creativeId < 1) {
    handle404();
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    _isMounted.current = true;
    (async () => {
      try {
        let r;
        if (isVideo) {
          r = await creativesService.getVideo(creativeId);
        } else {
          r = await creativesService.getDisplay(creativeId);
        }
        if (r.data.start_date) {
          r.data.start_date = parseAPIDateTime(r.data.start_date);
        }
        if (r.data.end_date) {
          r.data.end_date = parseAPIDateTime(r.data.end_date);
        }
        setInitialData(r.data);
      } catch (_) {
        handle404();
      }
    })();

    return () => {
      _isMounted.current = false;
    };
  }, [creativeId]);

  if (initialData === null) {
    return (
      <Segment
        className="loading"
        disabled
        tertiary
        textAlign="center"
      >
        &nbsp;
      </Segment>
    );
  }

  return (
    <Segment basic>
      <Header as="h2">
        {isVideo
          ? intl.formatMessage({
              defaultMessage: "Edit Video Creative",
              id: "HEADING_EDIT_VIDEO_CREATIVE",
            })
          : intl.formatMessage({
              defaultMessage: "Edit Display Creative",
              id: "HEADING_EDIT_DISPLAY_CREATIVE",
            })}
      </Header>
      <Divider />
      <Message
        content={serverError}
        error
        hidden={!serverError}
        size="tiny"
        style={{ marginTop: "10px" }}
      />
      {isVideo ? (
        <CreativeVideoForm
          formType="edit"
          initialData={initialData}
          loading={loading}
          onCancel={handleCancel}
          onsite_enabled={whitelabelDoubleCreativeApproval}
          onSubmit={handleSubmit}
          submitText={intl.formatMessage({
            defaultMessage: "Save Changes",
            id: "BTN_SAVE_CHANGES",
          })}
        />
      ) : (
        <CreativeDisplayForm
          formType="edit"
          initialData={initialData}
          loading={loading}
          onCancel={handleCancel}
          onsite_enabled={whitelabelDoubleCreativeApproval}
          onSubmit={handleSubmit}
          submitText={intl.formatMessage({
            id: "BTN_SAVE_CHANGES",
            defaultMessage: "Save Changes",
          })}
        />
      )}
    </Segment>
  );
};

export default CreativeEditPage;
