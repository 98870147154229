export default class CampaignsBulkEditModel {
  constructor({
    ids = null,
    status = null,
    frequencyOptimization = null,
    frequencyType = null,
    frequencyAmount = null,
    frequencyInterval = null,
  } = {}) {
    this.ids = ids;
    this.status = status !== null ? Number.parseInt(status) : null;
    this.frequency_optimization = frequencyOptimization;
    this.frequency_type = frequencyType;
    this.frequency_amount = frequencyAmount !== null ? Number.parseInt(frequencyAmount) : null;
    this.frequency_interval = frequencyInterval;

    for (const key of Object.keys(this)) {
      if (this[key] === null) {
        delete this[key];
      }
    }
  }
}
