import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Form, Input, TextArea } from "semantic-ui-react";

const VideoCreativeThirdPartyComponent = ({ values, errors, onChange }) => {
  const intl = useIntl();
  return (
    <>
      <Form.Field
        error={Object.hasOwn(errors, "custom_vast_url")}
        inline
        required
      >
        <label>
          {intl.formatMessage({
            defaultMessage: "VAST URL",
            id: "LABEL_VAST_URL",
          })}
        </label>
        <div style={{ display: "inline-block" }}>
          <TextArea
            defaultValue={values.custom_vast_url}
            name="custom_vast_url"
            onBlur={onChange}
            placeholder={intl.formatMessage({
              id: "PLACEHOLDER_INSERT_VAST_URL",
              defaultMessage: "Insert VAST URL here",
            })}
            required
            rows="5"
            style={{ width: "650px" }}
          />
        </div>
        <div className="custom-error">{errors.custom_vast_url}</div>
      </Form.Field>

      <Form.Field inline>
        <label>
          {intl.formatMessage({
            defaultMessage: "3PAS TAG ID",
            id: "LABEL_3PAS_TAG_ID",
          })}
        </label>
        <Input
          defaultValue={values.external_identifier}
          maxLength="64"
          name="external_identifier"
          onBlur={onChange}
          placeholder={intl.formatMessage({
            defaultMessage: "Placement id for the tag in 3rd party server...",
            id: "PLACEHOLDER_3PAS_TAG_ID_VIDEO_CREATIVE",
          })}
          style={{ width: "300px" }}
        />
      </Form.Field>
      <Form.Field
        error={Object.hasOwn(errors, "landing_page_url")}
        inline
        required
      >
        <label>
          {intl.formatMessage({
            defaultMessage: "Landing Page URL",
            id: "LABEL_LANDING_PAGE_URL",
          })}
        </label>
        <Input
          defaultValue={values.landing_page_url}
          name="landing_page_url"
          onBlur={onChange}
          pattern="https?://.*\..*"
          placeholder="http://www.example.com/"
          required
          style={{ width: "300px" }}
          title={intl.formatMessage({
            id: "ERROR_URL_PATTERN",
            defaultMessage: "must be a valid non-local HTTP URL",
          })}
          type="url"
        />
        <div className="custom-error">{errors.landing_page_url}</div>
      </Form.Field>
    </>
  );
};

VideoCreativeThirdPartyComponent.propTypes = {
  values: PropTypes.shape({
    // biome-ignore lint/style/useNamingConvention: <server params>
    custom_vast_url: PropTypes.string.isRequired,
    // biome-ignore lint/style/useNamingConvention: <server params>
    landing_page_url: PropTypes.string.isRequired,
    // biome-ignore lint/style/useNamingConvention: <server params>
    external_identifier: PropTypes.string,
  }).isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default VideoCreativeThirdPartyComponent;
