import { Common } from "./index";

export const Types = Object.freeze({
  CPA: "cpa",
  ROI: "roi",
  CPC: "cpc",
  CTR: "ctr",
  CPM_REACH: "reach",
  CPM_SPEND: "spend",
  VIEWABLE_CPM: "vcpm",
  VIEW_COMPLETION_RATE: "vcr",
  VIEWABILITY_RATE: "viewability_rate",
  ...Common,
});
