import { Common } from "./index";

export const DimensionCodes = Object.freeze({
  NMOS: "NMOS",
  BSER: "BSER",
  INVT: "INVT",
  NMDM: "NMDM",
  DVCE: "DVCE",
  ...Common,
});
