import { useFlags } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Divider, Form, Grid, Header, Message, Radio, Segment, TextArea } from "semantic-ui-react";
import { Config } from "../../../../config/api";
import { OnlineContext } from "../../../../context/online-context";
import {
  capitalizeFirstLetter,
  getApiDate,
  isApiDateTimeExpired,
  isExists,
  parseAPIDateTime,
} from "../../../../libs/common_utils";
import { useForm } from "../../../../libs/component_utils";
import { ApprovalStatuses } from "../../../../models/enum/creativeApprovalStatus";
import CreativesService from "../../../../services/creatives";
import {
  approval_statuses,
  expansion_directions,
  expansion_triggers,
  file_types,
  mraid_types,
  tag_types,
} from "../../fixtures";
import { CreativeRenderer } from "./creativeRenderer";

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: refactor to remove complexity / calculations
const AdminCreativeApprovalEditPage = ({ history, match, creativeType }) => {
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);
  const [firstPartyCreativeDimension, setFirstPartyCreativeDimension] = useState({});

  const _isMounted = useRef(false);
  const backUrl = creativeType === "onsite" ? "/admin/onsite-creatives/" : "/admin/in-store-dooh/";
  const creativeId = Number.parseInt(match.params.creative_id, 10) || 0;
  const creativesService = new CreativesService();
  const intl = useIntl();
  const isExpired = useRef(false);
  const { currentUserIsAdmin } = useContext(OnlineContext);
  const { whitelabelDoubleCreativeApproval } = useFlags();

  /**
   * form submit handler
   * @param {object} values
   */
  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      const params = {
        // biome-ignore lint/style/useNamingConvention: <server params>
        approval_status: values.status,
      };

      if (params.approval_status === ApprovalStatuses.REJECTED) {
        params.rejected_reason = values.rejected_reason;
      }

      await creativesService.updateLocal(creativeId, params);
      history.push(backUrl, { action: "confirmed", title: values.title, status: params.approval_status });
    } catch (e) {
      setServerError(e.error.message);
    } finally {
      if (_isMounted.current) {
        setLoading(false);
      }
    }
  };

  const { values, errors, onChange, updateValues, onSubmit } = useForm(
    handleSubmit,
    { status: ApprovalStatuses.APPROVED },
    () => {
      return [];
    },
  );

  /**
   * navigate user back
   */
  const handleCancel = useCallback(() => {
    history.push(backUrl);
  }, [history, backUrl]);

  /**
   * handle 404
   */
  const handle404 = () => {
    history.push(backUrl, {
      action: "error",
      msg: intl.formatMessage({
        defaultMessage: "No creative found",
        id: "ERROR_CREATIVE_NOT_FOUND",
      }),
    });
  };

  /**
   * get media type translation
   * @param {string} mediaType
   * @returns {string}
   */
  const mediaTypeTranslation = (mediaType) => {
    return mediaType === "display"
      ? intl.formatMessage({
          defaultMessage: "Display",
          id: "MEDIA_TYPE_DISPLAY",
        })
      : intl.formatMessage({
          defaultMessage: "Video",
          id: "MEDIA_TYPE_VIDEO",
        });
  };

  /**
   * get expansion value
   * @param {string} expansionDirection
   * @returns {string}
   */
  const getExpansionDirection = (expansionDirection) => {
    const directions = expansionDirection.split(",").map((x) => x.replace(/\s/g, ""));
    return directions.map((x) => expansion_directions(intl).find((node) => node.value === x).text).join(", ");
  };

  /**
   * get expansion trigger
   * @param {string} triggerValue
   * @returns {string}
   */
  const getExpansionTriggerValue = (triggerValue) => {
    return expansion_triggers(intl).find((node) => node.value === triggerValue).text;
  };

  /**
   * get tag type value
   * @param {string} tagType
   * @returns {string}
   */
  const getTagTypeValue = (tagType) => {
    return tag_types(intl).find((node) => node.value === tagType).text;
  };

  /**
   * get file type value
   * @param {string} fileType
   * @returns {string}
   */
  const getFileTypeValue = (fileType) => {
    return file_types(intl).find((node) => node.value === fileType).text;
  };

  /**
   * get mraid type value
   * @param {string} mraidType
   * @returns {string}
   */
  const getMraidTypeValue = (mraidType) => {
    return mraid_types(intl).find((node) => node.value === mraidType).text;
  };

  /**
   * format creative end date
   * @param {string} endDate
   * @returns {string}
   */
  const getEndDate = (endDate) => {
    const endDateObj = parseAPIDateTime(endDate);
    let creativeEndDate = getApiDate(endDateObj, Config.fullDateTimeFormat);

    if (isApiDateTimeExpired(endDateObj)) {
      const expiredTranslation = intl.formatMessage({
        defaultMessage: "expired",
        id: "TEXT_EXPIRED",
      });
      creativeEndDate = `${creativeEndDate} (${expiredTranslation})`;
    }

    return creativeEndDate;
  };

  /**
   * check that we need to show info or control blocks
   * @param {object} creative
   * @returns {boolean}
   */
  const isSavable = (creative) => {
    return creative.approval_status === ApprovalStatuses.PENDING;
  };

  if (creativeId < 1) {
    handle404();
  }

  // biome-ignore lint/complexity/useSimplifiedLogicExpression: refactor to remove complexity / calculations
  if (!currentUserIsAdmin || !whitelabelDoubleCreativeApproval) {
    handleCancel();
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: this needs to be refactored as creativesService.getCreativeApproval shouldn't need to be a dependency
  useEffect(() => {
    _isMounted.current = true;
    (async () => {
      try {
        const r = await creativesService.getCreativeApproval(creativeId);

        isExpired.current = isApiDateTimeExpired(parseAPIDateTime(r.data.end_date));

        let initialStatus = r.data.approval_status;
        let rejectedReason = r.data.rejected_reason;

        if (r.data.approval_status === ApprovalStatuses.PENDING) {
          initialStatus = isExpired.current ? ApprovalStatuses.REJECTED : ApprovalStatuses.APPROVED;
          rejectedReason = isExpired.current
            ? intl.formatMessage({
                defaultMessage:
                  "The creative has expired and will not be reviewed. Please create a new creative and submit for approval.",
                id: "VALUE_CREATIVE_REJECT_REASON",
              })
            : rejectedReason;
        }

        updateValues({
          status: initialStatus,
          ...r.data,
          // biome-ignore lint/style/useNamingConvention: <server params>
          rejected_reason: rejectedReason,
        });
      } catch (_) {
        history.push(backUrl);
      }
    })();

    return () => {
      _isMounted.current = false;
    };
  }, [creativeId]);

  let mraid = "";
  if (isExists(values, "mraid")) {
    if (values.mraid === 0) {
      mraid = intl.formatMessage({
        defaultMessage: "No",
        id: "LABEL_NO",
      });
    } else if (isExists(values, "mraid_type")) {
      mraid = getMraidTypeValue(values.mraid_type);
    } else {
      mraid = intl.formatMessage({
        defaultMessage: "Yes",
        id: "LABEL_YES",
      });
    }
  }

  if (!values.id) {
    return (
      <Segment
        className="loading"
        disabled
        tertiary
        textAlign="center"
      >
        &nbsp;
      </Segment>
    );
  }

  return (
    <Segment basic>
      <Header as="h2">
        {intl.formatMessage({
          defaultMessage:
            creativeType === "instoreDOOH" ? "In-store DOOH Creative Approval" : "Onsite Creative Approval",
          id:
            creativeType === "instoreDOOH"
              ? "HEADING_INSTORE_DOOH_CREATIVE_APPROVAL"
              : "HEADING_ONSITE_CREATIVE_APPROVAL",
        })}
      </Header>
      <Divider />
      <Message
        content={serverError}
        error
        hidden={!serverError}
        size="tiny"
        style={{ marginTop: "10px" }}
      />
      <Form
        autoComplete="off"
        loading={loading}
        noValidate
        onSubmit={onSubmit}
        size="small"
      >
        <Grid className="creative_approval_info">
          <Grid.Row>
            <Grid.Column width={4}>
              <strong>
                {intl.formatMessage({
                  defaultMessage: "Creative Name",
                  id: "LABEL_CREATIVE_NAME",
                })}
              </strong>
              <div
                className="creative_name"
                title={values.title}
              >
                {values.title}
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              <strong>
                {intl.formatMessage({
                  defaultMessage: "Advertiser",
                  id: "LABEL_ADVERTISER",
                })}
              </strong>
              {values.advertiser_name}
            </Grid.Column>
            <Grid.Column width={4}>
              <strong>
                {intl.formatMessage({
                  defaultMessage: "Creative Type",
                  id: "LABEL_CREATIVE_TYPE",
                })}
              </strong>
              {intl.formatMessage({
                defaultMessage: creativeType === "instoreDOOH" ? "In-store DOOH" : "On-site",
                id: creativeType === "instoreDOOH" ? "LABEL_CREATIVE_TYPE_INSTORE_DOOH" : "LABEL_CREATIVE_TYPE_ON_SITE",
              })}
            </Grid.Column>
            <Grid.Column width={4}>
              <strong>
                {intl.formatMessage({
                  defaultMessage: "Channel",
                  id: "LABEL_CHANNEL",
                })}
              </strong>
              {mediaTypeTranslation(values.media_type)}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingBottom: "0" }}>
            <Grid.Column className="creative-preview-container tree-loading">
              <CreativeRenderer
                creative={values}
                maxHeight="398"
                setFirstPartyCreativeDimension={setFirstPartyCreativeDimension}
              />
            </Grid.Column>
          </Grid.Row>
          <div
            align="center"
            id="creative_resized_id"
          >
            {intl.formatMessage({
              defaultMessage: "Note: The creative has been resized to fit this review page.",
              id: "NOTE_CREATIVE_RESIZED",
            })}
          </div>
        </Grid>

        <Divider hidden />

        <div style={{ width: "50%", margin: "auto" }}>
          <div style={{ maxHeight: "300px", overflowY: "auto" }}>
            {isExists(values, "end_date") && (
              <Form.Field inline>
                <label style={{ marginRight: 0 }}>
                  {intl.formatMessage({
                    defaultMessage: "End Date",
                    id: "LABEL_END_DATE",
                  })}
                </label>
                <span className={isApiDateTimeExpired(parseAPIDateTime(values.end_date)) ? "red" : ""}>
                  {getEndDate(values.end_date)}
                </span>
              </Form.Field>
            )}

            {isExists(values, "ad_server") && (
              <Form.Field inline>
                <label>
                  {intl.formatMessage({
                    defaultMessage: "Ad Server Name",
                    id: "LABEL_AD_SERVER_NAME",
                  })}
                </label>
                {values.ad_server}
              </Form.Field>
            )}

            {isExists(values, "tag_type") && (
              <Form.Field inline>
                <label>
                  {intl.formatMessage({
                    defaultMessage: "Ad Tag Type",
                    id: "LABEL_AD_TAG_TYPE",
                  })}
                </label>
                {getTagTypeValue(values.tag_type)}
              </Form.Field>
            )}

            {isExists(values, "tpas_tag_id") && (
              <Form.Field inline>
                <label>
                  {intl.formatMessage({
                    defaultMessage: "3PAS Tag ID",
                    id: "LABEL_3PAS_TAG_ID",
                  })}
                </label>
                {values.tpas_tag_id}
              </Form.Field>
            )}

            {isExists(values, "file_type") && (
              <Form.Field inline>
                <label>
                  {intl.formatMessage({
                    defaultMessage: "File Type",
                    id: "LABEL_FILE_TYPE",
                  })}
                </label>
                {getFileTypeValue(values.file_type)}
              </Form.Field>
            )}

            {isExists(values, "width") && (
              <Form.Field inline>
                <label>
                  {intl.formatMessage({
                    defaultMessage: "Dimensions",
                    id: "LABEL_DIMENSIONS",
                  })}
                </label>
                {values.width} x {values.height}
              </Form.Field>
            )}
            {isExists(firstPartyCreativeDimension, "width") && isExists(firstPartyCreativeDimension, "height") && (
              <Form.Field inline>
                <label>
                  {intl.formatMessage({
                    defaultMessage: "Dimensions",
                    id: "LABEL_DIMENSIONS",
                  })}
                </label>
                {firstPartyCreativeDimension.width} x {firstPartyCreativeDimension.height}
              </Form.Field>
            )}

            {isExists(values, "mraid") && (
              <Form.Field inline>
                <label>
                  {intl.formatMessage({
                    defaultMessage: "MRAID",
                    id: "LABEL_MRAID",
                  })}
                </label>
                {mraid}
              </Form.Field>
            )}

            {isExists(values, "is_expandable") && values.media_type !== "video" && (
              <Form.Field inline>
                <label>
                  {intl.formatMessage({
                    defaultMessage: "Expandable",
                    id: "LABEL_EXPANDABLE",
                  })}
                </label>
                {values.is_expandable === 1
                  ? intl.formatMessage({
                      defaultMessage: "Yes",
                      id: "LABEL_YES",
                    })
                  : intl.formatMessage({
                      defaultMessage: "No",
                      id: "LABEL_NO",
                    })}
              </Form.Field>
            )}

            {isExists(values, "expansion_trigger") && (
              <Form.Field inline>
                <label>
                  {intl.formatMessage({
                    defaultMessage: "Expansion Trigger",
                    id: "LABEL_EXPANSION_TRIGGER",
                  })}
                </label>
                {getExpansionTriggerValue(values.expansion_trigger)}
              </Form.Field>
            )}

            {isExists(values, "expansion_direction") && (
              <Form.Field inline>
                <label>
                  {intl.formatMessage({
                    defaultMessage: "Expansion Direction",
                    id: "LABEL_EXPANSION_DIRECTION",
                  })}
                </label>
                {getExpansionDirection(values.expansion_direction)}
              </Form.Field>
            )}

            {isExists(values, "clickthrough_url") && (
              <Form.Field
                inline
                style={{ display: "flex" }}
              >
                <label>
                  {intl.formatMessage({
                    defaultMessage: "Clickthrough URL",
                    id: "LABEL_CLICKTHROUGH_URL",
                  })}
                </label>
                <a
                  href={values.clickthrough_url}
                  rel="noreferrer"
                  style={{ wordBreak: "break-all", width: "540px" }}
                  target="_blank"
                >
                  {values.clickthrough_url}
                </a>
              </Form.Field>
            )}

            {isExists(values, "landing_page_url") && (
              <Form.Field
                inline
                style={{ display: "flex" }}
              >
                <label>
                  {intl.formatMessage({
                    defaultMessage: "Landing Page",
                    id: "LABEL_LANDING_PAGE_URL",
                  })}
                </label>
                <a
                  href={values.landing_page_url}
                  rel="noreferrer"
                  style={{ wordBreak: "break-all", width: "540px" }}
                  target="_blank"
                >
                  {values.landing_page_url}
                </a>
              </Form.Field>
            )}

            {isExists(values, "ad_tag") && (
              <Form.Field inline>
                <label style={{ verticalAlign: "top", marginRight: "0" }}>
                  {intl.formatMessage({
                    defaultMessage: "Ad Tag",
                    id: "LABEL_AD_TAG",
                  })}
                </label>
                <div>{values.ad_tag}</div>
              </Form.Field>
            )}
          </div>
          {isSavable(values) ? (
            <OnsiteCreativesStatusControl
              errors={errors}
              intl={intl}
              isExpired={isExpired.current}
              onChange={onChange}
              rejected_reason={values.rejected_reason}
              status={values.status}
            />
          ) : (
            <OnsiteCreativesStatusInfo
              intl={intl}
              isExpired={isExpired.current}
              values={values}
            />
          )}
        </div>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Form.Field align="right">
          <Button
            onClick={handleCancel}
            size="tiny"
            type="button"
          >
            {intl.formatMessage({
              defaultMessage: "Cancel",
              id: "BTN_CANCEL",
            })}
          </Button>
          {isSavable(values) && (
            <Button
              color="green"
              size="tiny"
              type="submit"
            >
              Save Changes
            </Button>
          )}
        </Form.Field>
      </Form>
    </Segment>
  );
};
AdminCreativeApprovalEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const OnsiteCreativesStatusControl = ({ intl, status, rejected_reason, errors, onChange, isExpired }) => {
  return (
    <>
      <Form.Field
        inline
        style={{ marginTop: "1em" }}
      >
        <label style={{ marginRight: "0" }}>
          {intl.formatMessage({
            defaultMessage: "Approval Status",
            id: "LABEL_APPROVAL_STATUS",
          })}
        </label>
        <Radio
          checked={status !== ApprovalStatuses.REJECTED}
          disabled={isExpired}
          label={intl.formatMessage({
            defaultMessage: "Approved",
            id: "CREATIVE_APPROVAL_STATUS_APPROVED",
          })}
          name="status"
          onChange={onChange}
          value={ApprovalStatuses.APPROVED}
        />
        <Radio
          checked={status === ApprovalStatuses.REJECTED}
          label={intl.formatMessage({
            defaultMessage: "Rejected",
            id: "CREATIVE_APPROVAL_STATUS_REJECTED",
          })}
          name="status"
          onChange={onChange}
          style={{ marginLeft: "15px" }}
          value={ApprovalStatuses.REJECTED}
        />
      </Form.Field>

      {status === ApprovalStatuses.REJECTED && (
        <Form.Field
          error={Object.hasOwn(errors, "rejected_reason")}
          inline
          required
        >
          <label style={{ marginRight: "0" }}>
            {intl.formatMessage({
              defaultMessage: "Rejection Reason",
              id: "LABEL_REJECTION_REASON",
            })}
          </label>
          <TextArea
            defaultValue={rejected_reason}
            name="rejected_reason"
            onBlur={onChange}
            required={true}
            rows="5"
            style={{ width: "650px" }}
          />
          <div
            className="custom-error"
            style={{ marginLeft: 0 }}
          >
            {errors.rejected_reason}
          </div>
        </Form.Field>
      )}
    </>
  );
};

OnsiteCreativesStatusControl.propTypes = {
  errors: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isExpired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  // biome-ignore lint/style/useNamingConvention: <server params>
  rejected_reason: PropTypes.string,
  status: PropTypes.string.isRequired,
};

const OnsiteCreativesStatusInfo = ({ intl, values, isExpired }) => {
  const statusTranslate = isExpired
    ? capitalizeFirstLetter(
        intl.formatMessage({
          defaultMessage: "expired",
          id: "TEXT_EXPIRED",
        }),
      )
    : approval_statuses(intl).get(values.approval_status);

  return (
    <>
      <Form.Field
        inline
        style={{ marginTop: "1em" }}
      >
        <label style={{ marginRight: "0" }}>
          {intl.formatMessage({
            defaultMessage: "Approval Status",
            id: "LABEL_APPROVAL_STATUS",
          })}
        </label>
        {statusTranslate}
      </Form.Field>
      {values.approval_status === ApprovalStatuses.REJECTED && (
        <Form.Field inline>
          <label style={{ marginRight: "0" }}>
            {intl.formatMessage({
              defaultMessage: "Rejection Reason",
              id: "LABEL_REJECTION_REASON",
            })}
          </label>
          {values.rejected_reason}
        </Form.Field>
      )}
    </>
  );
};
OnsiteCreativesStatusInfo.propTypes = {
  intl: PropTypes.object.isRequired,
  isExpired: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
};

export default AdminCreativeApprovalEditPage;
