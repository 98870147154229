import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { useIntl } from "react-intl";
import { Form } from "semantic-ui-react";
import { getTomorrowDate } from "../../../../libs/common_utils";

const OnSiteDateTimeControl = ({ onChange, endDate, error, isEdit, config }) => {
  const intl = useIntl();
  return isEdit ? (
    <Form.Field inline>
      <label>
        {intl.formatMessage({
          defaultMessage: "End Date",
          id: "LABEL_END_DATE",
        })}
      </label>
      <div className="display-inline">{endDate.toLocaleString()}</div>
    </Form.Field>
  ) : (
    <Form.Field
      className="onsite_datepicker"
      error={Boolean(error)}
      inline
      required
    >
      <label style={{ top: "8px", position: "relative" }}>
        {intl.formatMessage({
          defaultMessage: "End Date",
          id: "LABEL_END_DATE",
        })}
      </label>
      <DatePicker
        dateFormat={config.fullDateTimeFormat}
        minDate={getTomorrowDate()}
        name="end_date"
        onChange={onChange}
        required
        selected={endDate}
        showTimeSelect
        timeCaption="time"
        timeFormat={config.timeFormat}
        timeIntervals={15}
      />
      <div className="custom-error">{error}</div>
    </Form.Field>
  );
};
OnSiteDateTimeControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  isEdit: PropTypes.bool.isRequired,
  config: PropTypes.shape({
    timeFormat: PropTypes.string.isRequired,
    fullDateTimeFormat: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.string,
};

export default OnSiteDateTimeControl;
