import React, {
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";

import {
  Button,
  Icon,
  Message,
  Pagination,
  Popup,
  PopupContent,
  Segment,
  Table,
  Grid,
} from "semantic-ui-react";

import {
  isNewTabClick,
  onBannerDismiss,
} from "../../../libs/component_utils";

import { Config } from "../../../config/api";
import { CreativesApprovalGrid } from "./creative_approval";
import CreativesHeader from "../form/common/creativesHeader.js";
import { CreativePreviewer } from "../creative_previewer/creativePreviewer";
import { creativesActions as cA, creativeGridReducer } from "./reducers";
import CreativesGridContext from "./context";
import CreativesService from "../../../services/creatives";
import { OnlineContext } from "../../../context/online-context";
import Pager from "../../../models/pager";
import {isNil, getApiDate} from "../../../libs/common_utils";
import Filter from "./models/filter";
import FilterControl from "./filter_control";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { SuiModal } from "../../common/suiModal";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useIntl } from "react-intl";
import uuid from "uuid/v4";
import { parseAPIDateTime } from "../../../libs/common_utils.js";

const initialState = {
  "creatives": [],
  "pager": new Pager(),
  "filter": new Filter()
};

export const CreativesPage = ({history, creative_type}) => {
  const [creativePreviewId, setCreativePreviewId] = useState(null);
  const [state, dispatch] = useReducer(creativeGridReducer, initialState, undefined);
  const [gridLoading, setGridLoading] = useState(true);
  const [iSCreativePreviewModalOpen, setIsCreativePreviewModalOpen] = useState(false);

  const { agency } = useContext(OnlineContext);
  const intl = useIntl();
  const services = React.useRef(new Map([["creatives", new CreativesService()]]));
  const showSuccessMessage = !isNil(history.location.state);
  let _isMounted = React.useRef(false);

  const handleCreativePreviewClick = useCallback((id) => {
    setCreativePreviewId(id);
    setIsCreativePreviewModalOpen(true);
  }, [setCreativePreviewId, setIsCreativePreviewModalOpen]);

  const {
    whitelabelChannelVideo,
    whitelabelChannelDisplay,
    whitelabelCreativePreview,
    whitelabelDoubleCreativeApproval,
  } = useFlags();

  const isInstoreDOOH = creative_type === "instoreDOOH";
  const isVideo = creative_type === "video";
  const isDisplay = creative_type === "display";
  const isOnSite = creative_type === "onsite";

  /**
   * initial load
   */
  React.useEffect(() => {
    _isMounted.current = true;

    // load list of items
    (async () => {
      await getList(state.filter, state.pager);
    })();

    // clear cache
    return () => {
      _isMounted.current = false;
      state.pager.reset();
      state.filter.reset();
    }
  }, [creative_type]);

  /**
   * get page
   * @param e
   * @param activePage
   */
  const getPage = async (e, {activePage}) => {
    state.pager.setPage(activePage);
    await getList(state.filter, state.pager);
  };

  const creativePreviewerModalButtons = useMemo(
    () => [
      {
        text: intl.formatMessage({
          id: "BTN_CLOSE",
          defaultMessage: "Close"
        }),
        color: 'primary',
        onClick: () => setIsCreativePreviewModalOpen(false),
      }
    ],
    [intl, setIsCreativePreviewModalOpen]
  );

  /**
   * get to create page based on type provided
   * @param {boolean} isVideo
   */
  const navigateToCreatePage = isVideo => {
    history.push((isVideo) ? "/creative/video/create" : "/creative/display/create");
  };

  /**
   * navigate user to edit page
   */
  const navigateToEditPage = useCallback(id => {
    history.push((isVideo)? `/creative/video/edit/${id}` : `/creative/display/edit/${id}`,
      state.creatives.find(creative => id === creative.id));
  }, [state]);

  /**
   * get edit page URL by creative id
   */
  const getEditPageHref = useCallback((id) => (isVideo)? `/creative/video/edit/${id}` : `/creative/display/edit/${id}`, []);

  /**
   * load creatives from API
   * @param {object} [filter]
   * @param {object} [pager]
   * @return {Promise<void>}
   */
  const getList = async (filter = null, pager = null) => {
    const creatives = services.current.get("creatives");
    try {
      setGridLoading(true);

      let params = {approval_status: true};
      if (filter) {
        params = Object.assign(params, filter.toRequestJson());
      }
      if (pager) {
        params = Object.assign(params, pager.toJson());
      }
      if (isInstoreDOOH) {
        params.instore_dooh = true;
      }

      let r;
      if (isDisplay) {
        r = await creatives.displayList(agency, params);
      } else if (isVideo) {
        r = await creatives.videoList(agency, params);
      } else if (isOnSite || isInstoreDOOH) {
        r = await creatives.localCreativesByAgency(agency, params);
      }
        else {
        throw Error("Unknown creative type pass")
      }

      let meta = r.meta;
      meta.page = pager.page;
      dispatch({"type": cA.INIT, "data": r.data, "pager": meta});
    } catch (e) {
      // ignore error
      console.log(e);
    } finally {
      if(_isMounted.current) {
        setGridLoading(false);
      }
    }
  };

  /**
   * filter strategies
   * @param {object} json
   */
  const filtering = async json => {
    state.pager.reset();
    state.filter.fromJson(json);
    await getList(state.filter, state.pager);
  };

  if(!whitelabelChannelVideo && !whitelabelChannelDisplay && !whitelabelDoubleCreativeApproval) {
    return <Redirect to="/campaigns" />
  }

  /**
   * check that user has access to this page
   * @return {boolean}
   */
  const accessRestricted = () => {
    const conditions = [
      !whitelabelChannelVideo && isVideo,
      !whitelabelChannelDisplay && isDisplay,
      !whitelabelDoubleCreativeApproval && (isOnSite || isInstoreDOOH)
    ];

    return conditions.some(Boolean);
  };

  if(accessRestricted()) {
    return <Redirect to="/creatives" />
  }

  const openCreativePreviewModal = useCallback(() => {
    setIsCreativePreviewModalOpen(!iSCreativePreviewModalOpen);
  }, [iSCreativePreviewModalOpen]);

  return (
    <Segment basic style={{"padding": "0"}}>
      <SuiModal
        isOpen={iSCreativePreviewModalOpen}
        isScrollableModalBody
        modalButtons={creativePreviewerModalButtons}
        modalContent={
          <CreativePreviewer
            creativeId={creativePreviewId}
            hideDataTable
          />
        }
        modalHeader={intl.formatMessage({
          id: "LABEL_CREATIVE_PREVIEW",
          defaultMessage: "Creative Preview"
        })}
        onClose={openCreativePreviewModal}
        modalSize="large"
      />

      <CreativesGridContext.Provider value={{
        "getPage": getPage,
        "navigateToCreatePage": () => navigateToCreatePage(isVideo),
        gridLoading,
        "ld_flags": {
          whitelabelChannelVideo,
          whitelabelChannelDisplay,
          whitelabelCreativePreview,
          whitelabelDoubleCreativeApproval,
        },
        filtering,
        getEditPageHref,
        "handleCreativePreviewClick": handleCreativePreviewClick,
        navigateToEditPage,
      }}>
        {showSuccessMessage && (<CreativesSuccessMessage
          details={history.location.state || {}}
        />)}

          <CreativesHeader creativeType={creative_type} />
          {(isOnSite || isInstoreDOOH)? <CreativesApprovalGrid
					items={state.creatives}
					controls={{
						"filter": state.filter,
						"pager": {
							"page": state.pager.page,
							"total_pages": state.pager.total_pages,
							getPage,
						},
					}}
				/> : <CreativesGrid
					creative_type={creative_type}
					items={state.creatives}
					controls={{
						"filter": state.filter,
						"pager": {
							"page": state.pager.page,
							"total_pages": state.pager.total_pages,
							getPage,
						},
					}}
				/>}
      </CreativesGridContext.Provider>
    </Segment>
  )
};

/**
 * Generate success message
 * @param {object} details
 * @return {*}
 * @constructor
 */
const CreativesSuccessMessage = ({details}) => {
  const intl = useIntl();

  let timer;
  React.useEffect(() => {
    timer = setTimeout(onBannerDismiss, 10000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (details.action === "created") {
    return (
      <Message
        success
        className="page-success-message"
        attached
        onDismiss={onBannerDismiss}
      >
        {intl.formatMessage({
          id: "MESSAGE_CREATIVE_CREATED",
          defaultMessage: "Creative successfully created",
        })}
      </Message>
    );
  } else if (details.action === "updated") {
    return (
      <Message
        success
        className="page-success-message"
        attached
        onDismiss={onBannerDismiss}
      >
        {intl.formatMessage(
          {
            id: "MESSAGE_CREATIVE_UPDATED",
            defaultMessage: "Creative {name} updated",
          },
          {
            name: details.name,
          }
        )}
      </Message>
    );
  }

  return <></>
};
CreativesSuccessMessage.propTypes = {
  "details": PropTypes.object.isRequired
};

/**
 * Render grid
 * @param {array} items
 * @param {object} controls
 * @param {string} creative_type
 */
export const CreativesGrid = ({ items, controls, creative_type}) => {
  const intl = useIntl();
  const context = React.useContext(CreativesGridContext);
  const isVideo = creative_type === "video";
  const isDisplay = creative_type === "display";

  /**
   * generate button title based on creative_type
   * @param {boolean} isVideo
   * @returns {string}
   */
  const getCreateButtonTitle = isVideo => {
    return isVideo
      ? intl.formatMessage({
          defaultMessage: "Add Video Creative",
          id: "BTN_ADD_VIDEO_CREATIVE",
        })
      : intl.formatMessage({
          defaultMessage: "Add Display Creative",
          id: "BTN_ADD_DISPLAY_CREATIVE",
        });
  }

  return (
    <>
      <Segment basic style={{"padding": "0"}} loading={context.gridLoading}>
        <Grid className="common_grid">
          <Grid.Row>
            <Grid.Column>
              <FilterControl
                isVideo={isVideo}
                filter={controls.filter}
                onChange={context.filtering}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
            <Table className="custom-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {intl.formatMessage({
                      id: "LABEL_CREATIVE_NAME",
                      defaultMessage: "Creative Name",
                    })}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="left">
                    {intl.formatMessage({
                      id: "LABEL_STATUS",
                      defaultMessage: "Status",
                    })}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="left">
                    {intl.formatMessage({
                      id: "LABEL_CREATIVE_ID",
                      defaultMessage: "Creative ID",
                    })}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="left">
                    {intl.formatMessage({
                      id: "LABEL_ADVERTISER",
                      defaultMessage: "Advertiser",
                    })}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="left">
                    {intl.formatMessage({
                      id: "LABEL_R_START_DATE",
                      defaultMessage: "Start Date",
                    })}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="left">
                    {intl.formatMessage({
                      id: "LABEL_R_END_DATE",
                      defaultMessage: "End Date",
                    })}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ "width": "20px" }}>
                    &nbsp;
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ "width": "20px" }}>
                    &nbsp;
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {items.map((item) => {
                  return <CreativeGridItem key={uuid()} {...item} />;
                })}
                {!items.length && (
                  <GridEmptyRow
                    filterIsEmpty={controls.filter.isEmpty()}
                    buttonTitle={getCreateButtonTitle(isVideo)}
                    onAddButtonClick={context.navigateToCreatePage}
                  />
                )}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.Cell colSpan="5" textAlign="right">{controls.pager.total_pages > 1 && <Pagination
                    size="mini"
                    defaultActivePage={controls.pager.page}
                    totalPages={controls.pager.total_pages}
                    firstItem={null}
                    lastItem={null}
                    onPageChange={context.getPage}
                  />}
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  )
};
CreativesGrid.propTypes = {
  "items": PropTypes.array.isRequired,
  "controls": PropTypes.object.isRequired
};

const GridEmptyRow = ({ filterIsEmpty, buttonTitle, onAddButtonClick }) => {
  const intl = useIntl();

  return (
    <Table.Row>
      <Table.Cell colSpan="5" textAlign="center">
        {filterIsEmpty ? (
          <>
            {intl.formatMessage({
              id: "EMPTY_CREATIVES",
              defaultMessage: "You don’t have any creatives yet",
            })}
            <br />
            <br />
            <Button
              className="text__uppercase"
              primary
              compact
              onClick={onAddButtonClick}
            >
              {buttonTitle}
            </Button>
          </>
        ) : (
          intl.formatMessage({
            id: "EMPTY_SEARCH_RESULTS",
            defaultMessage: "No results found",
          })
        )}
      </Table.Cell>
    </Table.Row>
  );
};

/**
 * Derive approval status icon
 * @param {string} status
 * @returns semantic-ui icon color and name
 */
const approvalStatusIcon = status => {
  switch(status?.toLowerCase()) {
    case "approved":
      return {
        color: "green",
        name: "check circle outline"
      };
    case "pending":
      return {
        color: "yellow",
        name: "wait"
      };
    case "rejected":
      return {
        color: "red",
        name: "times circle outline"
      };
    default:
      return {
        color: "grey",
        name: "question circle outline"
      };
  }
};

const VENDORS = {
  "adx_deals": "AdX Deals",
  "adx_open_auction": "AdX Open Auction",
  "xander": "Xander"
}

/**
 * Generate popup statuses content
 * @param {object} creative_statuses
 * @return {JSX.Element} popup content
 */
const popupStatuses = creative_statuses => (
  <Grid centered style={{"paddingBottom": "10px"}}>
    <Grid.Row style={{"paddingBottom": "4px"}}>
      <b>Approval Status</b>
    </Grid.Row>
    {Object.entries(VENDORS).map(([key, value]) => (
      <Grid.Row style={{"padding": "4px"}} key={key}>
        <Grid.Column width={12}>
          {value}
        </Grid.Column>
        <Grid.Column width={4}>
          <Icon
            className="control"
            color={approvalStatusIcon(creative_statuses[key]).color}
            name={approvalStatusIcon(creative_statuses[key]).name}
          />
        </Grid.Column>
      </Grid.Row>
    ))}
  </Grid>
)

/**
 * Generate grid item
 * @param {object} item
 * @return {JSX.Element}
 * @constructor
 */
const CreativeGridItem = item => {
	const intl = useIntl();
	const context = React.useContext(CreativesGridContext);
  const creativeStartDate = item.start_date? getApiDate(parseAPIDateTime(item.start_date), Config.dateTimeFormat) : '-';
  const creativeEndDate = item.end_date? getApiDate(parseAPIDateTime(item.end_date), Config.dateTimeFormat) : '-';
	const { whitelabelCreativePreview } = context.ld_flags;

  return (
    <>
      <Table.Row className={item.status ? "" : " inactive"}>
        <Table.Cell className="text-ellipsis">{item.title}</Table.Cell>
        <Table.Cell>
          {item.status
            ? intl.formatMessage({
                id: "STATUS_ACTIVE",
                defaultMessage: "Active",
              })
            : intl.formatMessage({
                id: "STATUS_INACTIVE",
                defaultMessage: "Inactive",
              })}
        </Table.Cell>
        <Table.Cell>{item.id}</Table.Cell>
        <Table.Cell>{item.advertiser_name}</Table.Cell>
        <Table.Cell>{creativeStartDate}</Table.Cell>
        <Table.Cell>{creativeEndDate}</Table.Cell>
        <Table.Cell style={{ whiteSpace: 'nowrap'}} data-clickable="0" textAlign="center">
          {
            whitelabelCreativePreview &&
            <Icon
              name={`eye`}
              className="control"
              onClick={() => context.handleCreativePreviewClick(item.id)}
              id={`eyeIcon_${item.id}`}
            />
          }
          &nbsp;
          <Popup
            trigger={<Icon
              className="control"
              color={approvalStatusIcon(item.approval_status).color}
              name={approvalStatusIcon(item.approval_status).name}
            />}
            position='top right'
          >
            <PopupContent>
              {popupStatuses(item)}
            </PopupContent>
          </Popup>
          &nbsp;
          <a
            href={context.getEditPageHref(item.id)}
            onClick={(e) => {
              if (isNewTabClick(e)) return;
              e.preventDefault();
              e.stopPropagation();
              context.navigateToEditPage(item.id);
            }}
          >
            <Icon name="edit" className="control" />
          </a>
        </Table.Cell>
      </Table.Row>
    </>
  );
};
