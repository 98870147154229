import React, { useState } from "react";

import {
	Button, Checkbox,
	Divider,
	Dropdown,
	Form, Icon,
	Input, Popup,
	Radio,
} from "semantic-ui-react";
import { useIntl } from "react-intl";

import { useForm } from "../../../libs/component_utils";
import "react-datepicker/dist/react-datepicker.css";
import PixelsService from "../../../services/pixels";
import T1Service from "../../../services/t1";
import AgenciesService from "../../../services/agencies";
import PropTypes from "prop-types";
import CommonService from "../../../services/common";
import PixelFormContext from "./context";

import {RevenueType} from "../../../models/enum/pixelRevenueType";
import PixelRevenueCreateComponent from "./components/pixel_revenue_create";
import PixelRevenueViewComponent from "./components/pixel_revenue_view";
import {generatePixelSnippet} from "../../../libs/common_utils";
/**
 * Pixel create page component
 * @return {*}
 * @constructor
 */
const PixelForm = ({
	initialData,
	onSubmit,
	onCancel,
	onOpenInstructionsModal,
	formType = "create",
	submitText = "Upload Device ID",
}) => {
	const services = React.useRef(
		new Map([
			["pixels", new PixelsService()],
			["t1", new T1Service()],
			["common", new CommonService()],
			["agencies", new AgenciesService()],
		])
	);
	let _isMounted = React.useRef(false);
	const intl = useIntl();
	const editMode = formType.toString() === "edit";
	const agency = services.current.get("agencies").getSelectedAgency() || 0;

	const [formSending, setFormSending] = useState(false);

	// loaded page data
	const [pageData, setPageData] = useState(() => {
		return {
			"pageLoading": true,
			"advertisers": []
		};
	});

	/**
	 * initially loading advertisers list
	 */
	React.useEffect(() => {
		const service = services.current.get("t1");
		(async () => {
			try {
				const [r] = await Promise.all([
					service.advertisers(agency)
				]);

				setPageData({
					...pageData,
					"pageLoading": false,
					"advertisers": pageData.advertisers.concat(
						r.data.map(({ id, title }) => ({
							"key": id,
							"text": title,
							"value": id,
						}))
					),
				});
			} catch (e) {
				console.log(e);
			}
		})();

		return () => {
			_isMounted.current = false;
		};
	}, []);

	/**
	 * form submit handler
	 * @return {boolean}
	 */
	const addPixelForm = async () => {
		try {
			setFormSending(true);

			await onSubmit(values);
		} catch (e) {
			console.error(e);
		} finally {
			if (_isMounted.current) {
				setFormSending(false);
			}
		}
	};

	const { values, errors, onChange, onSubmit: handleSubmit, updateValues } = useForm(
		addPixelForm,
		initialData,
		() => {
			const errors = {};

			if (values.advertiser_id === null) {
				errors.advertiser_id = intl.formatMessage({
					id: "ERROR_EMPTY_ADVERTISER_ID",
					defaultMessage: "Please select an advertiser.",
				});
			}

			// validation for revenue block
			if(!editMode && values.revenue_enabled) {
				if(!values.revenue) {
					errors.revenue = intl.formatMessage({
						id: "VALIDATOR_PLEASE_SELECT_FIELD_NAME",
						defaultMessage: "Please select {field_name}.",
					}, {
						"field_name": intl.formatMessage({
							id: "LABEL_REVENUE",
							defaultMessage: "Revenue",
						})
					});
				}

				if(values.revenue_type === RevenueType.FIXED) {
					if(!values.revenue_currency_fixed) {
						errors.revenue_currency = intl.formatMessage({
							id: "VALIDATOR_PLEASE_SELECT_FIELD_NAME",
							defaultMessage: "Please select {field_name}.",
						}, {
							"field_name": intl.formatMessage({
								id: "LABEL_CURRENCY",
								defaultMessage: "Currency",
							})
						});
					}
				} else {
					if(!values.revenue_currency) {
						errors.revenue_currency = intl.formatMessage({
							id: "VALIDATOR_PLEASE_SELECT_FIELD_NAME",
							defaultMessage: "Please select {field_name}.",
						}, {
							"field_name": intl.formatMessage({
								id: "LABEL_CURRENCY",
								defaultMessage: "Currency",
							})
						});
					}
				}
			}

			return errors;
		}
	);

	let code_snippet_link = "";
	if(editMode) {
		code_snippet_link = generatePixelSnippet(values);
	}

	return (
		<PixelFormContext.Provider value={{
			intl
		}}>
		<Form
			onSubmit={handleSubmit}
			size="small"
			loading={formSending}
			noValidate
			error={!!Object.keys(errors).length}
			autoComplete="off"
		>
			<Form.Field inline>
				<label>
					{intl.formatMessage({
						id: "LABEL_STATUS",
						defaultMessage: "Status",
					})}
				</label>
				<Radio
					name="status"
					label={intl.formatMessage({
						id: "STATUS_ACTIVE",
						defaultMessage: "Active",
					})}
					value={1}
					checked={!!values.status}
					onChange={onChange}
				/>
				<Radio
					style={{ "marginLeft": "15px" }}
					name="status"
					label={intl.formatMessage({
						id: "STATUS_INACTIVE",
						defaultMessage: "Inactive",
					})}
					value={0}
					checked={!values.status}
					onChange={onChange}
				/>
			</Form.Field>

			<Form.Field inline error={errors.hasOwnProperty("title")} required>
				<label>
					{intl.formatMessage({
						id: "LABEL_PIXEL_NAME",
						defaultMessage: "Pixel Name",
					})}
				</label>
				<Input
					name="title"
					required
					minLength={1}
					maxLength={256}
					defaultValue={values.title}
					onBlur={onChange}
				/>
				<div className="custom-error">{errors["title"]}</div>
			</Form.Field>

			<Form.Field
				inline
				error={errors.hasOwnProperty("advertiser_id")}
				required
			>
				<label>
					{intl.formatMessage({
						id: "LABEL_ADVERTISER",
						defaultMessage: "Advertiser",
					})}
				</label>

				<Dropdown
					required
					search
					selection
					disabled={editMode}
					options={pageData.advertisers}
					loading={pageData.pageLoading}
					placeholder={intl.formatMessage({
						id: "HINT_ADVERTISER",
						defaultMessage: "Select advertiser",
					})}
					noResultsMessage={intl.formatMessage({
						id: "EMPTY_SEARCH_RESULTS",
						defaultMessage: "No results found",
					})}
					name="advertiser_id"
					value={values.advertiser_id}
					onChange={onChange}
				/>
				<div className="custom-error">{errors["advertiser_id"]}</div>
			</Form.Field>

			{editMode? <PixelRevenueViewComponent values={values} /> : <>
				<PixelRevenueCreateComponent values={values} errors={errors}
																		 onChange={onChange} updateValues={updateValues} />
			</>}
			{formType === "edit" ? (
				<>
					<Form.Field
						inline
						error={errors.hasOwnProperty("advertiser_id")}
						required
					>
						<label>
							{intl.formatMessage({
								id: "LABEL_PIXEL_TAG",
								defaultMessage: "Pixel Tag",
							})}
						</label>

						<pre
							className="pixel-modal-snippet"
							style={{ display: "inline-block" }}
						>
							{code_snippet_link}
						</pre>
					</Form.Field>

					<Form.Field
						inline
						error={errors.hasOwnProperty("advertiser_id")}
						required
					>
						<label>
							{intl.formatMessage({
								id: "LABEL_TAG_SETUP",
								defaultMessage: "Tag Setup",
							})}
						</label>

						<button type="button" className="pseudo-link" onClick={onOpenInstructionsModal}>
							{intl.formatMessage({
								id: "BTN_TAG_SETUP",
								defaultMessage: "Click for instructions",
							})}
						</button>
						{/* This should open a popup - perhaps use a button? */}
					</Form.Field>
				</>
			) : null}

			<Divider hidden />
			<Divider hidden />
			<Divider hidden />
			<Form.Field align="right">
				<Button size="tiny" type="button" onClick={onCancel}>
					{intl.formatMessage({
						id: "BTN_CANCEL",
						defaultMessage: "Cancel",
					})}
				</Button>
				<Button size="tiny" color="green" type="submit">
					{submitText}
				</Button>
			</Form.Field>
		</Form>
	</PixelFormContext.Provider>
	);
};
PixelForm.propTypes = {
	"formType": PropTypes.string,
};

export default PixelForm;
