import { Days } from "./enum/days";

export default class StrategyDaypart {
  constructor(id, startHour, endHour, days, userTime) {
    this.id = id || 0;
    this.start_hour = Number.parseInt(startHour, 10) || 0;
    this.end_hour = Number.parseInt(endHour, 10) || 0;
    this.days = days || Days.DEFAULT;
    this.user_time = Number.isNaN(Number(userTime)) ? 1 : userTime;
  }

  /**
   * transform data that comes from API/json
   * @param {object} json
   */
  static fromJson(json) {
    let days = Days.DEFAULT;
    try {
      days = Days.assign(json.days);
    } catch (e) {
      console.error(e);
    }

    return new StrategyDaypart(json.id, json.start_hour, json.end_hour, days, json.user_time);
  }

  /**
   * convert model into json format
   * @return {object}
   */
  toJson() {
    const { id, ...json } = this;
    return json;
  }
}
