import React from "react";
import PropTypes from "prop-types";
import {Form} from "semantic-ui-react";
import PixelFormContext from "../context";
import {RevenueType} from "../../../../models/enum/pixelRevenueType";

const PixelRevenueViewComponent = React.memo(({values}) => {
  const {intl} = React.useContext(PixelFormContext);

  return values.revenue_enabled? <>
    <Form.Field inline>
      <label>
        {intl.formatMessage({
          id: "LABEL_REVENUE",
          defaultMessage: "Revenue",
        })}
      </label>
      <div className="display-inline">{values.revenue}</div>
    </Form.Field>
    <Form.Field inline>
      <label>
        {intl.formatMessage({
          id: "LABEL_CURRENCY",
          defaultMessage: "Currency"
        })}
      </label>
      <div className="display-inline">{values.revenue_type === RevenueType.FIXED? values.currency_fixed : values.currency}</div>
    </Form.Field>
  </> : <Form.Field inline>
    <label>
      {intl.formatMessage({
        id: "LABEL_REVENUE_PAIR",
        defaultMessage: "Revenue Attribution Pair"
      })}
    </label>
    <div className="display-inline">{intl.formatMessage({
      id: "LABEL_DISABLED",
      defaultMessage: "Disabled"
    })}</div>
  </Form.Field>
}, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.values) === JSON.stringify(nextProps.values);
});
PixelRevenueViewComponent.propTypes = {
  "values": PropTypes.shape({
    "revenue_enabled": PropTypes.number.isRequired,
    "revenue_type": PropTypes.oneOf(["fixed", "dynamic"]),
    "currency_fixed": PropTypes.string,
    "revenue": PropTypes.string
  }).isRequired
};

export default PixelRevenueViewComponent;
