import { omit } from "lodash";
import { formatDateToISO, removeEmptyKeys } from "../libs/common_utils.js";

class CreativeModel {
  constructor(id, status, title, advertiserId, thirdParty, mraid, mraidType, landingPageUrl) {
    this.id = id || 0;
    this.status = status || 0;
    this.title = title || "";
    this.advertiser_id = advertiserId || 0;
    this.third_party = thirdParty || 0;
    this.mraid = mraid || 0;
    this.mraid_type = mraidType || "";
    this.landing_page_url = landingPageUrl || "";
  }
}

export class CreativeDisplay1PModel extends CreativeModel {
  constructor(
    id,
    status,
    title,
    advertiserId,
    thirdParty,
    mraid,
    mraidType,
    tag,
    fileType,
    clickthroughUrl,
    landingPageUrl,
    startDate,
    endDate,
  ) {
    super(id, status, title, advertiserId, thirdParty, mraid, mraidType, landingPageUrl);

    this.tag = tag || "";
    this.file_type = fileType || "";
    this.clickthrough_url = clickthroughUrl || "";
    this.landing_page_url = landingPageUrl || "";
    this.start_date = startDate ? formatDateToISO(startDate, true) : "";
    this.end_date = endDate ? formatDateToISO(endDate, true) : "";
    removeEmptyKeys(this);
  }

  /**
   * transform data that comes from API/json
   * @param {object} json
   */
  static fromJson(json) {
    return new CreativeDisplay1PModel(
      json.id,
      json.status,
      json.title,
      json.advertiser_id,
      json.third_party,
      json.mraid,
      json.mraid_type,
      json.tag,
      json.file_type,
      json.clickthrough_url,
      json.landing_page_url,
      json.start_date,
      json.end_date,
    );
  }

  /**
   * convert model into json format
   * @return {object}
   */
  toJson() {
    const json = {
      ...this,
    };

    const keysToOmit = [];
    if (!json.id) {
      keysToOmit.push("id");
      keysToOmit.push("file_type");

      // remove landing_page_url for create mode only
      if (!json.landing_page_url) {
        keysToOmit.push("landing_page_url");
      }
    }

    if (!json.mraid) {
      keysToOmit.push("mraid_type");
    }

    return omit(json, keysToOmit);
  }
}

export class CreativeDisplay3PModel extends CreativeModel {
  constructor(
    id,
    status,
    title,
    advertiserId,
    thirdParty,
    mraid,
    mraidType,
    tag,
    adServer,
    tpasTagId,
    tagType,
    isExpandable,
    expansionTrigger,
    expansionDirection,
    dimensions,
    fileType,
    landingPageUrl,
    startDate,
    endDate,
  ) {
    super(id, status, title, advertiserId, thirdParty, mraid, mraidType, landingPageUrl);

    this.tag = tag || "";
    this.ad_server = adServer || "";
    this.tpas_tag_id = tpasTagId || "";
    this.tag_type = tagType || "";
    this.is_expandable = isExpandable || 0;
    this.expansion_trigger = expansionTrigger || "";
    this.expansion_direction = expansionDirection || "";
    this.dimensions = dimensions || "";
    this.file_type = fileType || "";
    this.start_date = startDate ? formatDateToISO(startDate, true) : "";
    this.end_date = endDate ? formatDateToISO(endDate, true) : "";

    removeEmptyKeys(this);
  }

  /**
   * transform data that comes from API/json
   * @param {object} json
   */
  static fromJson(json) {
    return new CreativeDisplay3PModel(
      json.id,
      json.status,
      json.title,
      json.advertiser_id,
      json.third_party,
      json.mraid,
      json.mraid_type,
      json.tag,
      json.ad_server,
      json.tpas_tag_id,
      json.tag_type,
      json.is_expandable,
      json.expansion_trigger,
      json.expansion_direction,
      json.dimensions,
      json.file_type,
      json.landing_page_url,
      json.start_date,
      json.end_date,
    );
  }

  /**
   * convert model into json format
   * @return {object}
   */
  toJson() {
    const json = {
      ...this,
    };

    const keysToOmit = [];
    if (!json.id) {
      keysToOmit.push("id");

      // remove landing_page_url for create mode only
      if (!json.landing_page_url) {
        keysToOmit.push("landing_page_url");
      }
    }

    if (!json.mraid) {
      keysToOmit.push("mraid_type");
    }

    if (Array.isArray(json.expansion_direction)) {
      json.expansion_direction = json.expansion_direction.join(",");
    }

    if (!json.is_expandable) {
      keysToOmit.push("expansion_trigger");
      keysToOmit.push("expansion_direction");
    }

    return omit(json, keysToOmit);
  }
}
