import { isDigit } from "../../libs/common_utils";
import { Intervals, Types } from "../enum/pacing";

export default class Pacing {
  static MIN = 0.01;
  static MAX = 9_999_999.99;

  constructor(pacingType, pacingInterval, pacingAmount) {
    this.pacing_type = Types.assign(pacingType || Types.ASAP);
    this.pacing_interval = Intervals.assign(pacingInterval || Intervals.HOUR);
    this.pacing_amount = Number.parseFloat(pacingAmount) || 0;
  }

  /**
   * transform data that comes from API/json
   * @param {object} json
   */
  static fromJson(json) {
    return new Pacing(json.pacing_type, json.pacing_interval, json.pacing_amount);
  }

  /**
   * verify that pacing amount field is valid
   * @param {string} pacing_amount
   * @return {boolean}
   */
  static isValid(pacingAmount) {
    const value = Number.parseFloat(pacingAmount);
    return isDigit(value) && value >= Pacing.MIN && value <= Pacing.MAX;
  }

  /**
   * convert model into json format
   * @return {object}
   */
  toJson() {
    return {
      ...this,
    };
  }
}
