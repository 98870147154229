import isBefore from "date-fns/isBefore";
import isDate from "date-fns/isDate";
import { formatAPIDateTime } from "../../libs/common_utils.js";
export default class DateRange {
  constructor(startDate, endDate) {
    this.start_date = startDate ? new Date(startDate) : "";
    this.end_date = endDate ? new Date(endDate) : "";
  }

  /**
   * transform data that comes from API/json
   * @param {object} json
   */
  static fromJson(json) {
    const startDate = json.start_date || "";
    const endDate = json.end_date || "";

    return new DateRange(startDate.toString(), endDate.toString());
  }

  /**
   * verify that date field is valid
   * @param {date} startDate
   * @param {date} endDate
   * @return {boolean}
   */
  static isValid(startDate, endDate) {
    if (![startDate, endDate].every(isDate)) {
      return false;
    }

    return isBefore(startDate, endDate);
  }

  /**
   * convert model into json format
   * @return {object}
   */
  toJson() {
    return {
      start_date: this.start_date ? formatAPIDateTime(this.start_date) : "",
      end_date: this.end_date ? formatAPIDateTime(this.end_date) : "",
    };
  }
}
