import { Types } from "./enum/supply";

export default class StrategySuppliesSelected {
  constructor(strategyId, supplyType, supplyIds) {
    this.strategy_id = strategyId || 0;
    this.supply_type = Types.assign(supplyType) || Types.PMP;
    this.supply_ids = supplyIds || [];
  }

  /**
   * transform data that comes from API/json
   * @param {number} strategyId
   * @param {object} json
   */
  static fromJson(strategyId, json) {
    return new StrategySuppliesSelected(strategyId, json.supply_type, json.supply_ids);
  }

  /**
   * Do a validation
   * @return {boolean}
   */
  isValid() {
    if (~[Types.PMP, Types.PG, Types.OPEN_SUPPLY].indexOf(this.supply_type)) {
      return this.supply_ids.length > 0;
    }
    return true;
  }

  /**
   * convert model into json format
   * @return {object}
   */
  toJson() {
    return {
      ...this,
      // biome-ignore lint/style/useNamingConvention: <server params>
      strategy_id: Number.parseInt(this.strategy_id),
      // biome-ignore lint/style/useNamingConvention: <server params>
      supply_ids: this.supply_ids,
    };
  }
}
