import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Checkbox, Divider, Dropdown, Form, Grid, Input, Radio, Ref, Segment, Select } from "semantic-ui-react";
import { Config } from "../../../../config/api";
import { humanFileSize, isValidHttpUrl } from "../../../../libs/common_utils";
import CreativeModel, { CreativeVideo3PModel } from "../../../../models/creative_video";
import AgenciesService from "../../../../services/agencies";
import CreativesService from "../../../../services/creatives";
import { FileInputComponent } from "../../../common/fileInput";
import SuiRadioButtonGroup from "../../../common/suiRadioButtonGroup";
import { CreativePreviewer } from "../../creative_previewer/creativePreviewer";
import { default_button_text } from "../../fixtures";
import CreativeTypeLabel from "../common/creativeTypeLabel";
import OnSiteCreativeStatusChangeMessage from "../common/onSiteCreativeStatusChangeMessage";
import BannerCompanionComponent from "./components/banner_companion";
import "react-datepicker/dist/react-datepicker.css";
import { useFlags } from "launchdarkly-react-client-sdk";
import { omit } from "lodash";
import { useIntl } from "react-intl";
import { useForm } from "../../../../libs/component_utils";
import T1Service from "../../../../services/t1";
import StartEndDateFormField from "../../../common/StartEndDateFormField";
import OnSiteDateTimeControl from "../common/OnSiteDateTimeControl";
import VideoCreativeThirdPartyComponent from "./components/third_party";

const VideoFormContext = createContext();
/**
 * Creative create page component
 * @param history
 * @return {*}
 * @constructor
 */
const CreativeVideoForm = ({
  initialData,
  onSubmit,
  onCancel,
  loading,
  formType = "create",
  submitText = "Create Creative",
  onsite_enabled,
}) => {
  /** Errors from additional components. */
  const [additionalErrors, setAdditionalErrors] = useState({});
  // loaded page data
  const [pageData, setPageData] = useState(() => {
    return {
      advertisersLoading: true,
      advertisers: [],
    };
  });

  const fileSize = {
    // biome-ignore lint/style/useNamingConvention: these are constants
    MIN: 1000,
    // biome-ignore lint/style/useNamingConvention: these are constants
    MAX: 150e6,
  };

  const intl = useIntl();
  const fileRef = useRef();
  const { whitelabelCreativeCompanionAd, whitelabelCreativePreview } = useFlags();

  const services = useRef(
    new Map([
      ["creatives", new CreativesService()],
      ["t1", new T1Service()],
      ["agencies", new AgenciesService()],
    ]),
  );

  const [_, redraw] = useState();

  /** Fields from additional components. */
  const additionalFields = useRef({});
  const agency = services.current.get("agencies").getSelectedAgency() || 0;
  const isEditMode = formType.toString() === "edit";

  /**
   * initially loading advertisers list
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on "agency" change
  useEffect(() => {
    const service = services.current.get("t1");
    (async () => {
      try {
        const r = await service.advertisers(agency);
        /** @namespace {array} r.data **/

        setPageData({
          ...pageData,
          advertisersLoading: false,
          advertisers: pageData.advertisers.concat(
            r.data.map(({ id, title }) => ({
              key: id,
              text: title,
              value: id,
            })),
          ),
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  /**
   * updated fields from additional components
   * @param {object} fields
   */
  const updateAdditionalFields = (fields) => {
    additionalFields.current = {
      ...additionalFields.current,
      ...fields,
    };
  };

  /**
   * updated errors from additional components
   * @param {object} errors
   */
  // biome-ignore lint/correctness/noUnusedVariables: this will need to be rescoped in a future refactor
  const updateAdditionalErrors = (errors) => {
    additionalErrors.current = errors;
    redraw(Date.now());
  };

  /**
   * form submit handler
   * @return {boolean}
   */
  const addCreativeForm = async () => {
    const creative = values.third_party
      ? CreativeVideo3PModel.fromJson(values).toJson()
      : CreativeModel.fromJson(Object.assign(values, additionalFields.current));

    await onSubmit(
      creative,
      values.third_party ? null : fileRef.current.querySelector("[type='file']")?.files[0],
      values.creative_type,
    );
  };

  const {
    values,
    errors,
    onChange,
    updateValues,
    onSubmit: handleSubmit,
  } = useForm(
    addCreativeForm,
    () => {
      const found =
        initialData.button_text &&
        default_button_text(intl).find((x) => x.value.toLowerCase() === initialData.button_text.toLowerCase());

      return {
        ...initialData,
        // biome-ignore lint/style/useNamingConvention: <refactor next time>
        button_text_type: found ? "standard" : "custom",
      };
    },
    // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: refactor to remove complexity / calculations
    () => {
      const errors = { ...additionalErrors };

      if (values.title && !!values.title.match(/[^a-z0-9\-_ ]/i)) {
        errors.title = intl.formatMessage({
          defaultMessage: "Creative title may only contain letters, numbers, spaces, underscores, and dashes.",
          id: "ERROR_CREATIVE_TITLE_PATTERN",
        });
      }

      if (values.advertiser_id === null) {
        errors.advertiser_id = intl.formatMessage({
          defaultMessage: "Please select an advertiser.",
          id: "ERROR_EMPTY_ADVERTISER_ID",
        });
      }

      if (values.third_party) {
        if (values.custom_vast_url && !isValidHttpUrl(values.custom_vast_url)) {
          errors.custom_vast_url = intl.formatMessage(
            {
              defaultMessage: "{field_name} must be a valid URL",
              id: "VALIDATOR_VALID_URL_FIELD_NAME",
            },
            {
              // biome-ignore lint/style/useNamingConvention: <refactor next time>
              field_name: intl.formatMessage({
                defaultMessage: "VAST URL",
                id: "LABEL_VAST_URL",
              }),
            },
          );
        }
      } else {
        const fileInput = fileRef.current.querySelector("[type='file']");
        if (fileInput && fileInput.files.length === 0) {
          errors.creative_file = intl.formatMessage({
            defaultMessage: "Please select a creative asset.",
            id: "ERROR_EMPTY_CREATIVE_FILE",
          });
        } else if (fileInput && fileInput.files[0].type !== "video/mp4") {
          errors.creative_file = intl.formatMessage({
            defaultMessage: "Creative asset must be an MP4 file.",
            id: "ERROR_CREATIVE_FILE_WRONG_FORMAT",
          });
        }

        if (formType === "create" && fileInput.files[0]?.size) {
          // biome-ignore lint/style/useNamingConvention: <server prop>
          const file_size = fileInput.files[0].size;
          if (file_size < fileSize.MIN) {
            errors.creative_file = intl.formatMessage(
              {
                defaultMessage: "Creative asset size cannot be less than {file_min_size}.",
                id: "ERROR_CREATIVE_FILE_TOO_SMALL",
              },
              {
                // biome-ignore lint/style/useNamingConvention: <server prop>
                file_size: humanFileSize(fileSize.MIN),
              },
            );
          }

          if (file_size > fileSize.MAX) {
            errors.creative_file = intl.formatMessage({
              defaultMessage: "Creative asset size cannot be more than 150 mb.",
              id: "ERROR_CREATIVE_FILE_TOO_LARGE",
            });
          }
        }
      }

      if (
        (initialData.start_date && values.start_date === null) ||
        (initialData.end_date && values.end_date === null)
      ) {
        errors.hasInitialDate = intl.formatMessage({
          defaultMessage: "Creatives with an initial start/end date  must have start/end date set",
          id: "CANNOT_EMPTY_CREATIVE_DATE",
        });
      }

      return errors;
    },
  );

  /**
   * handle creative type change
   * @param {Event} e
   * @param {object} input
   */
  const handleCreativeTypeChange = useCallback(
    (_, input) => {
      updateValues({
        ...values,
        instoreDOOH: input.value === "instoreDOOH" ? 1 : 0,
        // biome-ignore lint/style/useNamingConvention: <server prop>
        creative_type: input.value,
        // biome-ignore lint/style/useNamingConvention: <server prop>
        third_party: input.value === "on_site" || input.value === "instoreDOOH" ? 0 : values.third_party,
      });
    },
    [values, updateValues],
  );

  /**
   * handle end date change
   * @param {Date} date
   */
  const handleEndDateChange = useCallback(
    (date) => {
      onChange(null, { name: "end_date", value: date });
    },
    [onChange],
  );

  const isEditOnSite = (fields) => {
    return isEditMode && fields.creative_type === "on_site";
  };

  const creativeTypeRadios = [
    {
      default: true,
      label: intl.formatMessage({
        defaultMessage: "On-site",
        id: "LABEL_CREATIVE_TYPE_ON_SITE",
      }),
      value: "on_site",
      onChange: handleCreativeTypeChange,
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Off-site",
        id: "LABEL_CREATIVE_TYPE_OFF_SITE",
      }),
      value: "off_site",
      onChange: handleCreativeTypeChange,
    },
    {
      label: intl.formatMessage({
        defaultMessage: "In-store DOOH",
        id: "LABEL_CREATIVE_TYPE_INSTORE_DOOH",
      }),
      value: "instoreDOOH",
      onChange: handleCreativeTypeChange,
    },
  ];

  const statusRadios = [
    {
      default: true,
      label: intl.formatMessage({
        defaultMessage: "Active",
        id: "STATUS_ACTIVE",
      }),
      value: 1,
      onChange: onChange,
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Inactive",
        id: "STATUS_INACTIVE",
      }),
      value: 0,
      onChange: onChange,
    },
  ];

  const thirdPartyRadios = [
    {
      label: intl.formatMessage({
        defaultMessage: "Upload a creative asset",
        id: "ASSET_FIRST_PARTY",
      }),
      value: 0,
      onChange: onChange,
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Enter a third party ad tag",
        id: "ASSET_THIRD_PARTY",
      }),
      value: 1,
      onChange: onChange,
    },
  ];

  return (
    <>
      {isEditOnSite(values) && <OnSiteCreativeStatusChangeMessage />}
      <Form
        autoComplete="off"
        error={!!Object.keys(errors).length}
        loading={loading}
        noValidate
        onSubmit={handleSubmit}
        size="small"
      >
        <SuiRadioButtonGroup
          groupLabel={intl.formatMessage({
            defaultMessage: "Status",
            id: "LABEL_STATUS",
          })}
          name="status"
          options={statusRadios}
          value={values.status}
        />

        <Form.Field
          error={Object.hasOwn(errors, "title")}
          inline
          required
        >
          <label>
            {intl.formatMessage({
              defaultMessage: "Creative Name",
              id: "LABEL_CREATIVE_NAME",
            })}
          </label>
          <Input
            defaultValue={values.title}
            disabled={isEditOnSite(values)}
            maxLength={100}
            minLength={1}
            name="title"
            onBlur={onChange}
            required
          />
          <div className="custom-error">{errors.title}</div>
        </Form.Field>

        <Form.Field
          error={Object.hasOwn(errors, "advertiser_id")}
          inline
          required
        >
          <label>
            {intl.formatMessage({
              defaultMessage: "Advertiser",
              id: "LABEL_ADVERTISER",
            })}
          </label>
          <Dropdown
            disabled={isEditMode}
            loading={pageData.advertisersLoading}
            name="advertiser_id"
            onChange={onChange}
            options={pageData.advertisers}
            placeholder={intl.formatMessage({
              defaultMessage: "Select advertiser",
              id: "HINT_ADVERTISER",
            })}
            required
            search
            selection
            value={values.advertiser_id}
          />
          <div className="custom-error">{errors.advertiser_id}</div>
        </Form.Field>

        {onsite_enabled && (
          <>
            <SuiRadioButtonGroup
              disabled={isEditMode}
              groupLabel={<CreativeTypeLabel onsiteAndInstoreDOOH={true} />}
              name="creative_type"
              options={creativeTypeRadios}
              value={values.creative_type}
            />

            {values.creative_type === "on_site" || values.creative_type === "instoreDOOH" ? (
              <OnSiteDateTimeControl
                config={Config}
                endDate={values.end_date}
                error={errors.end_date}
                isEdit={isEditMode}
                onChange={handleEndDateChange}
              />
            ) : (
              <StartEndDateFormField
                error={errors.hasInitialDate}
                isEditMode={isEditMode}
                shouldFilterStartPassedTime={false}
                updateValues={updateValues}
                values={values}
              />
            )}
          </>
        )}

        {!isEditMode && values.creative_type !== "on_site" && values.creative_type !== "instoreDOOH" && (
          <SuiRadioButtonGroup
            groupLabel={intl.formatMessage({
              defaultMessage: "Asset",
              id: "LABEL_ASSET",
            })}
            name="third_party"
            options={thirdPartyRadios}
            value={values.third_party}
          />
        )}

        {Boolean(values.third_party) && (
          <VideoCreativeThirdPartyComponent
            errors={errors}
            onChange={onChange}
            values={values}
          />
        )}

        {!values.third_party && (
          <>
            <Form.Field
              error={Object.hasOwn(errors, "creative_file")}
              inline
              required
            >
              <label>
                {intl.formatMessage({
                  defaultMessage: "Asset",
                  id: "LABEL_ASSET",
                })}
              </label>
              <Ref innerRef={fileRef}>
                {isEditMode ? (
                  <span>{initialData.creative_file}</span>
                ) : (
                  <FileInputComponent
                    accept=".mp4"
                    placeholder={intl.formatMessage({
                      defaultMessage: "Upload a MP4 file from your computer",
                      id: "HINT_ASSET_MP4",
                    })}
                    required={true}
                  />
                )}
              </Ref>
              <div className="custom-error">{errors.creative_file}</div>
            </Form.Field>

            <Form.Field
              error={Object.hasOwn(errors, "clickthrough_url")}
              inline
              required
            >
              <label>
                {intl.formatMessage({
                  defaultMessage: "Clickthrough URL",
                  id: "LABEL_CLICKTHROUGH_URL",
                })}
              </label>
              <Input
                defaultValue={values.clickthrough_url}
                name="clickthrough_url"
                onBlur={onChange}
                pattern="https?://.*\..*"
                placeholder="http://www.example.com/"
                required
                style={{ width: "300px" }}
                title={intl.formatMessage({
                  defaultMessage: "must be a valid non-local HTTP URL",
                  id: "ERROR_URL_PATTERN",
                })}
                type="url"
                disabled={isEditOnSite(values)}
              />
              <div className="custom-error">{errors.clickthrough_url}</div>
            </Form.Field>

            <Form.Field
              error={Object.hasOwn(errors, "landing_page_url")}
              inline
              required
            >
              <label>
                {intl.formatMessage({
                  defaultMessage: "Landing Page URL",
                  id: "LABEL_LANDING_PAGE_URL",
                })}
              </label>
              <Input
                defaultValue={values.landing_page_url}
                disabled={isEditOnSite(values)}
                name="landing_page_url"
                onBlur={onChange}
                pattern="https?://.*\..*"
                placeholder="http://www.example.com/"
                required
                style={{ width: "300px" }}
                title={intl.formatMessage({
                  defaultMessage: "must be a valid non-local HTTP URL",
                  id: "ERROR_URL_PATTERN",
                })}
                type="url"
              />
              <div className="custom-error">{errors.landing_page_url}</div>
            </Form.Field>

            <VideoFormContext.Provider
              value={{
                errors: Object.assign(errors, additionalErrors.current),
              }}
            >
              {whitelabelCreativeCompanionAd && (
                <VideoCompanionAdBlock
                  update={updateAdditionalFields}
                  updateAdditionalErrors={setAdditionalErrors}
                  values={values}
                />
              )}

              {initialData.id && whitelabelCreativePreview && (
                <Form.Field
                  inline
                  style={{ verticalAlign: "top" }}
                >
                  <label style={{ verticalAlign: "top" }}>
                    {intl.formatMessage({
                      defaultMessage: "Creative Preview",
                      id: "LABEL_CREATIVE_PREVIEW",
                    })}
                  </label>
                  <div className="ui input">
                    <CreativePreviewer
                      creativeId={initialData.id}
                      hideDataTables
                    />
                  </div>
                </Form.Field>
              )}
            </VideoFormContext.Provider>
          </>
        )}

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <Form.Field
          align="right"
          disabled={pageData.advertisersLoading}
        >
          <Button
            onClick={onCancel}
            size="tiny"
            type="button"
          >
            {intl.formatMessage({
              defaultMessage: "Cancel",
              id: "BTN_CANCEL",
            })}
          </Button>
          <Button
            size="tiny"
            color="green"
            type="submit"
          >
            {submitText}
          </Button>
        </Form.Field>
      </Form>
    </>
  );
};

const VideoCompanionAdBlock = ({ values, update, updateAdditionalErrors }) => {
  const intl = useIntl();
  const [showBlock, setShowBlock] = useState(Boolean(values.companion_ad));
  return (
    <>
      <Form.Field inline>
        <label>
          {intl.formatMessage({
            defaultMessage: "Companion Ad",
            id: "LABEL_COMPANION_AD",
          })}
        </label>
        {/* TODO: unhardcode this label: */}
        <Checkbox
          checked={showBlock}
          label="Hotstar Companion Banner"
          name="companion_ad"
          onClick={() => {
            // biome-ignore lint/style/useNamingConvention: <server prop>
            update({ companion_ad: +!showBlock });
            setShowBlock(!showBlock);
          }}
        />
      </Form.Field>
      {showBlock && (
        <Segment
          secondary
          style={{ fontSize: "unset" }}
        >
          <AdditionalVideoCreativeFields
            root_values={values}
            updateAdditionalErrors={updateAdditionalErrors}
            update={update}
          />
        </Segment>
      )}
    </>
  );
};
VideoCompanionAdBlock.propTypes = {
  values: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
};

const AdditionalVideoCreativeFields = ({ root_values, update, updateAdditionalErrors }) => {
  const intl = useIntl();

  const { values, errors, setErrors, onChange } = useForm(
    // biome-ignore lint/suspicious/noEmptyBlockStatements: this may require a refactor of useForm to allow null values
    () => {},
    {
      // biome-ignore lint/style/useNamingConvention: <server prop>
      brand_name: root_values.brand_name || "",
      // biome-ignore lint/style/useNamingConvention: <server prop>
      ad_title: root_values.ad_title || "",
      // biome-ignore lint/style/useNamingConvention: <server prop>
      logo_url: root_values.logo_url || "",
      // biome-ignore lint/style/useNamingConvention: <server prop>
      button_color: root_values.button_color || "#f2711c",
      // biome-ignore lint/style/useNamingConvention: <server prop>
      button_text_type: root_values.button_text_type || "standard",
      // biome-ignore lint/style/useNamingConvention: <server prop>
      button_text: root_values.button_text || default_button_text(intl)[0].value,
      // biome-ignore lint/style/useNamingConvention: <server prop>
      deep_link: root_values.deep_link || "",
    },
    // biome-ignore lint/suspicious/noEmptyBlockStatements: this may require a refactor of useForm to allow null values
    () => {},
  );

  const context = useContext(VideoFormContext);

  // update parent component
  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on "update" change
  useEffect(() => {
    update(values);
  }, [values]);

  // update errors for root component
  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on "updateAdditionalErrors" change
  useEffect(() => {
    updateAdditionalErrors(errors);
  }, [errors]);

  /**
   * get value selected for button text dropdown
   * @param {string} selected
   * @returns {string}
   */
  const getButtonTextSelected = (selected) => {
    const found = default_button_text(intl).find((x) => x.value.toLowerCase() === selected.toLowerCase());
    return found ? found.value : default_button_text(intl)[0].value;
  };

  /**
   * update logotype
   * @param e
   */
  const onUpdateLogo = (e) => {
    const url = e.target.value;

    setErrors(omit(errors, "logo_url"));

    if (!url) {
      onChange(null, { name: "logo_url", value: "" });
      return;
    }

    if (!url.match(/png$/i)) {
      errors.logo_url = intl.formatMessage({
        defaultMessage: "Logo should be in PNG format",
        id: "ERROR_LOGO_URL_WRONG_FORMAT",
      });
      setErrors({
        ...errors,
      });
      return;
    }

    const i = new Image();
    i.onload = () => {
      if (i.width !== 120 || i.height !== 120) {
        errors.logo_url = intl.formatMessage({
          defaultMessage: "Logo should be 120x120",
          id: "ERROR_LOGO_URL_WRONG_DIMENSIONS",
        });
        setErrors({
          ...errors,
        });
        return;
      }

      onChange(null, { name: "logo_url", value: url });
    };

    i.onerror = () => {
      errors.logo_url = intl.formatMessage({
        defaultMessage: "Logo should be publicly accessible",
        id: "ERROR_LOGO_URL_INACCESSIBLE",
      });
      setErrors({
        ...errors,
      });
    };
    i.src = url;
  };

  /**
   * verify and assign deep link
   * @param e
   */
  const onUpdateDeepLink = (e) => {
    const value = e.target.value;

    setErrors(omit(errors, "deep_link"));

    if (!value) {
      onChange(null, { name: "deep_link", value: "" });
      return;
    }

    if (!value.match(/^.+:\/\/.+\/.+/i)) {
      errors.deep_link = intl.formatMessage({
        defaultMessage: "Please provide correct link",
        id: "ERROR_DEEP_LINK_PATTERN",
      });
      setErrors({
        ...errors,
      });

      return;
    }

    onChange(null, { name: "deep_link", value: value });
  };

  return (
    <>
      <Grid
        className="companion_creative"
        columns="equal"
      >
        <Grid.Row>
          <Grid.Column>
            <Form.Field
              inline
              required
              error={Object.hasOwn(context.errors, "brand_name")}
            >
              <label>
                {intl.formatMessage({
                  defaultMessage: "Brand Name",
                  id: "LABEL_BRAND_NAME",
                })}
              </label>
              <Input
                defaultValue={values.brand_name}
                maxLength={20}
                minLength={1}
                name="brand_name"
                onChange={onChange}
                required
                size="small"
              />
              <div className="custom-error">{context.errors.brand_name}</div>
            </Form.Field>
            <Form.Field
              error={Object.hasOwn(context.errors, "ad_title")}
              inline
              required
            >
              <label>
                {intl.formatMessage({
                  defaultMessage: "Ad Title",
                  id: "LABEL_AD_TITLE",
                })}
              </label>
              <Input
                defaultValue={values.ad_title}
                maxLength={24}
                minLength={1}
                name="ad_title"
                onChange={onChange}
                required
                size="small"
              />
              <div className="custom-error">{context.errors.ad_title}</div>
            </Form.Field>
            <Form.Field
              error={Object.hasOwn(context.errors, "logo_url")}
              inline
              required
            >
              <label>
                {intl.formatMessage({
                  defaultMessage: "Brand Logo URL",
                  id: "LABEL_BRAND_LOGO_URL",
                })}
              </label>
              <Input
                defaultValue={values.logo_url}
                name="logo_url"
                onBlur={onUpdateLogo}
                required
                size="small"
                style={{ width: "300px" }}
              />
              <div className="custom-error">{context.errors.logo_url}</div>
            </Form.Field>
            <Form.Field inline>
              <label>
                {intl.formatMessage({
                  defaultMessage: "Button Colour",
                  id: "LABEL_BUTTON_COLOUR",
                })}
              </label>
              <Input
                defaultValue={values.button_color}
                name="button_color"
                onBlur={onChange}
                size="small"
                type="color"
              />
            </Form.Field>
            <Form.Field inline>
              <label style={{ top: "-5px" }}>
                {intl.formatMessage({
                  defaultMessage: "Button Text",
                  id: "LABEL_CREATIVE_BTN_TEXT",
                })}
              </label>
              <div style={{ display: "inline-block" }}>
                <div>
                  <Radio
                    label={intl.formatMessage({
                      id: "CREATIVE_BTN_TEXT_STANDARD",
                      defaultMessage: "Standard",
                    })}
                    name="button_text_type"
                    value="standard"
                    checked={values.button_text_type === "standard"}
                    style={{ marginRight: "20px" }}
                    onClick={onChange}
                  />
                  <Radio
                    label={intl.formatMessage({
                      id: "CREATIVE_BTN_TEXT_CUSTOM",
                      defaultMessage: "Custom",
                    })}
                    value="custom"
                    checked={values.button_text_type === "custom"}
                    name="button_text_type"
                    onClick={onChange}
                  />
                </div>
                <div style={{ marginTop: "15px" }}>
                  {values.button_text_type === "standard" && (
                    <Select
                      name="button_text"
                      onChange={onChange}
                      options={default_button_text(intl)}
                      size="small"
                      value={getButtonTextSelected(values.button_text)}
                    />
                  )}
                  {values.button_text_type === "custom" && (
                    <Input
                      maxLength={10}
                      minLength={1}
                      name="button_text"
                      onChange={onChange}
                      size="small"
                      value={values.button_text}
                    />
                  )}
                </div>
              </div>
            </Form.Field>
            <Form.Field
              error={Object.hasOwn(context.errors, "deep_link")}
              inline
            >
              <label>
                {intl.formatMessage({
                  defaultMessage: "Mobile Deep Link",
                  id: "LABEL_MOBILE_DEEP_LINK",
                })}
              </label>
              <Input
                defaultValue={values.deep_link}
                name="deep_link"
                onBlur={onUpdateDeepLink}
                size="small"
                style={{ width: "300px" }}
                type="text"
              />
              <div className="custom-error">{context.errors.deep_link}</div>
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <BannerCompanionComponent {...values} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

AdditionalVideoCreativeFields.propTypes = {
  // biome-ignore lint/style/useNamingConvention: <server prop>
  root_values: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
};

export default CreativeVideoForm;
