import { Common } from "./index";

export const Intervals = Object.freeze({
  HOUR: "hour",
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  NOT_APPLICABLE: "not-applicable",
  ...Common,
});

export const Types = Object.freeze({ ASAP: "asap", NO_LIMIT: "no-limit", EVEN: "even", ...Common });
