import { useContext } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { Button, Menu } from "semantic-ui-react";
import CreativesGridContext from "../../index/context";

export const CreativesTabsMenu = ({ creativeType = "display" }) => {
  const context = useContext(CreativesGridContext);
  const intl = useIntl();
  const { whitelabelChannelDisplay, whitelabelChannelVideo, whitelabelDoubleCreativeApproval } = context.ld_flags;
  const isVideo = creativeType === "video";
  const isDisplay = creativeType === "display";

  /**
   * generate button title based on creative_type
   * @param {boolean} isVideo
   * @returns {string}
   */
  const getCreateButtonTitle = () => {
    return isVideo
      ? intl.formatMessage({
          defaultMessage: "Add Video Creative",
          id: "BTN_ADD_VIDEO_CREATIVE",
        })
      : intl.formatMessage({
          defaultMessage: "Add Display Creative",
          id: "BTN_ADD_DISPLAY_CREATIVE",
        });
  };

  return (
    <Menu
      pointing
      secondary
    >
      {whitelabelChannelDisplay && (
        <Menu.Item
          active={creativeType === "display"}
          as={NavLink}
          content={intl.formatMessage({
            defaultMessage: "Display",
            id: "LINK_DISPLAY",
          })}
          to="/creatives/display"
        />
      )}

      {whitelabelChannelVideo && (
        <Menu.Item
          active={creativeType === "video"}
          as={NavLink}
          content={intl.formatMessage({
            defaultMessage: "Video",
            id: "LINK_VIDEO",
          })}
          to="/creatives/video"
        />
      )}

      {whitelabelDoubleCreativeApproval && (
        <>
          <Menu.Item
            active={creativeType === "onsite"}
            as={NavLink}
            content={intl.formatMessage({
              defaultMessage: "On-Site Creatives",
              id: "LINK_ON_SITE_CREATIVES",
            })}
            to="/creatives/onsite"
          />

          <Menu.Item
            active={creativeType === "instoreDOOH"}
            as={NavLink}
            content={intl.formatMessage({
              defaultMessage: "In-store DOOH Creatives",
              id: "LINK_INSTORE_DOOH_CREATIVES",
            })}
            to="/creatives/in-store-dooh"
          />
        </>
      )}

      {(isVideo || isDisplay) && (
        <Menu.Menu position="right">
          <Menu.Item>
            <Button
              primary
              compact
              className="text__uppercase"
              onClick={() => context.navigateToCreatePage(isVideo)}
              content={getCreateButtonTitle(isVideo)}
            />
          </Menu.Item>
        </Menu.Menu>
      )}
    </Menu>
  );
};

export default CreativesTabsMenu;
