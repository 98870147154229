import { omit } from "lodash";
import { isDigit } from "../libs/common_utils";
import DateRange from "./object_value/date_range";
import Frequency from "./object_value/frequency";
import Goal from "./object_value/goal";
import Pacing from "./object_value/pacing";

export default class DraftModel {
  static BUDGET = {
    MIN: 0.01,
    MAX: 9_999_999.99,
  };

  constructor(
    id,
    t1Id,
    campaignId,
    status,
    strategyName,
    useCampaignStart,
    useCampaignEnd,
    dateRange,
    budget,
    goal,
    minBid,
    maxBid,
    bidAggressiveness,
    bidPriceIsMediaOnly,
    pacing,
    frequency,
    mediaType,
    strategyPlacementType,
  ) {
    this.id = id || 0;
    this.t1_id = t1Id || 0;
    this.campaign_id = campaignId || 0;
    this.status = Boolean(status);
    this.strategy_name = strategyName || "";
    this.use_campaign_start = useCampaignStart || 0;
    this.use_campaign_end = useCampaignEnd || 0;
    this.date_range = dateRange || new DateRange();
    this.budget = Number.parseFloat(budget) || "";
    this.bid_price_is_media_only = Boolean(bidPriceIsMediaOnly);
    this.goal = goal || new Goal();
    this.min_bid = Number.isNaN(Number.parseFloat(minBid)) ? null : Number.parseFloat(minBid);
    this.max_bid = Number.isNaN(Number.parseFloat(maxBid)) ? null : Number.parseFloat(maxBid);
    this.bid_aggressiveness = Number.isNaN(Number.parseFloat(bidAggressiveness))
      ? null
      : Number.parseFloat(bidAggressiveness);
    this.pacing = pacing || new Pacing();
    this.frequency = frequency || new Frequency();
    this.media_type = mediaType || "video";
    this.strategy_placement_type = strategyPlacementType || "";
  }

  /**
   * transform data that comes from API/json
   * @param {object} json
   */
  static fromJson(json) {
    return new DraftModel(
      json.id,
      json.t1_id,
      json.campaign_id,
      json.status,
      json.strategy_name,
      json.use_campaign_start,
      json.use_campaign_end,
      DateRange.fromJson(json),
      json.budget,
      Goal.fromJson(json),
      json.min_bid,
      json.max_bid,
      json.bid_aggressiveness,
      Boolean(json.bid_price_is_media_only),
      Pacing.fromJson(json),
      Frequency.fromJson(json),
      json.media_type,
      json.strategy_placement_type,
    );
  }

  /**
   * verify that budget field is valid
   * @param {string} budget
   * @return {boolean}
   */
  static budgetIsValid(budget) {
    const value = Number.parseFloat(budget);
    return isDigit(value) && value >= DraftModel.BUDGET.MIN;
  }

  /**
   * check that object is in edit mode
   * @return {boolean}
   */
  isEditMode() {
    return Boolean(this.id || this.t1_id);
  }

  /**
   * convert model into json format
   * @return {object}
   */
  toJson() {
    const json = {
      ...this,
      ...this.frequency.toJson(),
      ...this.pacing.toJson(),
      ...this.goal.toJson(),
      ...this.date_range.toJson(),
    };

    const keysToOmit = ["frequency", "pacing", "goal", "date_range"];

    if (!json.id) keysToOmit.push("id");
    if (!json.t1_id) keysToOmit.push("t1_id");
    if (!json.min_bid) keysToOmit.push("min_bid");
    if (!json.max_bid) keysToOmit.push("max_bid");
    if (this.isEditMode()) {
      json.budget = json.budget ? json.budget : null;
    } else if (!json.budget) {
      keysToOmit.push("budget");
    }

    return omit(json, keysToOmit);
  }
}
