import { isInt } from "../../libs/common_utils";
import { Intervals, Types } from "../enum/frequency";

export default class Frequency {
  static MIN = 1;
  static MAX = 9_999_999;

  constructor(optimization, type, amount, interval) {
    this.frequency_optimization = optimization || 0;
    this.frequency_type = Types.assign(type || Types.ASAP);
    this.frequency_amount = Number.parseInt(amount, 10) || 0;
    this.frequency_interval = Intervals.assign(interval || Intervals.HOUR);
  }

  /**
   * transform data that comes from API/json
   * @param {object} json
   */
  static fromJson(json) {
    return new Frequency(
      json.frequency_optimization,
      json.frequency_type,
      json.frequency_amount,
      json.frequency_interval,
    );
  }

  /**
   * check for no-limit size
   * @param frequency_type
   * @return {boolean}
   */
  static isNoLimit(frequencyType) {
    return frequencyType.toLowerCase() === Types.NO_LIMIT;
  }

  /**
   * verify that frequency amount field is valid
   * @param {string} freq_amount
   * @return {boolean}
   */
  static isValid(freqAmount) {
    const value = Number.parseInt(freqAmount, 10);
    return isInt(value) && value >= Frequency.MIN && value <= Frequency.MAX;
  }

  /**
   * convert model into json format
   * @return {object}
   */
  toJson() {
    if (this.frequency_optimization) {
      return {
        frequency_optimization: this.frequency_optimization,
      };
    }

    if (Frequency.isNoLimit(this.frequency_type)) {
      return {
        frequency_optimization: this.frequency_optimization,
        frequency_type: this.frequency_type,
      };
    }

    return {
      ...this,
    };
  }
}
