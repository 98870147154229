export const daily_pacing_options = (intl) => [
	{
		"text": intl.formatMessage({
			id: "DAILY_PACING_ASAP",
			defaultMessage: "ASAP",
		}),
		"value": "asap",
	},
	{
		"text": intl.formatMessage({
			id: "DAILY_PACING_EVEN",
			defaultMessage: "Even",
		}),
		"value": "even",
	},
];

export const media_type_options = (intl) => [
	{
		"text": intl.formatMessage({
			id: "MEDIA_TYPE_DISPLAY",
			defaultMessage: "Display",
		}),
		"value": "display",
	},
	{
		"text": intl.formatMessage({
			id: "MEDIA_TYPE_VIDEO",
			defaultMessage: "Video",
		}),
		"value": "video",
	},
];

export const daily_pacing_intervals = (intl) => [
	{
		"text": intl.formatMessage({
			id: "PACING_INTERVAL_DAY",
			defaultMessage: "Day",
		}),
		"value": "day",
	},
	{
		"text": intl.formatMessage({
			id: "PACING_INTERVAL_HOUR",
			defaultMessage: "Hour",
		}),
		"value": "hour",
	},
];

export const frequency_options = (intl) => [
	{
		"text": intl.formatMessage({
			id: "FREQUENCY_ASAP",
			defaultMessage: "ASAP",
		}),
		"value": "asap",
	},
	{
		"text": intl.formatMessage({
			id: "FREQUENCY_EVEN",
			defaultMessage: "Even",
		}),
		"value": "even",
	},
	{
		"text": intl.formatMessage({
			id: "FREQUENCY_NO_CAP",
			defaultMessage: "No Cap",
		}),
		"value": "no-limit",
	},
];

export const frequency_intervals = (intl) => [
	{
		"text": intl.formatMessage({
			id: "FREQENCY_INTERVAL_HOUR",
			defaultMessage: "Hour",
		}),
		"value": "hour",
	},
	{
		"text": intl.formatMessage({
			id: "FREQENCY_INTERVAL_DAY",
			defaultMessage: "Day",
		}),
		"value": "day",
	},
	{
		"text": intl.formatMessage({
			id: "FREQENCY_INTERVAL_WEEK",
			defaultMessage: "Week",
		}),
		"value": "week",
	},
	{
		"text": intl.formatMessage({
			id: "FREQENCY_INTERVAL_MONTH",
			defaultMessage: "Month",
		}),
		"value": "month",
	},
];

export const goal_media_options = intl=>[
	{"text": intl.formatMessage({
		id: "GOAL_MEDIA_ONLY",
		defaultMessage: "Media Only",
	}), "value": true},
	{"text": intl.formatMessage({
		id: "GOAL_TOTAL_SPEND",
		defaultMessage: "Total Spend",
	}), "value": false}
];

/**
 * Controls display options for various goal types.
 * Option labels are returned by the backend, so we don't include them here.
 */
export const goal_options = [
	{"value": "cpa", "display": "currency"},
	{"value": "roi", "display": "roi"},
	{"value": "cpc", "display": "currency"},
	{"value": "ctr", "display": "percent"},
	{"value": "reach", "display": "currency"},
	{"value": "spend", "display": "currency"},
	{"value": "vcpm", "display": "currency"},
	{"value": "vcr", "display": "percent"},
	{"value": "viewability_rate", "display": "percent"}
];

export const campaign_goal_matrix = {
	"roi": new Set(["roi"]),
	"cpa": new Set(["roi", "cpa"]),
	"cpc": new Set(["roi", "cpa", "cpc", "ctr", "vcr", "viewability_rate", "vcpm"]),
	"ctr": new Set(["roi", "cpa", "cpc", "ctr", "vcr", "viewability_rate", "vcpm"]),
	"vcr": new Set(["roi", "cpa", "cpc", "ctr", "vcr", "viewability_rate", "vcpm"]),
	"viewability_rate": new Set(["roi", "cpa", "cpc", "ctr", "vcr", "viewability_rate", "vcpm"]),
	"vcpm": new Set(["roi", "cpa", "cpc", "ctr", "vcr", "viewability_rate", "vcpm"]),
	"reach": new Set(["roi", "cpa", "cpc", "ctr", "vcr", "viewability_rate", "vcpm", "reach"]),
	"spend": new Set(["roi", "cpa", "cpc", "ctr", "vcr", "viewability_rate", "vcpm", "reach", "spend"])
};

export const dayparts_days = (intl) => [
	{
		"text": intl.formatMessage({
			id: "DAYPARTS_ALL_DAYS",
			defaultMessage: "All days",
		}),
		"value": "MTWRFSU",
	},
	{
		"text": intl.formatMessage({
			id: "DAYPARTS_WEEKDAYS",
			defaultMessage: "Mondays - Fridays",
		}),
		"value": "MTWRF",
	},
	{
		"text": intl.formatMessage({
			id: "DAYPARTS_WEEKENDS",
			defaultMessage: "Saturdays & Sundays",
		}),
		"value": "SU",
	},
	{
		"text": intl.formatMessage({
			id: "DAYPARTS_MONDAYS",
			defaultMessage: "Mondays",
		}),
		"value": "M",
	},
	{
		"text": intl.formatMessage({
			id: "DAYPARTS_TUESDAYS",
			defaultMessage: "Tuesdays",
		}),
		"value": "T",
	},
	{
		"text": intl.formatMessage({
			id: "DAYPARTS_WEDNESDAYS",
			defaultMessage: "Wednesdays",
		}),
		"value": "W",
	},
	{
		"text": intl.formatMessage({
			id: "DAYPARTS_THURSDAYS",
			defaultMessage: "Thursdays",
		}),
		"value": "R",
	},
	{
		"text": intl.formatMessage({
			id: "DAYPARTS_FRIDAYS",
			defaultMessage: "Fridays",
		}),
		"value": "F",
	},
	{
		"text": intl.formatMessage({
			id: "DAYPARTS_SATURDAYS",
			defaultMessage: "Saturdays",
		}),
		"value": "S",
	},
	{
		"text": intl.formatMessage({
			id: "DAYPARTS_SUNDAYS",
			defaultMessage: "Sundays",
		}),
		"value": "U",
	},
];

export const dayparts_start_hours = [
	{"text": "12:00 AM", "value": "0"},
	{"text": "1:00 AM", "value": "1"},
	{"text": "2:00 AM", "value": "2"},
	{"text": "3:00 AM", "value": "3"},
	{"text": "4:00 AM", "value": "4"},
	{"text": "5:00 AM", "value": "5"},
	{"text": "6:00 AM", "value": "6"},
	{"text": "7:00 AM", "value": "7"},
	{"text": "8:00 AM", "value": "8"},
	{"text": "9:00 AM", "value": "9"},
	{"text": "10:00 AM", "value": "10"},
	{"text": "11:00 AM", "value": "11"},
	{"text": "12:00 PM", "value": "12"},
	{"text": "1:00 PM", "value": "13"},
	{"text": "2:00 PM", "value": "14"},
	{"text": "3:00 PM", "value": "15"},
	{"text": "4:00 PM", "value": "16"},
	{"text": "5:00 PM", "value": "17"},
	{"text": "6:00 PM", "value": "18"},
	{"text": "7:00 PM", "value": "19"},
	{"text": "8:00 PM", "value": "20"},
	{"text": "9:00 PM", "value": "21"},
	{"text": "10:00 PM", "value": "22"},
	{"text": "11:00 PM", "value": "23"}
];

export const dayparts_end_hours = [
	{"text": "12:59 AM", "value": "0"},
	{"text": "1:59 AM", "value": "1"},
	{"text": "2:59 AM", "value": "2"},
	{"text": "3:59 AM", "value": "3"},
	{"text": "4:59 AM", "value": "4"},
	{"text": "5:59 AM", "value": "5"},
	{"text": "6:59 AM", "value": "6"},
	{"text": "7:59 AM", "value": "7"},
	{"text": "8:59 AM", "value": "8"},
	{"text": "9:59 AM", "value": "9"},
	{"text": "10:59 AM", "value": "10"},
	{"text": "11:59 AM", "value": "11"},
	{"text": "12:59 PM", "value": "12"},
	{"text": "1:59 PM", "value": "13"},
	{"text": "2:59 PM", "value": "14"},
	{"text": "3:59 PM", "value": "15"},
	{"text": "4:59 PM", "value": "16"},
	{"text": "5:59 PM", "value": "17"},
	{"text": "6:59 PM", "value": "18"},
	{"text": "7:59 PM", "value": "19"},
	{"text": "8:59 PM", "value": "20"},
	{"text": "9:59 PM", "value": "21"},
	{"text": "10:59 PM", "value": "22"},
	{"text": "11:59 PM", "value": "23"}
];

export const strategyPlacementTypeOptions = (intl) => [
  {
    text: intl.formatMessage({
      id: "LABEL_CREATIVE_TYPE_ON_SITE",
      defaultMessage: "On-site",
    }),
    value: "onsite",
  },
  {
    text: intl.formatMessage({
      id: "LABEL_CREATIVE_TYPE_OFF_SITE",
      defaultMessage: "Off-site",
    }),
    value: "offsite",
  },
  {
    text: intl.formatMessage({
      id: "CTV",
      defaultMessage: "CTV",
    }),
    value: "ctv",
  },
  {
    text: intl.formatMessage({
      id: "LABEL_CREATIVE_TYPE_INSTORE_DOOH",
      defaultMessage: "In-store DOOH",
    }),
    value: "instore_dooh",
  },
];
