import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Form, Grid, Icon, Input, Message, Modal, Pagination, Segment, Table } from "semantic-ui-react";
import { Config } from "../../../config/api";
import { errorToMessage } from "../../../libs/common_utils";
import { isNil } from "../../../libs/common_utils";
import {
  isNewTabClick,
  onBannerDismiss,
  search_query_becomes_empty,
  should_do_search,
} from "../../../libs/component_utils";
import Pager from "../../../models/pager";
import MarginsService from "../../../services/margins";
import MarginsGridContext from "./context";
import { marginGridReducer, marginsActions } from "./reducers";

const initialState = {
  margins: [],
  pager: new Pager(),
  total_pages: 0,
};

export const MarginsPage = ({ history }) => {
  const intl = useIntl();
  const [state, dispatch] = useReducer(marginGridReducer, initialState);
  const [gridLoading, setGridLoading] = useState(true);
  const showSuccessMessage = !isNil(history.location.state);
  const _isMounted = useRef(false);
  const services = useRef(new Map([["margins", new MarginsService()]]));
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [deleteSuccessDetails, setDeleteSuccessDetails] = useState(null);
  const [serverError, setServerError] = useState(null);

  const query = useRef("");
  const timer = useRef(0);

  /**
   * initial load
   */
  useEffect(() => {
    _isMounted.current = true;
    // load list of items
    getList().then(() => console.log);

    // clear cache
    return () => {
      _isMounted.current = false;
    };
  }, []);

  /**
   * get page
   * @param e
   * @param activePage
   */
  const getPage = (_, { activePage }) => {
    state.pager.setPage(activePage);
    getList(state.pager).then(() => console.log);
  };

  /**
   * get to create page
   */
  const navigateToCreatePage = () => {
    history.push("/admin/margin/create");
  };

  /**
   * Delete the margin and close confirmation modal.
   */
  const handleDeleteConfirm = useCallback(async () => {
    const margins = services.current.get("margins");
    setDeleteModalData(null);
    setGridLoading(true);
    try {
      await margins.delete(deleteModalData.id);
      setDeleteSuccessDetails({
        action: "deleted",
        agency_name: deleteModalData.agency_name,
      });
    } catch (e) {
      setServerError(
        intl.formatMessage(
          {
            id: "ERROR_CANT_DELETE_MARGIN",
            defaultMessage: "Could not delete margin control for {agency_name}: {error}",
          },
          {
            agency_name: deleteModalData.agency_name,
            error: errorToMessage(e),
          },
        ),
      );
      console.error(e);
    } finally {
      await getList(state.pager);
    }
  }, [deleteModalData, intl, state]);

  /**
   * navigate user to edit page
   */
  const navigateToEditPage = useCallback(
    (id) => {
      history.push(
        `/admin/margin/edit/${id}`,
        state.margins.find((margin) => margin.id === id),
      );
    },
    [history, state],
  );

  /**
   * get edit page URL by campaign id
   */
  const getEditPageHref = useCallback((id) => `/admin/margin/edit/${id}`, []);

  /**
   * load margins from API
   * @param {object|null} pager
   * @return {Promise<void>}
   */
  const getList = async (pager = null) => {
    const margins = services.current.get("margins");
    try {
      setGridLoading(true);

      let params = {};
      if (pager) {
        params = Object.assign(params, pager.toJson());
      }

      if (query.current.length) {
        params.agency_name = query.current;
      }

      const response = await margins.list(params);
      /** @namespace response.data **/
      const meta = response.meta;
      meta.page = 1;

      dispatch({
        type: marginsActions.INIT,
        data: response.data,
        pager: meta,
      });
    } catch (e) {
      // ignore error
      console.warn(e);
    } finally {
      if (_isMounted.current) {
        setGridLoading(false);
      }
    }
  };

  /**
   * do a search
   * @param e
   * @param {string} value
   */
  const handleSearch = async (_, { value: searchQuery }) => {
    const prev_query = query.current || "";
    query.current = searchQuery;

    // clear time any time we hit the method
    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (!should_do_search(searchQuery, prev_query)) {
      return;
    }

    state.pager.reset();
    if (search_query_becomes_empty(searchQuery, prev_query)) {
      await getList(state.pager);
      return;
    }

    timer.current = setTimeout(async () => {
      await getList(state.pager);
    }, Config.search_debounce_delay);
  };

  return (
    <Segment
      basic
      style={{ padding: "0" }}
    >
      <MarginsGridContext.Provider
        value={{
          navigateToCreatePage,
          navigateToEditPage,
          getEditPageHref,
          getPage,
          handleSearch,
          setDeleteModalData,
        }}
      >
        {showSuccessMessage && <MarginsSuccessMessage details={history.location.state || {}} />}
        {deleteSuccessDetails && (
          <MarginsSuccessMessage
            details={deleteSuccessDetails || {}}
            onDismiss={() => setDeleteSuccessDetails(null)}
          />
        )}
        {serverError && (
          <Message
            error
            attached
            onDismiss={() => setServerError(null)}
          >
            {serverError}
          </Message>
        )}
        <h1>
          {intl.formatMessage({
            id: "HEADING_MARGINS",
            defaultMessage: "Margin Controls",
          })}
        </h1>
        <MarginsGrid
          items={state.margins}
          loading={gridLoading}
          controls={{ pager: state.pager }}
        />
        <MarginDeleteModal
          open={Boolean(deleteModalData)}
          margin={deleteModalData}
          onConfirm={handleDeleteConfirm}
          onClose={() => setDeleteModalData(null)}
        />
      </MarginsGridContext.Provider>
    </Segment>
  );
};

/**
 * Generate success message
 * @param {function} onDismiss
 * @param {object} details
 * @return {*}
 * @constructor
 */
const MarginsSuccessMessage = ({ details, onDismiss = onBannerDismiss }) => {
  const { formatMessage } = useIntl();

  const message = useMemo(() => {
    const { action, agency_name: name } = details;
    let messageObject = null;

    if (action === "created") {
      messageObject = formatMessage({
        defaultMessage: "Margin Control successfully created",
        id: "MESSAGE_MARGIN_CREATED",
      });
    } else if (action === "updated") {
      messageObject = formatMessage(
        {
          defaultMessage: "Margin Control for {name} updated",
          id: "MESSAGE_MARGIN_UPDATED",
        },
        { name },
      );
    } else if (action === "deleted") {
      messageObject = formatMessage(
        {
          defaultMessage: "Margin Control for {name} deleted",
          id: "MESSAGE_MARGIN_DELETED",
        },
        { name },
      );
    }

    return messageObject;
  }, [details, formatMessage]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on initial render
  useEffect(() => {
    const timer = setTimeout(onBannerDismiss, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return message ? (
    <Message
      attached
      className="page-success-message"
      onDismiss={onDismiss}
      success
    >
      {message}
    </Message>
  ) : null;
};

MarginsSuccessMessage.propTypes = {
  details: PropTypes.object.isRequired,
};

/**
 * Render grid
 * @param {array} items
 * @param {boolean} loading
 * @param {object} controls
 * @return {JSX.Element}
 * @constructor
 */
export const MarginsGrid = ({ items, loading, controls }) => {
  const intl = useIntl();
  const context = useContext(MarginsGridContext);
  const [value, setValue] = useState("");
  const _isMounted = useRef(false);

  /**
   * generate close icon
   * @returns {{onClick: *, name: string}}
   */
  const getCloseIcon = () => {
    return {
      name: "close",
      link: true,
      id: "clear_search_input",
    };
  };

  // set effect to on query change, added listener to resert
  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on "value" change
  useLayoutEffect(() => {
    const el = document.getElementById("clear_search_input");
    if (el && value.length > 0) {
      el.addEventListener("click", clearSearch, false);
    }

    if (_isMounted.current) {
      (async () => {
        await context.handleSearch(null, { value });
      })();
    }

    return () => {
      if (el) {
        el.removeEventListener("click", clearSearch);
      }
    };
  }, [value]);

  // set component is mounted
  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);

  /**
   * set search value and trigger search
   * @param e
   * @param value
   * @return {Promise<void>}
   */
  const handleSearch = async (_, { value }) => {
    await setValue(value);
  };

  /**
   * clear search query
   */
  const clearSearch = () => {
    setValue("");
  };

  return (
    <>
      <Grid className="common_grid">
        <Grid.Row>
          <Grid.Column width={12}>
            <Form
              autoComplete="off"
              noValidate
              size="tiny"
              style={{ marginTop: "15px" }}
            >
              <Form.Group>
                <Form.Field>
                  <label>
                    {intl.formatMessage({
                      id: "LABEL_AGENCY_NAME",
                      defaultMessage: "Agency name",
                    })}
                  </label>
                  <Input
                    onChange={handleSearch}
                    value={value}
                    disabled={loading}
                    icon={value.length ? getCloseIcon() : null}
                    placeholder={intl.formatMessage({
                      id: "HINT_SEARCH_MARGIN_BY_AGENCY",
                      defaultMessage: "Search margin controls by agency name",
                    })}
                    name="agency_name"
                    style={{ width: "250px" }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column
            width={4}
            style={{ marginBottom: "1.6em" }}
            textAlign="right"
            verticalAlign="bottom"
          >
            <Button
              primary
              compact
              className="text__uppercase"
              onClick={() => context.navigateToCreatePage()}
            >
              {intl.formatMessage({
                id: "BTN_CREATE_MARGIN",
                defaultMessage: "Create Margin Control",
              })}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment
        basic
        style={{ padding: "0" }}
        loading={loading}
      >
        <Table className="custom-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {intl.formatMessage({
                  id: "LABEL_AGENCY",
                  defaultMessage: "Agency",
                })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {intl.formatMessage({
                  id: "LABEL_AGENCY_ID",
                  defaultMessage: "Agency ID",
                })}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left">
                {intl.formatMessage({
                  id: "LABEL_ADDITIONAL_MARGIN",
                  defaultMessage: "Additional Margin",
                })}
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="center"
                style={{ width: "70px" }}
              >
                &nbsp;
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.length ? (
              items.map((item) => {
                return (
                  <MarginGridItem
                    key={item.id}
                    {...item}
                  />
                );
              })
            ) : (
              <GridEmptyRow
                filterIsEmpty={!value}
                onAddButtonClick={context.navigateToCreatePage}
              />
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell
                colSpan="12"
                textAlign="right"
              >
                {controls.pager.total_pages > 1 && (
                  <Pagination
                    size="mini"
                    activePage={controls.pager.page}
                    totalPages={controls.pager.total_pages}
                    firstItem={null}
                    lastItem={null}
                    onPageChange={context.getPage}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
    </>
  );
};

/**
 * Generate grid item
 * @param {object} item
 * @return {*}
 * @constructor
 */
const MarginGridItem = (item) => {
  const context = useContext(MarginsGridContext);

  return (
    <Table.Row>
      <Table.Cell className="grid-item-title">{item.agency_name}</Table.Cell>
      <Table.Cell>{item.agency_id}</Table.Cell>
      <Table.Cell>{item.additional_margin_pct.toFixed(4)}%</Table.Cell>
      <Table.Cell
        data-clickable="0"
        textAlign="center"
      >
        <a
          href={context.getEditPageHref(item.id)}
          onClick={(e) => {
            if (isNewTabClick(e)) return;
            e.preventDefault();
            e.stopPropagation();
            context.navigateToEditPage(item.id);
          }}
        >
          <Icon
            name="edit"
            className="control"
          />
        </a>
        <Icon
          name="trash"
          className="control"
          onClick={() => context.setDeleteModalData(item)}
        />
      </Table.Cell>
    </Table.Row>
  );
};

const MarginDeleteModal = ({ open, onConfirm, onClose, margin }) => {
  const intl = useIntl();

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Modal.Header>
        {intl.formatMessage(
          {
            id: "HEADING_DELETE_MARGIN",
            defaultMessage: "Delete Margin Control for {agency_name}?",
          },
          {
            agency_name: margin?.agency_name,
          },
        )}
      </Modal.Header>
      <Modal.Content>
        {intl.formatMessage({
          id: "BODY_DELETE_MARGIN",
          defaultMessage:
            "Deleting Margin Control will not remove additional margin from existing campaigns. Are you sure you want to continue?",
        })}
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={intl.formatMessage({
            id: "BTN_DELETE",
            defaultMessage: "Delete",
          })}
          onClick={onConfirm}
          negative
        />
        <Button
          content={intl.formatMessage({
            id: "BTN_CANCEL",
            defaultMessage: "Cancel",
          })}
          onClick={onClose}
        />
      </Modal.Actions>
    </Modal>
  );
};

const GridEmptyRow = ({ filterIsEmpty, onAddButtonClick }) => {
  const intl = useIntl();

  return (
    <Table.Row>
      <Table.Cell
        colSpan="5"
        textAlign="center"
      >
        {filterIsEmpty ? (
          <>
            {intl.formatMessage({
              id: "EMPTY_MARGINS",
              defaultMessage: "You don’t have any margin controls yet",
            })}
            <br />
            <br />
            <Button
              className="text__uppercase"
              primary
              compact
              onClick={onAddButtonClick}
            >
              {intl.formatMessage({
                id: "BTN_CREATE_MARGIN",
                defaultMessage: "Create Margin Control",
              })}
            </Button>
          </>
        ) : (
          intl.formatMessage({
            id: "EMPTY_SEARCH_RESULTS",
            defaultMessage: "No results found",
          })
        )}
      </Table.Cell>
    </Table.Row>
  );
};

GridEmptyRow.propTypes = {
  filterIsEmpty: PropTypes.bool.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
};
