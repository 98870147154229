export default {
  performance: {
    title: "Performance Report",
    messageId: "REPORT_PERFORMANCE",
    type: "performance",
    coverSrc: require("../../../../assets/report-icons/performance.png"),
    defaultColumns: [
      "agency_name",
      "advertiser_name",
      "campaign_name",
      "strategy_name",
      "campaign_currency_code",
      "clicks",
      "ctr",
      "impressions",
      "total_conversions",
      "total_revenue",
      "total_spend",
      "total_spend_cpa",
      "total_spend_cpc",
      "total_spend_cpm",
      "post_click_conversions",
      "post_view_conversions",
      "start_date",
      "end_date",
    ],
    allowedColumns: [
      "advertiser_id",
      "advertiser_name",
      "agency_id",
      "agency_name",
      "attribution_group",
      "campaign_budget",
      "campaign_currency_code",
      "campaign_end_date",
      "campaign_end_date_tz",
      "campaign_goal_type",
      "campaign_goal_value",
      "campaign_id",
      "campaign_initial_start_date",
      "campaign_initial_start_date_tz",
      "campaign_name",
      "campaign_start_date",
      "campaign_start_date_tz",
      "campaign_timezone",
      "campaign_timezone_code",
      "client_product_estimate",
      "concept_id",
      "concept_name",
      "creative_id",
      "creative_name",
      "creative_size",
      "deal_external_id",
      "deal_group_id",
      "deal_group_name",
      "deal_id",
      "deal_name",
      "deal_price_type",
      "exchange_id",
      "exchange_name",
      "io_number",
      "organization_id",
      "organization_name",
      "prisma_campaign_id",
      "strategy_budget",
      "strategy_channel",
      "strategy_end_date",
      "strategy_goal_type",
      "strategy_goal_value",
      "strategy_id",
      "strategy_name",
      "strategy_start_date",
      "strategy_supply_type",
      "strategy_type",
      "tpas_placement_id",
      "tpas_placement_name",
      "3pa_conversions",
      "3pa_media_cost_cpa",
      "3pa_media_cost_cpa_usd",
      "3pa_media_cost_roi",
      "3pa_rr_per_1k_imps",
      "3pa_total_ad_cost_cpa",
      "3pa_total_ad_cost_cpa_usd",
      "3pa_total_ad_cost_roi",
      "3pa_total_aov",
      "3pa_total_aov_usd",
      "3pa_total_revenue",
      "3pa_total_revenue_usd",
      "3pa_total_spend_cpa",
      "3pa_total_spend_cpa_usd",
      "3pa_total_spend_roi",
      "adserving_cost",
      "adserving_cost_usd",
      "adverification_cost",
      "adverification_cost_usd",
      "audience_verification_cost",
      "audience_verification_cost_usd",
      "billed_spend",
      "billed_spend_usd",
      "clicks",
      "client_exchange_cost",
      "client_margin_share",
      "client_margin_share_usd",
      "contextual_cost",
      "contextual_cost_usd",
      "ctc",
      "ctr",
      "dynamic_creative_cost",
      "dynamic_creative_cost_usd",
      "facebook_actions",
      "facebook_app_installs",
      "facebook_likes",
      "facebook_page_engagement",
      "facebook_post_comments",
      "facebook_post_engagement",
      "facebook_post_reactions",
      "facebook_post_shares",
      "impressions",
      "in_view",
      "in_view_100_percent",
      "in_view_15_seconds",
      "in_view_15_seconds_rate",
      "in_view_5_seconds",
      "in_view_5_seconds_rate",
      "managed_service_fee",
      "managed_service_fee_usd",
      "margin",
      "margin_usd",
      "measurability_rate",
      "measurable",
      "measurement_cost",
      "measurement_cost_usd",
      "media_cost",
      "media_cost_cpa",
      "media_cost_cpa_usd",
      "media_cost_cpc",
      "media_cost_cpc_usd",
      "media_cost_cpcv",
      "media_cost_cpcv_usd",
      "media_cost_cpm",
      "media_cost_cpm_usd",
      "media_cost_pc_cpa",
      "media_cost_pc_cpa_usd",
      "media_cost_pv_cpa",
      "media_cost_pv_cpa_usd",
      "media_cost_roi",
      "media_cost_usd",
      "media_cost_vcpm",
      "media_cost_vcpm_usd",
      "mm_data_cost",
      "mm_data_cost_usd",
      "mm_margin_share",
      "mm_margin_share_usd",
      "mm_total_fee",
      "mm_total_fee_usd",
      "optimization_fee",
      "optimization_fee_usd",
      "platform_access_fee",
      "platform_access_fee_usd",
      "pmp_no_opto_fee",
      "pmp_no_opto_fee_usd",
      "pmp_opto_fee",
      "pmp_opto_fee_usd",
      "post_click_aov",
      "post_click_aov_usd",
      "post_click_conversions",
      "post_click_media_cost_roi",
      "post_click_revenue",
      "post_click_revenue_usd",
      "post_click_total_ad_cost_roi",
      "post_click_total_spend_roi",
      "post_view_aov",
      "post_view_aov_usd",
      "post_view_conversions",
      "post_view_conversions_raw",
      "post_view_media_cost_roi",
      "post_view_revenue",
      "post_view_revenue_raw",
      "post_view_revenue_raw_usd",
      "post_view_revenue_usd",
      "post_view_total_ad_cost_roi",
      "post_view_total_spend_roi",
      "privacy_compliance_cost",
      "privacy_compliance_cost_usd",
      "revenue",
      "revenue_usd",
      "rr_per_1k_imps",
      "source_media_enablement_fee",
      "source_media_enablement_fee_usd",
      "source_platform_access_fee",
      "source_platform_access_fee_usd",
      "total_ad_cost",
      "total_ad_cost_cpa",
      "total_ad_cost_cpa_usd",
      "total_ad_cost_cpc",
      "total_ad_cost_cpc_usd",
      "total_ad_cost_cpcv",
      "total_ad_cost_cpcv_usd",
      "total_ad_cost_cpm",
      "total_ad_cost_cpm_usd",
      "total_ad_cost_pc_cpa",
      "total_ad_cost_pc_cpa_usd",
      "total_ad_cost_pv_cpa",
      "total_ad_cost_pv_cpa_usd",
      "total_ad_cost_roi",
      "total_ad_cost_usd",
      "total_ad_cost_vcpm",
      "total_ad_cost_vcpm_usd",
      "total_aov",
      "total_aov_usd",
      "total_conversions",
      "total_revenue",
      "total_revenue_usd",
      "total_spend",
      "total_spend_cpa",
      "total_spend_cpa_usd",
      "total_spend_cpc",
      "total_spend_cpc_usd",
      "total_spend_cpcv",
      "total_spend_cpcv_usd",
      "total_spend_cpm",
      "total_spend_cpm_usd",
      "total_spend_pc_cpa",
      "total_spend_pc_cpa_usd",
      "total_spend_pv_cpa",
      "total_spend_pv_cpa_usd",
      "total_spend_roi",
      "total_spend_usd",
      "total_spend_vcpm",
      "total_spend_vcpm_usd",
      "udi_data_cost",
      "udi_data_cost_usd",
      "uniques",
      "video_close",
      "video_collapse",
      "video_companion_clicks",
      "video_companion_ctr",
      "video_companion_impressions",
      "video_complete",
      "video_complete_rate",
      "video_complete_rate_impression_based",
      "video_engaged_impressions",
      "video_engaged_rate",
      "video_expand",
      "video_first_quartile",
      "video_first_quartile_rate",
      "video_first_quartile_rate_impression_based",
      "video_fullscreen",
      "video_midpoint",
      "video_midpoint_rate",
      "video_midpoint_rate_impression_based",
      "video_mute",
      "video_pause",
      "video_play_rate",
      "video_resume",
      "video_rewind",
      "video_skip",
      "video_skippable_impressions",
      "video_skipped_impressions",
      "video_skipped_rate",
      "video_start",
      "video_third_quartile",
      "video_third_quartile_rate",
      "video_third_quartile_rate_impression_based",
      "video_unmute",
      "viewability_cost",
      "viewability_cost_usd",
      "viewability_rate",
      "viewability_rate_100_percent",
      "start_date",
      "end_date",
    ],
  },
  "app-transparency": {
    title: "App Transparency Report",
    messageId: "REPORT_APP_TRANSPARENCY",
    type: "app_transparency",
    coverSrc: require("../../../../assets/report-icons/app-transparency.png"),
    defaultColumns: [
      "agency_name",
      "advertiser_name",
      "campaign_name",
      "strategy_name",
      "app_id",
      "app_name",
      "clicks",
      "ctc",
      "ctr",
      "impressions",
      "total_conversions",
      "total_spend_cpa",
      "total_spend_cpc",
      "total_spend_cpm",
      "total_spend",
      "post_click_conversions",
      "post_view_conversions",
      "start_date",
      "end_date",
    ],
    allowedColumns: [
      "advertiser_id",
      "advertiser_name",
      "agency_id",
      "agency_name",
      "app_id",
      "app_name",
      "attribution_group",
      "campaign_budget",
      "campaign_currency_code",
      "campaign_end_date",
      "campaign_end_date_tz",
      "campaign_goal_type",
      "campaign_goal_value",
      "campaign_id",
      "campaign_initial_start_date",
      "campaign_initial_start_date_tz",
      "campaign_name",
      "campaign_start_date",
      "campaign_start_date_tz",
      "campaign_timezone",
      "campaign_timezone_code",
      "exchange_id",
      "exchange_name",
      "organization_id",
      "organization_name",
      "strategy_budget",
      "strategy_channel",
      "strategy_end_date",
      "strategy_goal_type",
      "strategy_goal_value",
      "strategy_id",
      "strategy_name",
      "strategy_start_date",
      "strategy_supply_type",
      "strategy_type",
      "clicks",
      "ctc",
      "ctr",
      "impressions",
      "media_cost",
      "media_cost_cpa",
      "media_cost_cpc",
      "media_cost_cpcv",
      "media_cost_cpm",
      "media_cost_pc_cpa",
      "media_cost_pv_cpa",
      "post_click_conversions",
      "post_view_conversions",
      "rr_per_1k_imps",
      "total_ad_cost",
      "total_ad_cost_cpa",
      "total_ad_cost_cpc",
      "total_ad_cost_cpcv",
      "total_ad_cost_cpm",
      "total_ad_cost_pc_cpa",
      "total_ad_cost_pv_cpa",
      "total_conversions",
      "total_spend",
      "total_spend_cpa",
      "total_spend_cpc",
      "total_spend_cpcv",
      "total_spend_cpm",
      "total_spend_pc_cpa",
      "total_spend_pv_cpa",
      "video_close",
      "video_collapse",
      "video_companion_clicks",
      "video_companion_ctr",
      "video_companion_impressions",
      "video_complete",
      "video_complete_rate",
      "video_complete_rate_impression_based",
      "video_engaged_impressions",
      "video_engaged_rate",
      "video_expand",
      "video_first_quartile",
      "video_first_quartile_rate",
      "video_first_quartile_rate_impression_based",
      "video_fullscreen",
      "video_midpoint",
      "video_midpoint_rate",
      "video_midpoint_rate_impression_based",
      "video_mute",
      "video_pause",
      "video_play_rate",
      "video_resume",
      "video_rewind",
      "video_skip",
      "video_skippable_impressions",
      "video_skipped_impressions",
      "video_skipped_rate",
      "video_start",
      "video_third_quartile",
      "video_third_quartile_rate",
      "video_third_quartile_rate_impression_based",
      "video_unmute",
      "start_date",
      "end_date",
    ],
  },
  "site-transparency": {
    title: "Site Transparency Report",
    messageId: "REPORT_SITE_TRANSPARENCY",
    type: "site_transparency",
    coverSrc: require("../../../../assets/report-icons/site-transparency.png"),
    defaultColumns: [
      "agency_name",
      "advertiser_name",
      "campaign_name",
      "strategy_name",
      "site_domain",
      "clicks",
      "ctc",
      "ctr",
      "impressions",
      "total_conversions",
      "total_spend_cpa",
      "total_spend_cpc",
      "total_spend_cpm",
      "total_spend",
      "post_click_conversions",
      "post_view_conversions",
      "start_date",
      "end_date",
    ],
    allowedColumns: [
      "advertiser_id",
      "advertiser_name",
      "agency_id",
      "agency_name",
      "attribution_group",
      "campaign_budget",
      "campaign_currency_code",
      "campaign_end_date",
      "campaign_end_date_tz",
      "campaign_goal_type",
      "campaign_goal_value",
      "campaign_id",
      "campaign_initial_start_date",
      "campaign_initial_start_date_tz",
      "campaign_name",
      "campaign_start_date",
      "campaign_start_date_tz",
      "campaign_timezone",
      "campaign_timezone_code",
      "exchange_id",
      "exchange_name",
      "organization_id",
      "organization_name",
      "site_domain",
      "strategy_budget",
      "strategy_channel",
      "strategy_end_date",
      "strategy_goal_type",
      "strategy_goal_value",
      "strategy_id",
      "strategy_name",
      "strategy_start_date",
      "strategy_supply_type",
      "strategy_type",
      "clicks",
      "ctc",
      "ctr",
      "impressions",
      "media_cost",
      "media_cost_cpa",
      "media_cost_cpc",
      "media_cost_cpcv",
      "media_cost_cpm",
      "media_cost_pc_cpa",
      "media_cost_pv_cpa",
      "media_cost_roi",
      "post_click_aov",
      "post_click_conversions",
      "post_click_media_cost_roi",
      "post_click_revenue",
      "post_click_total_ad_cost_roi",
      "post_click_total_spend_roi",
      "post_view_aov",
      "post_view_conversions",
      "post_view_conversions_raw",
      "post_view_media_cost_roi",
      "post_view_revenue",
      "post_view_revenue_raw",
      "post_view_total_ad_cost_roi",
      "post_view_total_spend_roi",
      "revenue_raw",
      "rr_per_1k_imps",
      "total_ad_cost",
      "total_ad_cost_cpa",
      "total_ad_cost_cpc",
      "total_ad_cost_cpcv",
      "total_ad_cost_cpm",
      "total_ad_cost_pc_cpa",
      "total_ad_cost_pv_cpa",
      "total_ad_cost_roi",
      "total_aov",
      "total_conversions",
      "total_revenue",
      "total_spend",
      "total_spend_cpa",
      "total_spend_cpc",
      "total_spend_cpcv",
      "total_spend_cpm",
      "total_spend_pc_cpa",
      "total_spend_pv_cpa",
      "total_spend_roi",
      "video_close",
      "video_collapse",
      "video_companion_clicks",
      "video_companion_ctr",
      "video_companion_impressions",
      "video_complete",
      "video_complete_rate",
      "video_complete_rate_impression_based",
      "video_engaged_impressions",
      "video_engaged_rate",
      "video_expand",
      "video_first_quartile",
      "video_first_quartile_rate",
      "video_first_quartile_rate_impression_based",
      "video_fullscreen",
      "video_midpoint",
      "video_midpoint_rate",
      "video_midpoint_rate_impression_based",
      "video_mute",
      "video_pause",
      "video_play_rate",
      "video_resume",
      "video_rewind",
      "video_skip",
      "video_skippable_impressions",
      "video_skipped_impressions",
      "video_skipped_rate",
      "video_start",
      "video_third_quartile",
      "video_third_quartile_rate",
      "video_third_quartile_rate_impression_based",
      "video_unmute",
      "start_date",
      "end_date",
    ],
  },
  geo: {
    title: "Geo Performance Report",
    messageId: "REPORT_GEO_PERFORMANCE",
    type: "geo",
    coverSrc: require("../../../../assets/report-icons/geo.png"),
    defaultColumns: [
      "agency_name",
      "advertiser_name",
      "campaign_name",
      "strategy_name",
      "country_name",
      "clicks",
      "ctc",
      "ctr",
      "impressions",
      "total_conversions",
      "total_spend_cpa",
      "total_spend_cpc",
      "total_spend_cpm",
      "total_spend",
      "post_click_conversions",
      "post_view_conversions",
      "start_date",
      "end_date",
    ],
    allowedColumns: [
      "advertiser_id",
      "advertiser_name",
      "agency_id",
      "agency_name",
      "campaign_budget",
      "campaign_currency_code",
      "campaign_end_date",
      "campaign_end_date_tz",
      "campaign_goal_type",
      "campaign_goal_value",
      "campaign_id",
      "campaign_initial_start_date",
      "campaign_initial_start_date_tz",
      "campaign_name",
      "campaign_start_date",
      "campaign_start_date_tz",
      "campaign_timezone",
      "campaign_timezone_code",
      "country_code",
      "country_name",
      "exchange_id",
      "exchange_name",
      "metro_name",
      "organization_id",
      "organization_name",
      "region_code",
      "region_name",
      "strategy_budget",
      "strategy_channel",
      "strategy_end_date",
      "strategy_goal_type",
      "strategy_goal_value",
      "strategy_id",
      "strategy_name",
      "strategy_start_date",
      "strategy_supply_type",
      "strategy_type",
      "clicks",
      "ctc",
      "ctr",
      "impressions",
      "media_cost",
      "media_cost_cpa",
      "media_cost_cpc",
      "media_cost_cpcv",
      "media_cost_cpm",
      "media_cost_pc_cpa",
      "media_cost_pv_cpa",
      "post_click_conversions",
      "post_view_conversions",
      "rr_per_1k_imps",
      "total_ad_cost",
      "total_ad_cost_cpa",
      "total_ad_cost_cpc",
      "total_ad_cost_cpcv",
      "total_ad_cost_cpm",
      "total_ad_cost_pc_cpa",
      "total_ad_cost_pv_cpa",
      "total_conversions",
      "total_spend",
      "total_spend_cpa",
      "total_spend_cpc",
      "total_spend_cpcv",
      "total_spend_cpm",
      "total_spend_pc_cpa",
      "total_spend_pv_cpa",
      "video_close",
      "video_collapse",
      "video_companion_clicks",
      "video_companion_ctr",
      "video_companion_impressions",
      "video_complete",
      "video_complete_rate",
      "video_complete_rate_impression_based",
      "video_engaged_impressions",
      "video_engaged_rate",
      "video_expand",
      "video_first_quartile",
      "video_first_quartile_rate",
      "video_first_quartile_rate_impression_based",
      "video_fullscreen",
      "video_midpoint",
      "video_midpoint_rate",
      "video_midpoint_rate_impression_based",
      "video_mute",
      "video_pause",
      "video_play_rate",
      "video_resume",
      "video_rewind",
      "video_skip",
      "video_skippable_impressions",
      "video_skipped_impressions",
      "video_skipped_rate",
      "video_start",
      "video_third_quartile",
      "video_third_quartile_rate",
      "video_third_quartile_rate_impression_based",
      "video_unmute",
      "city_code",
      "city_name",
      "start_date",
      "end_date",
    ],
  },
};
