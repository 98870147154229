export default {
  clicks: {
    id: "clicks",
    name: "Clicks",
    type: "count",
    kind: "metric",
    messageId: "LABEL_CLICKS",
  },
  client_margin_share: {
    id: "client_margin_share",
    name: "Client Margin Share",
    type: "money",
    kind: "metric",
    messageId: "LABEL_CLIENT_MARGIN_SHARE",
  },
  client_margin_share_usd: {
    id: "client_margin_share_usd",
    name: "(USD) Client Margin Share",
    type: "money-usd",
    kind: "metric",
    messageId: "LABEL_CLIENT_MARGIN_SHARE_USD",
  },
  platform_access_fee: {
    id: "platform_access_fee",
    name: "Platform Access Fee",
    type: "money",
    kind: "metric",
    messageId: "LABEL_PLATFORM_ACCESS_FEE",
  },
  platform_access_fee_usd: {
    id: "platform_access_fee_usd",
    name: "(USD) Platform Access Fee",
    type: "money-usd",
    kind: "metric",
    messageId: "LABEL_PLATFORM_ACCESS_FEE_USD",
  },
  ctc: {
    id: "ctc",
    name: "CTC",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_CTC",
  },
  ctr: {
    id: "ctr",
    name: "CTR",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_CTR",
  },
  impressions: {
    id: "impressions",
    name: "Impressions",
    type: "count",
    kind: "metric",
    messageId: "LABEL_IMPRESSIONS",
  },
  media_cost: {
    id: "media_cost",
    name: "Media Cost",
    type: "money",
    kind: "metric",
    messageId: "LABEL_MEDIA_COST",
  },
  media_cost_cpa: {
    id: "media_cost_cpa",
    name: "Media Cost eCPA",
    type: "money",
    kind: "metric",
    messageId: "LABEL_MEDIA_COST_CPA",
  },
  media_cost_cpc: {
    id: "media_cost_cpc",
    name: "Media Cost eCPC",
    type: "money",
    kind: "metric",
    messageId: "LABEL_MEDIA_COST_CPC",
  },
  media_cost_cpcv: {
    id: "media_cost_cpcv",
    name: "Media Cost eCPCV",
    type: "money",
    kind: "metric",
    messageId: "LABEL_MEDIA_COST_CPCV",
  },
  media_cost_cpm: {
    id: "media_cost_cpm",
    name: "Media Cost eCPM",
    type: "money",
    kind: "metric",
    messageId: "LABEL_MEDIA_COST_CPM",
  },
  media_cost_pc_cpa: {
    id: "media_cost_pc_cpa",
    name: "Media Cost PC CPA",
    type: "money",
    kind: "metric",
    messageId: "LABEL_MEDIA_COST_PC_CPA",
  },
  media_cost_pv_cpa: {
    id: "media_cost_pv_cpa",
    name: "Media Cost PV CPA",
    type: "money",
    kind: "metric",
    messageId: "LABEL_MEDIA_COST_PV_CPA",
  },
  media_cost_roi: {
    id: "media_cost_roi",
    name: "Media Cost ROI",
    type: "ratio",
    kind: "metric",
    messageId: "LABEL_MEDIA_COST_ROI",
  },
  post_click_aov: {
    id: "post_click_aov",
    name: "Post-Click AOV",
    type: "money",
    kind: "metric",
    messageId: "LABEL_POST_CLICK_AOV",
  },
  post_click_conversions: {
    id: "post_click_conversions",
    name: "Post-Click Conversions",
    type: "count",
    kind: "metric",
    messageId: "LABEL_POST_CLICK_CONVERSIONS",
  },
  post_click_media_cost_roi: {
    id: "post_click_media_cost_roi",
    name: "Post-Click Media Cost ROI",
    type: "ratio",
    kind: "metric",
    messageId: "LABEL_POST_CLICK_MEDIA_COST_ROI",
  },
  post_click_revenue: {
    id: "post_click_revenue",
    name: "Post-Click Revenue",
    type: "money",
    kind: "metric",
    messageId: "LABEL_POST_CLICK_REVENUE",
  },
  post_click_total_ad_cost_roi: {
    id: "post_click_total_ad_cost_roi",
    name: "Post-Click Total Ad Cost ROI",
    type: "ratio",
    kind: "metric",
    messageId: "LABEL_POST_CLICK_TOTAL_AD_COST_ROI",
  },
  post_click_total_spend_roi: {
    id: "post_click_total_spend_roi",
    name: "Post-Click Total Spend ROI",
    type: "ratio",
    kind: "metric",
    messageId: "LABEL_POST_CLICK_TOTAL_SPEND_ROI",
  },
  post_view_aov: {
    id: "post_view_aov",
    name: "Post-View AOV",
    type: "money",
    kind: "metric",
    messageId: "LABEL_POST_VIEW_AOV",
  },
  post_view_conversions: {
    id: "post_view_conversions",
    name: "Post-View Conversions",
    type: "float",
    kind: "metric",
    messageId: "LABEL_POST_VIEW_CONVERSIONS",
  },
  post_view_conversions_raw: {
    id: "post_view_conversions_raw",
    name: "Post-View Conversions (Raw)",
    type: "count",
    kind: "metric",
    messageId: "LABEL_POST_VIEW_CONVERSIONS_RAW",
  },
  post_view_media_cost_roi: {
    id: "post_view_media_cost_roi",
    name: "Post-View Media Cost ROI",
    type: "ratio",
    kind: "metric",
    messageId: "LABEL_POST_VIEW_MEDIA_COST_ROI",
  },
  post_view_revenue: {
    id: "post_view_revenue",
    name: "Post-View Revenue",
    type: "money",
    kind: "metric",
    messageId: "LABEL_POST_VIEW_REVENUE",
  },
  post_view_revenue_raw: {
    id: "post_view_revenue_raw",
    name: "Post-View Revenue (Raw)",
    type: "money",
    kind: "metric",
    messageId: "LABEL_POST_VIEW_REVENUE_RAW",
  },
  post_view_total_ad_cost_roi: {
    id: "post_view_total_ad_cost_roi",
    name: "Post-View Total Ad Cost ROI",
    type: "ratio",
    kind: "metric",
    messageId: "LABEL_POST_VIEW_TOTAL_AD_COST_ROI",
  },
  post_view_total_spend_roi: {
    id: "post_view_total_spend_roi",
    name: "Post-View Total Spend ROI",
    type: "ratio",
    kind: "metric",
    messageId: "LABEL_POST_VIEW_TOTAL_SPEND_ROI",
  },
  revenue_raw: {
    id: "revenue_raw",
    name: "Revenue (Raw)",
    type: "money",
    kind: "metric",
    messageId: "LABEL_REVENUE_RAW",
  },
  rr_per_1k_imps: {
    id: "rr_per_1k_imps",
    name: "Response Rate/1K Imps",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_RR_PER_1K_IMPS",
  },
  total_ad_cost: {
    id: "total_ad_cost",
    name: "Total Ad Cost",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_AD_COST",
  },
  total_ad_cost_cpa: {
    id: "total_ad_cost_cpa",
    name: "Total Ad Cost eCPA",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_AD_COST_CPA",
  },
  total_ad_cost_cpc: {
    id: "total_ad_cost_cpc",
    name: "Total Ad Cost eCPC",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_AD_COST_CPC",
  },
  total_ad_cost_cpcv: {
    id: "total_ad_cost_cpcv",
    name: "Total Ad Cost eCPCV",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_AD_COST_CPCV",
  },
  total_ad_cost_cpm: {
    id: "total_ad_cost_cpm",
    name: "Total Ad Cost eCPM",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_AD_COST_CPM",
  },
  total_ad_cost_pc_cpa: {
    id: "total_ad_cost_pc_cpa",
    name: "Total Ad Cost PC CPA",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_AD_COST_PC_CPA",
  },
  total_ad_cost_pv_cpa: {
    id: "total_ad_cost_pv_cpa",
    name: "Total Ad Cost PV CPA",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_AD_COST_PV_CPA",
  },
  total_ad_cost_roi: {
    id: "total_ad_cost_roi",
    name: "Total Ad Cost ROI",
    type: "ratio",
    kind: "metric",
    messageId: "LABEL_TOTAL_AD_COST_ROI",
  },
  total_aov: {
    id: "total_aov",
    name: "Total AOV",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_AOV",
  },
  total_conversions: {
    id: "total_conversions",
    name: "Total Conversions",
    type: "float",
    kind: "metric",
    messageId: "LABEL_TOTAL_CONVERSIONS",
  },
  total_revenue: {
    id: "total_revenue",
    name: "Total Revenue",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_REVENUE",
  },
  total_spend: {
    id: "total_spend",
    name: "Total Spend",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_SPEND",
  },
  total_spend_cpa: {
    id: "total_spend_cpa",
    name: "Total Spend eCPA",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_SPEND_CPA",
  },
  total_spend_cpc: {
    id: "total_spend_cpc",
    name: "Total Spend eCPC",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_SPEND_CPC",
  },
  total_spend_cpcv: {
    id: "total_spend_cpcv",
    name: "Total Spend eCPCV",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_SPEND_CPCV",
  },
  total_spend_cpm: {
    id: "total_spend_cpm",
    name: "Total Spend eCPM",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_SPEND_CPM",
  },
  total_spend_pc_cpa: {
    id: "total_spend_pc_cpa",
    name: "Total Spend PC CPA",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_SPEND_PC_CPA",
  },
  total_spend_pv_cpa: {
    id: "total_spend_pv_cpa",
    name: "Total Spend PV CPA",
    type: "money",
    kind: "metric",
    messageId: "LABEL_TOTAL_SPEND_PV_CPA",
  },
  total_spend_roi: {
    id: "total_spend_roi",
    name: "Total Spend ROI",
    type: "ratio",
    kind: "metric",
    messageId: "LABEL_TOTAL_SPEND_ROI",
  },
  video_close: {
    id: "video_close",
    name: "Close",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_CLOSE",
  },
  video_collapse: {
    id: "video_collapse",
    name: "Collapse",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_COLLAPSE",
  },
  video_companion_clicks: {
    id: "video_companion_clicks",
    name: "Companion Clicks",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_COMPANION_CLICKS",
  },
  video_companion_ctr: {
    id: "video_companion_ctr",
    name: "Companion CTR",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_VIDEO_COMPANION_CTR",
  },
  video_companion_impressions: {
    id: "video_companion_impressions",
    name: "Companion Impressions",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_COMPANION_IMPRESSIONS",
  },
  video_complete: {
    id: "video_complete",
    name: "100% Completed Views",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_COMPLETE",
  },
  video_complete_rate: {
    id: "video_complete_rate",
    name: "100% Completed Rate",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_VIDEO_COMPLETE_RATE",
  },
  video_complete_rate_impression_based: {
    id: "video_complete_rate_impression_based",
    name: "VCR (Impression Based)",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_VIDEO_COMPLETE_RATE_IMPRESSION_BASED",
  },
  video_engaged_impressions: {
    id: "video_engaged_impressions",
    name: "Engaged Impressions",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_ENGAGED_IMPRESSIONS",
  },
  video_engaged_rate: {
    id: "video_engaged_rate",
    name: "Engaged Rate",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_VIDEO_ENGAGED_RATE",
  },
  video_expand: {
    id: "video_expand",
    name: "Expand",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_EXPAND",
  },
  video_first_quartile: {
    id: "video_first_quartile",
    name: "25% Completed Views",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_FIRST_QUARTILE",
  },
  video_first_quartile_rate: {
    id: "video_first_quartile_rate",
    name: "25% Completed Rate",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_VIDEO_FIRST_QUARTILE_RATE",
  },
  video_first_quartile_rate_impression_based: {
    id: "video_first_quartile_rate_impression_based",
    name: "25% Completed Rate - Impressions Based",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_VIDEO_FIRST_QUARTILE_RATE_IMPRESSION_BASED",
  },
  video_fullscreen: {
    id: "video_fullscreen",
    name: "Fullscreen",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_FULLSCREEN",
  },
  video_midpoint: {
    id: "video_midpoint",
    name: "50% Completed Views",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_MIDPOINT",
  },
  video_midpoint_rate: {
    id: "video_midpoint_rate",
    name: "50% Completed Rate",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_VIDEO_MIDPOINT_RATE",
  },
  video_midpoint_rate_impression_based: {
    id: "video_midpoint_rate_impression_based",
    name: "50% Completed Rate - Impressions Based",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_VIDEO_MIDPOINT_RATE_IMPRESSION_BASED",
  },
  video_mute: {
    id: "video_mute",
    name: "Mute",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_MUTE",
  },
  video_pause: {
    id: "video_pause",
    name: "Pause",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_PAUSE",
  },
  video_play_rate: {
    id: "video_play_rate",
    name: "Play Rate",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_VIDEO_PLAY_RATE",
  },
  video_resume: {
    id: "video_resume",
    name: "Resume",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_RESUME",
  },
  video_rewind: {
    id: "video_rewind",
    name: "Rewind",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_REWIND",
  },
  video_skip: {
    id: "video_skip",
    name: "Skip",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_SKIP",
  },
  video_skippable_impressions: {
    id: "video_skippable_impressions",
    name: "Skippable Impressions",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_SKIPPABLE_IMPRESSIONS",
  },
  video_skipped_impressions: {
    id: "video_skipped_impressions",
    name: "Skipped Impressions",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_SKIPPED_IMPRESSIONS",
  },
  video_skipped_rate: {
    id: "video_skipped_rate",
    name: "Skipped Rate",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_VIDEO_SKIPPED_RATE",
  },
  video_start: {
    id: "video_start",
    name: "Start",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_START",
  },
  video_third_quartile: {
    id: "video_third_quartile",
    name: "75% Completed Views",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_THIRD_QUARTILE",
  },
  video_third_quartile_rate: {
    id: "video_third_quartile_rate",
    name: "75% Completed Rate",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_VIDEO_THIRD_QUARTILE_RATE",
  },
  video_third_quartile_rate_impression_based: {
    id: "video_third_quartile_rate_impression_based",
    name: "75% Completed Rate - Impressions Based",
    type: "percent",
    kind: "metric",
    messageId: "LABEL_VIDEO_THIRD_QUARTILE_RATE_IMPRESSION_BASED",
  },
  video_unmute: {
    id: "video_unmute",
    name: "Unmute",
    type: "count",
    kind: "metric",
    messageId: "LABEL_VIDEO_UNMUTE",
  },
};
