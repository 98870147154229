import PropTypes from "prop-types";
import { useState } from "react";
import { FormField, Grid, GridColumn, Radio } from "semantic-ui-react";

const styles = {
  columnVertical: {
    padding: 0,
    margin: "0 0 0 15px",
  },

  columnHorizontal: {
    width: "auto",
  },

  formField: {
    display: "flex",
  },
};

const SuiRadioButtonGroup = ({ disabled, isVertical, required, groupLabel, name, options, value }) => {
  const defaultRadioOption = value ?? options?.find((radio) => radio.default)?.value;

  const [selectedRadio, setSelectedRadio] = useState(defaultRadioOption);

  const handleChange = (e, radio) => {
    setSelectedRadio(radio.value);
    if (radio.onChange) {
      radio.name = name;
      radio.onChange(e, radio);
    }
  };

  if (!options?.length) return null;

  return (
    <FormField
      inline
      required={required}
      style={styles.formField}
    >
      {groupLabel && <label>{groupLabel}</label>}

      <Grid
        columns={isVertical ? 1 : null}
        stackable
      >
        {options.map((radio) => (
          <GridColumn
            key={`column-${name}-${radio.value}`}
            style={isVertical ? styles.columnVertical : styles.columnHorizontal}
          >
            <Radio
              checked={selectedRadio === radio.value}
              data-testid={`${name}-input-${radio.value}`}
              disabled={disabled}
              label={radio.label}
              name={name}
              onChange={(e) => handleChange(e, radio)}
              value={radio.value}
            />
          </GridColumn>
        ))}
      </Grid>
    </FormField>
  );
};

SuiRadioButtonGroup.propTypes = {
  groupLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  isVertical: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      default: PropTypes.bool,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default SuiRadioButtonGroup;
