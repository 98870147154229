export default class StrategyCreativesSelected {
  constructor(strategyId, creativeIds) {
    this.strategy_id = strategyId || 0;
    this.creative_ids = creativeIds || [];
  }

  /**
   * Parse data from API into a model instance
   * @param {number} strategy_id
   * @param {object} json
   */
  static fromJson(strategyId, { creative_ids }) {
    return new StrategyCreativesSelected(strategyId, creative_ids);
  }

  /**
   * Parse data from API into a model instance
   * @param {object} json
   */
  static fromResponse(strategyId, { creative_ids }) {
    return new StrategyCreativesSelected(
      strategyId,
      creative_ids.map((node) => {
        return node.id;
      }),
    );
  }

  /**
   * Check if model instance is valid
   * @return {boolean}
   */
  isValid() {
    return this.creative_ids.length;
  }

  /**
   * Convert model instance into API format
   * @return {object}
   */
  toJson() {
    return {
      // biome-ignore lint/style/useNamingConvention: <server params>
      creative_ids: this.creative_ids,
    };
  }
}
