import { useIntl } from "react-intl";
import { Divider, Grid, Segment } from "semantic-ui-react";
import CreativesTabsMenuAdmin from "../../form/common/creativesTabsMenuAdmin";

export const AdminCreativesHeader = () => {
  const intl = useIntl();

  return (
    <Segment
      basic
      style={{ padding: "0" }}
    >
      <h1>
        {intl.formatMessage({
          id: "HEADING_CREATIVES",
          defaultMessage: "Creatives",
        })}
      </h1>

      <Divider hidden />

      <Grid className="common_grid">
        <Grid.Row>
          <Grid.Column>
            <CreativesTabsMenuAdmin />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default AdminCreativesHeader;
