import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { Button, Icon, Image as Img, Label } from "semantic-ui-react";
import brandImg from "../../../../../assets/creative/brand_logo.png";
import headerImg from "../../../../../assets/creative/mobile_header.png";
import backdropImg from "../../../../../assets/creative/video player backdrop.png";

/**
 * banner component
 * @param brand_name
 * @param ad_title
 * @param logo_url
 * @param button_color
 * @param button_text
 * @param deep_link
 * @returns {JSX.Element}
 * @constructor
 */
const BannerCompanionComponent = React.memo(
  ({ brand_name, ad_title, logo_url, button_color, button_text, deep_link }) => {
    const intl = useIntl();
    const url = deep_link ? new URL(deep_link).hostname : "yourwebsite.com";

    return (
      <>
        <strong>
          {intl.formatMessage({
            defaultMessage: "Companion Ad Preview",
            id: "LABEL_COMPANION_AD_PREVIEW",
          })}
        </strong>
        <div className="companion_ad_preview">
          <div className="images_block">
            <Img src={headerImg} />
            <Img src={backdropImg} />
            <a
              href={deep_link || "/"}
              rel="noopener noreferrer"
              target="_BLANK"
            >
              <Icon name="external" /> {url}
            </a>
          </div>
          <table width="100%">
            <tbody>
              <tr>
                <td width="40">
                  <Img src={logo_url || brandImg} />
                </td>
                <td className="ad_body">
                  <div>
                    <Label
                      color="yellow"
                      size="tiny"
                    >
                      {intl.formatMessage({
                        defaultMessage: "AD",
                        id: "LABEL_AD",
                      })}
                    </Label>
                    <span>
                      {brand_name ||
                        intl.formatMessage({
                          defaultMessage: "Your Brand Name",
                          id: "PLACEHOLDER_BRAND_NAME",
                        })}
                    </span>
                  </div>
                  <div>
                    {ad_title ||
                      intl.formatMessage({
                        defaultMessage: "Your Ad Title",
                        id: "PLACEHOLDER_AD_TITLE",
                      })}
                  </div>
                </td>
                <td width="110">
                  <Button
                    size="tiny"
                    style={{ backgroundColor: button_color || "orange" }}
                    type="button"
                  >
                    {button_text ||
                      intl.formatMessage({
                        defaultMessage: "YOUR TEXT",
                        id: "PLACEHOLDER_BUTTON_TEXT",
                      })}
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  },
  (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
  },
);

BannerCompanionComponent.propTypes = {
  brand_name: PropTypes.string.isRequired,
  ad_title: PropTypes.string.isRequired,
  logo_url: PropTypes.string.isRequired,
  button_color: PropTypes.string.isRequired,
  button_text: PropTypes.string.isRequired,
  deep_link: PropTypes.string.isRequired,
};

export default BannerCompanionComponent;
