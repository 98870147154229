import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { Menu } from "semantic-ui-react";

export const CreativesTabsMenuAdmin = () => {
  const intl = useIntl();

  return (
    <Menu
      pointing
      secondary
    >
      <Menu.Item
        as={NavLink}
        to="/admin/onsite-creatives"
        content={intl.formatMessage({
          id: "LINK_ON_SITE_CREATIVES",
          defaultMessage: "On-Site Creatives",
        })}
      />

      <Menu.Item
        as={NavLink}
        content={intl.formatMessage({
          defaultMessage: "In-store DOOH Creatives",
          id: "LINK_INSTORE_DOOH_CREATIVES",
        })}
        to="/admin/in-store-dooh"
      />
    </Menu>
  );
};

export default CreativesTabsMenuAdmin;
