import PropTypes from "prop-types";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { Loader } from "semantic-ui-react";
import { DynamicIframe } from "../../common/dynamicIframe";
import { VastPlayer } from "../index/vast_tag_player";

const CreativePreviewerRenderer = ({ creativePreviewData = {} }) => {
  const intl = useIntl();
  const { data } = creativePreviewData;

  // Render content based on file type
  const RenderContent = useCallback(() => {
    switch (data.creative_type) {
      case "display":
        return (
          <DynamicIframe
            creativeID={data.id}
            iframeHeight={data.height || "100%"}
            iframeWidth={data.width || "100%"}
            iframeContent={data.html_tag || ""}
            iframeTitle={intl.formatMessage({
              defaultMessage: "Creative Preview",
              id: "LABEL_CREATIVE_PREVIEW",
            })}
            isMRAID={data.mraid}
          />
        );

      case "video":
        return <VastPlayer vastTagUrl={data.vast_url} />;

      default:
        return <p>Unsupported creative type</p>;
    }
  }, [data, intl]);

  // Wait for the creativePreviewData to be available
  if (!data) {
    // Show loader while waiting for data
    return (
      <Loader
        active
        className="workaround"
        content="Loading preview..."
        inline="centered"
      />
    );
  }

  return (
    <div>
      <RenderContent />
    </div>
  );
};

CreativePreviewerRenderer.propTypes = {
  creativePreviewData: PropTypes.shape({
    data: PropTypes.shape({
      // biome-ignore lint/style/useNamingConvention: payload attribute
      creative_type: PropTypes.string,
      height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      tag: PropTypes.string,

      // biome-ignore lint/style/useNamingConvention: payload attribute
      vast_url: PropTypes.string,

      // biome-ignore lint/style/useNamingConvention: payload attribute
      video_url: PropTypes.string,
    }),
  }),
};

export { CreativePreviewerRenderer };
