import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Confirm, Form, Select } from "semantic-ui-react";
import { strategyPlacementTypeOptions } from "../../fixtures/index";
import StrategyManageContext from "../context";
import { walmartMXTranslations } from "../../../../translations/walmartMXTranslations";
import { Config } from "../../../../config/api";

export const MarketingObjectiveStrategyPlacementTypeField = ({ errors, isT1Edit, isEdit, onChange }) => {
  const context = React.useContext(StrategyManageContext);
  const [confirmOpened, setConfirmOpened] = useState(false);
  const [strategyPlacementType, setStrategyPlacementType] = useState({
    previous: context?.strategy?.strategy_placement_type,
    current: context?.strategy?.strategy_placement_type,
  });
  const intl = useIntl();

  const handleStrategyPlacementTypeChange = useCallback(
    (_e, input) => {
      const { value } = input;

      // create mode, no need to confirm so just update value
      if (!isEdit) {
        onChange(null, { name: "strategy_placement_type", value: value });
        setStrategyPlacementType((prev) => ({
          ...prev,
          current: value,
        }));
        return;
      }

      // edit mode, value has changed therefore need to confirm
      if (strategyPlacementType.previous !== value) {
        setConfirmOpened(true);
        setStrategyPlacementType((prev) => ({
          ...prev,
          current: value,
        }));
      // edit mode, value has not changed so set back to initial starting value
      } else {
        onChange(null, { name: "strategy_placement_type", value: strategyPlacementType.previous });
        setStrategyPlacementType({
          previous: strategyPlacementType.previous,
          current: strategyPlacementType.previous,
        });
      }
    },
    [isEdit, strategyPlacementType.previous, onChange],
  );

  const handleStrategyPlacementTypeConfirm = useCallback(() => {
    onChange(null, { name: "strategy_placement_type", value: strategyPlacementType.current });
    setConfirmOpened(false);
  }, [onChange, strategyPlacementType.current]);

  const handleStrategyPlacementTypeCancel = useCallback(() => {
    setConfirmOpened(false);
    setStrategyPlacementType({
      previous: strategyPlacementType.previous,
      current: strategyPlacementType.previous,
    });
  }, [strategyPlacementType.previous]);

  return (
    <Form.Field
      inline
      error={Object.hasOwn(errors, "strategy_placement_type")}
      required
    >
      <label>
        {intl.formatMessage({
          id: "LABEL_STRATEGY_TYPE",
          defaultMessage: "Strategy Type",
        })}
      </label>
      <Confirm
        open={confirmOpened}
        content={intl.formatMessage({
          id: "BODY_CONFIRM_CHANGE_STRATEGY_PLACEMENT_TYPE",
          defaultMessage: "Please note that changing Strategy Type will clear selected Supply and Creatives",
        })}
        cancelButton={intl.formatMessage({
          id: "BTN_CANCEL",
          defaultMessage: "Cancel",
        })}
        confirmButton={intl.formatMessage({
          id: "BTN_OK",
          defaultMessage: "OK",
        })}
        onCancel={handleStrategyPlacementTypeCancel}
        onConfirm={handleStrategyPlacementTypeConfirm}
        id="media_channel_confirm"
        size="mini"
      />
      <Select
        className="margin__right20"
        disabled={isT1Edit}
        name="strategy_placement_type"
        options={strategyPlacementTypeOptions(intl)}
        onChange={handleStrategyPlacementTypeChange}
        value={strategyPlacementType.current}
      />
      {Config.public_client === "walmart" && strategyPlacementType.current === 'ctv' &&
        <p style={{ fontSize: 'inherit', fontWeight: 'inherit', fontStyle: 'italic'}}> {walmartMXTranslations.strategyCTVHelperText} </p>
      }
      <div className="custom-error">{errors.strategy_placement_type}</div>
    </Form.Field>
  );
};
