import { useIntl } from "react-intl";
import { Icon, Popup } from "semantic-ui-react";
import { Config } from "../../../../config/api";
import { getReadableClientName } from "../../../../libs/common_utils";

const CreativeTypeLabel = ({ onsiteAndInstoreDOOH }) => {
  const intl = useIntl();
  const defaultMessage = onsiteAndInstoreDOOH
    ? "On-site and In-store DOOH creatives should follow the guidelines set by Walmart and require additional approval before assigning them to strategies."
    : "On-site creatives should follow the guidelines set by walmart and requires additional approval, before assigning it on strategies.";
  const id = onsiteAndInstoreDOOH ? "HINT_ON_SITE_INSTORE_DOOH_CREATIVE" : "HINT_ON_SITE_CREATIVE";
  const popupContent = intl.formatMessage(
    {
      defaultMessage: defaultMessage,
      id: id,
    },
    {
      clientName: getReadableClientName(Config.public_client),
    },
  );

  const helpIcon = (
    <Icon
      name="help circle"
      className="cursor-help"
      tabIndex="0"
      aria-label={intl.formatMessage({
        defaultMessage: "Learn more about creative types",
        id: "ARIA_HELP_CREATIVE_TYPE",
      })}
    />
  );

  return (
    <span style={{ display: "inline-flex", alignItems: "center" }}>
      {intl.formatMessage({
        defaultMessage: "Creative Type",
        id: "LABEL_CREATIVE_TYPE",
      })}
      &nbsp;
      <Popup
        content={popupContent}
        inverted
        position="top center"
        size="mini"
        trigger={helpIcon}
      />
    </span>
  );
};

export default CreativeTypeLabel;
