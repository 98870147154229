import { useCallback, useEffect, useRef, useState } from "react";
import { Divider, Header, Message, Segment } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useIntl } from "react-intl";
import CreativesService from "../../../services/creatives";
import { default_button_text } from "../fixtures";
import CreativeDisplayForm from "../form/display";
import CreativeVideoForm from "../form/video";

let initialData = {
  // biome-ignore lint/style/useNamingConvention: <server params>
  advertiser_id: null,

  // biome-ignore lint/style/useNamingConvention: <server params>
  clickthrough_url: "",

  // biome-ignore lint/style/useNamingConvention: <server params>
  landing_page_url: "",
  status: 1,
  title: "",

  // biome-ignore lint/style/useNamingConvention: <server params>
  end_date: "",

  // biome-ignore lint/style/useNamingConvention: <server params>
  start_date: "",
};

/**
 * Creative create page component
 * @param history
 * @param {string} creative_type
 * @return {JSX.Element}
 * @constructor
 */
const CreativeCreatePage = ({ history, creative_type }) => {
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);

  const intl = useIntl();
  const service = new CreativesService();
  const _isMounted = useRef(true);
  const isVideo = creative_type === "video";
  const { whitelabel1PDisplayCreative, whitelabel3PDisplayCreative, whitelabelDoubleCreativeApproval } = useFlags();
  const assetRestriction = !(whitelabel1PDisplayCreative && whitelabel3PDisplayCreative);
  const backUrl = isVideo ? "/creatives/video" : "/creatives/display";

  if (isVideo) {
    initialData = Object.assign(initialData, {
      // biome-ignore lint/style/useNamingConvention: <server params>
      ad_title: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      brand_name: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      button_color: "#f2711c",

      // biome-ignore lint/style/useNamingConvention: <server params>
      button_text_type: "standard",

      // biome-ignore lint/style/useNamingConvention: <server params>
      button_text: default_button_text(intl)[0].value,

      // biome-ignore lint/style/useNamingConvention: <server params>
      companion_ad: 0,

      // biome-ignore lint/style/useNamingConvention: <server params>
      creative_type: whitelabelDoubleCreativeApproval ? "on_site" : "off_site",

      // biome-ignore lint/style/useNamingConvention: <server params>
      custom_vast_url: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      deep_link: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      end_date: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      external_identifier: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      logo_url: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      third_party: 0,

      instoreDOOH: 0,
    });
  } else {
    initialData = Object.assign(initialData, {
      // biome-ignore lint/style/useNamingConvention: <server params>
      ad_format: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      ad_server: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      creative_type: whitelabelDoubleCreativeApproval ? "on_site" : "off_site",
      dimension: "standard",
      dimensions: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      end_date: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      expansion_direction: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      expansion_trigger: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      file_type: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      is_expandable: 0,

      // biome-ignore lint/style/useNamingConvention: <server params>
      mraid_type: "",
      mraid: 0,

      // biome-ignore lint/style/useNamingConvention: <server params>
      tag_type: "",
      tag: "",

      // biome-ignore lint/style/useNamingConvention: <server params>
      third_party: !assetRestriction || whitelabel1PDisplayCreative ? 0 : 1,

      // biome-ignore lint/style/useNamingConvention: <server params>
      tpas_tag_id: "",
    });
  }

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  /**
   * navigate user back
   */
  const handleCancel = useCallback(() => {
    history.push(backUrl);
  }, [backUrl, history]);

  /**
   * check if its On-Site or In-store DOOH
   * @param {object} values
   * @returns {boolean}
   */
  const isOnsiteOrInstoreDOOHClicked = (creativeType) => {
    return whitelabelDoubleCreativeApproval && (creativeType === "on_site" || creativeType === "instoreDOOH");
  };

  /**
   * form submit handler
   * @param {object} values
   * @param {File} file
   */
  const handleSubmit = async (values, file, creativeType) => {
    let backUrl = "";
    setLoading(true);

    const form = new FormData();
    for (const k of Object.keys(values)) {
      form.append(k, values[k]);
    }

    if (file) {
      form.append("creative_file", file);
    }

    try {
      if (isOnsiteOrInstoreDOOHClicked(creativeType)) {
        const creativePaths = {
          instoreDOOH: "/creatives/in-store-dooh",
          on_site: "/creatives/onsite",
        };
        backUrl = creativePaths[creativeType];
        await service.createLocal(form);
      } else if (isVideo) {
        backUrl = "/creatives/video";
        await service.createVideo(form);
      } else {
        backUrl = "/creatives/display";
        await service.createDisplay(form);
      }

      history.push(backUrl, { action: "created" });
    } catch (e) {
      setServerError(e.error.message);
    } finally {
      if (_isMounted.current) {
        setLoading(false);
      }
    }
  };

  return (
    <Segment basic>
      <Header as="h2">
        {isVideo
          ? intl.formatMessage({
              defaultMessage: "Create Video Creative",
              id: "HEADING_CREATE_VIDEO_CREATIVE",
            })
          : intl.formatMessage({
              defaultMessage: "Create Display Creative",
              id: "HEADING_CREATE_DISPLAY_CREATIVE",
            })}
      </Header>
      <Divider />
      <Message
        content={serverError}
        error
        hidden={!serverError}
        size="tiny"
        style={{ marginTop: "10px" }}
      />
      {isVideo ? (
        <CreativeVideoForm
          initialData={initialData}
          loading={loading}
          onCancel={handleCancel}
          onsite_enabled={whitelabelDoubleCreativeApproval}
          onSubmit={handleSubmit}
          submitText={intl.formatMessage({
            defaultMessage: "Create Creative",
            id: "BTN_CREATE_CREATIVE",
          })}
        />
      ) : (
        <CreativeDisplayForm
          assetRestriction={assetRestriction}
          initialData={initialData}
          loading={loading}
          onCancel={handleCancel}
          onsite_enabled={whitelabelDoubleCreativeApproval}
          onSubmit={handleSubmit}
          submitText={intl.formatMessage({
            defaultMessage: "Create Creative",
            id: "BTN_CREATE_CREATIVE",
          })}
        />
      )}
    </Segment>
  );
};

export default CreativeCreatePage;
