import lightFormatDate from "date-fns/lightFormat";
import parseISO from "date-fns/parseISO";
import subDays from "date-fns/subDays";
import groupBy from "lodash/groupBy";
import PropTypes from "prop-types";
import React, { useContext, useReducer, useRef, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { Button, Divider, Grid, Icon, Message, Pagination, Segment, Table } from "semantic-ui-react";
import uuid from "uuid/v4";
import { Config } from "../../../config/api";
import { formatMoney, formatNumber } from "../../../libs/common_utils";
import Pager from "../../../models/pager";
import ReportModel from "../../../models/report";
import ReportsService from "../../../services/reports";
import AVAILABLE_COLUMNS from "../fixtures/columns";
import REPORT_TYPES from "../fixtures/report-types";
import ColumnPicker from "./column-picker";
import ReportGridContext from "./context";
import ReportParamsForm from "./form";
import { reportActions, reportGridReducer } from "./reducers";

const initialState = {
  items: null,
  allItems: null,
  pager: new Pager(Config.reportingRowsPerPage),
  sortedBy: null,
  sortDirection: null,
};

const DATE_NOW = new Date();
const INITIAL_START_DATE = subDays(DATE_NOW, 7);
const INITIAL_END_DATE = subDays(DATE_NOW, 1);

/**
 * Downloads blob as a file with a given name.
 * @param {Blob} blob
 * @param {string} filename
 */
function downloadBlob(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement("a");
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }, 0);
  }
}

const ReportPage = ({ history, match }) => {
  const backUrl = "/reports";

  const intl = useIntl();

  const reportDefaults = REPORT_TYPES[match.params.report_slug];
  const hiddenColumns = new Set(
    Object.hasOwn(Config.hideReportColumns, match.params.report_slug)
      ? Config.hideReportColumns[match.params.report_slug]
      : [],
  );

  const services = React.useRef(new Map([["reports", new ReportsService()]]));
  const [state, dispatch] = useReducer(reportGridReducer, initialState);

  /** Can be "json", "csv", or null, depending on which content type is being loaded. */
  const [showLoaderFor, setShowLoaderFor] = useState(null);
  const _isMounted = useRef(false);
  const [serverError, setServerError] = useState(null);
  const [reportParams, setReportParams] = useState({
    start_date: INITIAL_START_DATE,
    end_date: INITIAL_END_DATE,
    advertiser_id: null,
    campaign_ids: [],
    reporting_period: "last_30_days",
    time_rollup: "by_day",
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <run on mount>
  useEffect(() => {
    _isMounted.current = true;
    return () => {
      state.pager.reset();
      _isMounted.current = false;
    };
  }, []);

  const defaultColumns = Object.hasOwn(Config.defaultReportColumns, match.params.report_slug)
    ? Config.defaultReportColumns[match.params.report_slug].filter((name) => !hiddenColumns.has(name))
    : reportDefaults
      ? reportDefaults.defaultColumns.filter((name) => !hiddenColumns.has(name))
      : [];
  const [selectedColumns, setSelectedColumns] = useState(defaultColumns);
  const allowedColumns = reportDefaults
    ? Object.fromEntries(
        Object.entries(AVAILABLE_COLUMNS).filter(
          ([name, _]) => reportDefaults.allowedColumns.includes(name) && !hiddenColumns.has(name),
        ),
      )
    : [];

  const getPage = (_e, { activePage }) => {
    dispatch({ type: reportActions.GET_PAGE, data: activePage });
  };

  const setSorting = (column) => {
    dispatch({ type: reportActions.CHANGE_SORT, data: column });
  };

  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <refactoring>
  const runReport = async (output = "json") => {
    const hasMissingDates = !(reportParams.start_date && reportParams.end_date);
    if (reportParams.reporting_period === "custom" && hasMissingDates) {
      return setServerError(
        intl.formatMessage({
          id: "ERROR_EMPTY_REPORT_DATES",
          defaultMessage: "Please select report date range.",
        }),
      );
    }
    if (!reportParams.advertiser_id) {
      return setServerError(
        intl.formatMessage({
          id: "ERROR_EMPTY_ADVERTISER_ID",
          defaultMessage: "Please select an advertiser.",
        }),
      );
    }

    const { dimension: dimensions = [], metric: metrics = [] } = groupBy(
      selectedColumns,
      (columnId) => AVAILABLE_COLUMNS[columnId].kind,
    );
    if (dimensions.includes("start_date")) {
      const index = dimensions.indexOf("start_date");
      if (index > -1) {
        dimensions.splice(index, 1);
      }
    }
    if (dimensions.includes("end_date")) {
      const index = dimensions.indexOf("end_date");
      if (index > -1) {
        dimensions.splice(index, 1);
      }
    }
    if (dimensions.length === 0) {
      return setServerError(
        intl.formatMessage({
          id: "ERROR_EMPTY_DIMENSIONS",
          defaultMessage: "Please select some dimension columns (e. g. Agency Name).",
        }),
      );
    }
    if (metrics.length === 0) {
      return setServerError(
        intl.formatMessage({
          id: "ERROR_EMPTY_METRICS",
          defaultMessage: "Please select some metric columns (e. g. CTR).",
        }),
      );
    }

    // Used for formatting monetary values in other columns:
    if (output === "json" && !dimensions.includes("campaign_currency_code")) {
      dimensions.push("campaign_currency_code");
    }
    const reportRequest = new ReportModel({
      type: reportDefaults.type,
      start_date: reportParams.start_date,
      end_date: reportParams.end_date,
      advertiser_id: reportParams.advertiser_id,
      campaign_ids: reportParams.campaign_ids,
      dimensions: dimensions,
      metrics: metrics,
      output: output,
      time_rollup: reportParams.time_rollup,
      reporting_period: reportParams.reporting_period,
    });
    setShowLoaderFor(output);
    setServerError(null);
    try {
      const service = services.current.get("reports");

      if (output === "json") {
        const reportContent = await service.run(reportRequest.toJson());
        dispatch({ type: reportActions.INIT, data: reportContent.data });
      } else {
        const reportContent = await service.runDownload(reportRequest.toJson());
        const blob = new Blob([reportContent], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        downloadBlob(blob, "report.xlsx");
      }
    } catch (e) {
      // console.log(e);
      setServerError(e.error.message);
    } finally {
      if (_isMounted.current) {
        setShowLoaderFor(null);
      }
    }
  };

  const handleReportParamsChange = (nextValues) => {
    setReportParams((prevValues) => ({ ...prevValues, ...nextValues }));
  };

  const handleSelectedColumnsChange = (columns) => {
    setSelectedColumns(columns);
  };

  if (!Object.hasOwn(REPORT_TYPES, match.params.report_slug)) {
    history.push(backUrl, {
      action: "error",
      msg: `Report ${match.params.report_slug} not found`,
    });
    return null;
  }

  return (
    <Segment
      loading={showLoaderFor === "json"}
      basic
      style={{ padding: "0" }}
    >
      <ReportGridContext.Provider
        value={{
          getPage,
          setSorting,
          selectedColumns,
          sortedBy: state.sortedBy,
          sortDirection: state.sortDirection,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <div style={{ flex: 1 }}>
            <h1>
              <NavLink
                to="/reports"
                title={intl.formatMessage({
                  id: "HINT_BACK_REPORTS",
                  defaultMessage: "Back to the Reports",
                })}
                className="black"
              >
                <Icon
                  name="arrow left"
                  size="small"
                />{" "}
                {intl.formatMessage({
                  id: reportDefaults.messageId,
                  defaultMessage: reportDefaults.title,
                })}
              </NavLink>
            </h1>
          </div>
          <div>
            <Button
              positive
              compact
              type="button"
              onClick={() => runReport()}
            >
              {intl.formatMessage({
                id: "BTN_RUN_REPORT",
                defaultMessage: "Run Report",
              })}
            </Button>
            <Button
              loading={showLoaderFor === "csv"}
              compact
              type="button"
              onClick={() => runReport("csv")}
            >
              {intl.formatMessage({
                id: "BTN_DL_REPORT",
                defaultMessage: "Download Report",
              })}
            </Button>
          </div>
        </div>
        <Divider hidden />
        <Grid className="common_grid">
          <Grid.Row>
            <Grid.Column width={13}>
              <ReportParamsForm
                values={reportParams}
                onChange={handleReportParamsChange}
                onSubmit={runReport}
              />
              <Message
                style={{ marginTop: "10px" }}
                error
                hidden={!serverError}
                size="tiny"
                content={serverError}
              />

              <ReportGrid
                items={state.items}
                controls={{ pager: state.pager }}
              />
            </Grid.Column>
            <Grid.Column
              width={3}
              style={{ paddingInlineStart: 0 }}
            >
              <ColumnPicker
                options={allowedColumns}
                defaultOptions={defaultColumns}
                value={selectedColumns}
                onChange={handleSelectedColumnsChange}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ReportGridContext.Provider>
    </Segment>
  );
};

export default ReportPage;

/**
 * Render grid
 * @param {array} items
 * @param {object} controls
 * @return {jsx}
 * @constructor
 */
export const ReportGrid = ({ items, controls }) => {
  const intl = useIntl();
  const context = useContext(ReportGridContext);
  const shouldShowPagination = controls.pager.total_pages > 1 && context.selectedColumns.length > 0;

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <Table
          className="custom-table"
          sortable
        >
          <Table.Header>
            <Table.Row>
              {context.selectedColumns.map((columnId) => (
                <Table.HeaderCell
                  key={columnId}
                  textAlign="left"
                  sorted={context.sortedBy === columnId ? context.sortDirection : null}
                  onClick={() => context.setSorting(columnId)}
                >
                  {intl.formatMessage({
                    id: AVAILABLE_COLUMNS[columnId].messageId,
                    defaultMessage: AVAILABLE_COLUMNS[columnId].name,
                  })}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items === null ? (
              <ReportGridEmptyRow>
                {intl.formatMessage({
                  id: "EMPTY_REPORT_PARAMS",
                  defaultMessage: "Select parameters to generate the report",
                })}
              </ReportGridEmptyRow>
            ) : items.length ? (
              items.map((item) => {
                return (
                  <ReportGridItem
                    key={uuid()}
                    {...item}
                  />
                );
              })
            ) : (
              <ReportGridEmptyRow>
                {intl.formatMessage({
                  id: "EMPTY_REPORT",
                  defaultMessage: "Current report contains no data",
                })}
              </ReportGridEmptyRow>
            )}
          </Table.Body>
        </Table>
      </div>

      {shouldShowPagination && (
        <div style={{ textAlign: "right", marginTop: "1em" }}>
          <Pagination
            activePage={controls.pager.page}
            firstItem={null}
            lastItem={null}
            onPageChange={context.getPage}
            size="mini"
            totalPages={controls.pager.total_pages}
          />
        </div>
      )}
    </>
  );
};

/**
 * @param {*} props.children - empty state message
 */
const ReportGridEmptyRow = ({ children }) => (
  <Table.Row>
    <Table.Cell
      colSpan="5000"
      textAlign="center"
      style={{ paddingLeft: 0, paddingRight: 0 }}
    >
      <div
        style={{
          // Width of a <Grid.Column width={13} /> minus all the paddings:
          width: "calc(81.25vw - 2rem - .78571429em)",
          position: "absolute",
        }}
      >
        {children}
      </div>
      {/* A single whitespace to move scrollbar further below: */}
      &nbsp;
    </Table.Cell>
  </Table.Row>
);
ReportGridEmptyRow.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Generate grid item
 * @param {object} item
 * @return {*}
 * @constructor
 */
const ReportGridItem = (item) => {
  const context = useContext(ReportGridContext);

  return (
    <Table.Row>
      {context.selectedColumns.map((columnId) => (
        <Table.Cell key={columnId}>
          {Object.hasOwn(item, columnId) ? (
            <ReportGridValue
              type={AVAILABLE_COLUMNS[columnId].type}
              value={item[columnId]}
              row={item}
            />
          ) : (
            ""
          )}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

const FORMATTERS = {
  // Should IDs be linked with names perhaps?
  // E. g. advertiser_id + advertiser_name -> one Advertiser column
  id: (val) => val,
  string: (val) => val,
  "money-usd": (val, _row) => formatMoney(val, "USD", "en-US", 2),

  money: (val, row) => formatMoney(val, row.campaign_currency_code || Config.defaultCurrency, Config.defaultLocale, 2),

  datetime: (val) => (val === "NULL" || val == null ? "NULL" : lightFormatDate(parseISO(val), Config.dateTimeFormat)),
  count: (val) => formatNumber(val, Config.defaultLocale),

  // how many decimal places?
  percent: (val) => `${formatNumber(val, Config.defaultLocale, 2)}%`,
  ratio: (val) => formatNumber(val, Config.defaultLocale, 2),
  float: (val) => formatNumber(val, Config.defaultLocale, 2),
};

/**
 * Display report cell value depending on its' type.
 * @param {string} props.type - type of the cell
 * @param {string|null} props.value - value of the cell, if it is loaded
 * @param {object} props.row - current report row
 */
const ReportGridValue = ({ type, value = null, row }) => {
  const formatter = FORMATTERS[type];
  if (!formatter) {
    console.error("[ReportGridValue] No formatter available for", type);
  }
  return formatter ? formatter(value, row) : `#WAT?${type}`;
};
