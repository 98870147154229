import { removeEmptyKeys } from "../libs/common_utils.js";

export default class StrategiesBulkEditModel {
  constructor({
    ids = null,
    status = null,
    pacingType = null,
    pacingInterval = null,
    pacingAmount = null,
    frequencyOptimization = null,
    frequencyType = null,
    frequencyAmount = null,
    frequencyInterval = null,
    minBid = null,
    maxBid = null,
  } = {}) {
    this.ids = ids;
    this.status = status !== null ? Number.parseInt(status) : null;
    this.pacing_type = pacingType;
    this.pacing_interval = pacingInterval;
    this.pacing_amount = pacingAmount !== null ? Number.parseInt(pacingAmount) : null;
    this.frequency_optimization = frequencyOptimization;
    this.frequency_type = frequencyType;
    this.frequency_amount = frequencyAmount !== null ? Number.parseInt(frequencyAmount) : null;
    this.frequency_interval = frequencyInterval;
    this.min_bid = minBid !== null ? Number.parseFloat(minBid) : null;
    this.max_bid = maxBid !== null ? Number.parseFloat(maxBid) : null;

    removeEmptyKeys(this);
  }
}
