import { subDays, subYears } from "date-fns";
import escapeRegExp from "lodash/escapeRegExp";
import PropTypes from "prop-types";
import React, { useState, useLayoutEffect, useEffect } from "react";
import DatePicker from "react-datepicker";
import { useIntl } from "react-intl";
import { Button, Dropdown, Form, Label } from "semantic-ui-react";
import { Config } from "../../../config/api";
import "react-datepicker/dist/react-datepicker.css";
import { getReportingPeriods, getTimeRollUps } from "../../../libs/common_utils";
import { useDebounce, useMounted } from "../../../libs/component_utils";
import HttpConnect from "../../../libs/http_connect";
import AgenciesService from "../../../services/agencies";
import CampaignsService from "../../../services/campaign";
import T1Service from "../../../services/t1";

const DATE_NOW = new Date();
const MINIMAL_START_DATE = subYears(DATE_NOW, 1);
const MAXIMAL_END_DATE = subDays(DATE_NOW, 1);

/**
 * Same as <Dropdown multiple />, but wraps labels in a scrollable container.
 */
class ScrollableDropdown extends Dropdown {
  constructor(...params) {
    super(...params);

    const prevRenderLabels = this.renderLabels;
    const prevRenderSearchInput = this.renderSearchInput;
    const prevRenderSearchSizer = this.renderSearchSizer;

    this.renderLabels = () => {
      return (
        <div className="scrollable-droprown-contents">
          {prevRenderLabels() || this.props.defaulttrigger}
          {prevRenderSearchInput()}
          {prevRenderSearchSizer()}
        </div>
      );
    };
    this.renderSearchInput = () => null;
    this.renderSearchSizer = () => null;
    this.renderText = () => null;

    // Not specific to ScrollableDropdown, but it would be a sane behaviour
    // not to clear search query in most of our usecases.
    // biome-ignore lint/suspicious/noEmptyBlockStatements: <refactoring needed for why it was used like this>
    this.clearSearchQuery = () => {};
  }
}

const ReportParamsForm = ({ values, onChange, onSubmit }) => {
  const intl = useIntl();
  const reportingPeriod = getReportingPeriods(intl);
  const timeRollup = getTimeRollUps(intl);

  const services = React.useRef(
    new Map([
      ["t1", new T1Service()],
      ["agencies", new AgenciesService()],
      ["campaigns", new CampaignsService()],
    ]),
  );

  const _isMounted = useMounted();
  const agency = services.current.get("agencies").getSelectedAgency() || 0;

  /* Initial advertiser / campaign lists for empty search query: */
  const initialAdvertisers = React.useRef(null);
  const initialCampaigns = React.useRef(null);

  const [advertisersLoading, setAdvertisersLoading] = useState(true);
  const [campaignsLoading, setCampaignsLoading] = useState(false);

  const updateValues = onChange;

  const handleStartDateChange = (date) => {
    // biome-ignore lint/style/useNamingConvention: <server prop>
    if (date) updateValues({ start_date: date });
  };

  const handleEndDateChange = (date) => {
    // biome-ignore lint/style/useNamingConvention: <server prop>
    if (date) updateValues({ end_date: date });
  };

  // loaded page data
  const [advertisers, setAdvertisers] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignSearchQuery, setCampaignSearchQuery] = useState("");

  /**
   * initially loading advertisers list
   */
  React.useEffect(() => {
    performSearchAdvertisers("");
  }, []);

  /**
   * Handle advertiser dropdown × button click.
   * If called directly, just reset advetisers.
   * @param {Event} e
   */
  const resetAdvertisers = (e) => {
    if (
      initialAdvertisers.current === null ||
      (e && !(e.target.tagName.toLowerCase() === "i" && e.target.classList.contains("clear")))
    ) {
      return;
    }

    HttpConnect.cancelRequest();

    setAdvertisersLoading(false);
    setCampaignsLoading(false);
    setAdvertisers(initialAdvertisers.current);
  };

  /**
   * reset selected campaigns to default state
   */
  const resetSelectedCampaigns = () => {
    clearCampaignsSearchQuery();
    resetCampaigns();

    updateValues({
      // biome-ignore lint/style/useNamingConvention: <server prop>
      campaign_ids: [],
    });
  };

  /**
   * reset campaign query
   */
  const clearCampaignsSearchQuery = () => {
    setCampaignSearchQuery("");
  };

  /**
   * Handle campaign dropdown × button click.
   * If called directly, just reset campaigns.
   * @param {Event} e
   */
  const resetCampaigns = (e) => {
    if (
      initialCampaigns.current === null ||
      (e && !(e.target.tagName.toLowerCase() === "i" && e.target.classList.contains("clear")))
    ) {
      return;
    }

    HttpConnect.cancelRequest();
    setAdvertisersLoading(false);
    setCampaignsLoading(false);
    const selectedCampaigns = campaigns.filter(
      ({ key }) => values.campaign_ids.includes(key) && !initialCampaigns.current.map(({ key }) => key).includes(key),
    );
    setCampaigns([...selectedCampaigns, ...initialCampaigns.current]);
  };

  // Attach reset handlers:
  // biome-ignore lint/correctness/useExhaustiveDependencies: <run once only>
  useLayoutEffect(() => {
    document.querySelector("[data-id='advertisers']").addEventListener("click", resetAdvertisers);
    document.querySelector("[data-id='campaigns']").addEventListener("click", resetCampaigns);

    return () => {
      document.querySelector("[data-id='advertisers']").removeEventListener("click", resetAdvertisers);
      document.querySelector("[data-id='campaigns']").removeEventListener("click", resetCampaigns);
    };
  }, []);

  /**
   * Load advertisers from backend or, if possible, cache.
   * @param {string} query
   */
  const performSearchAdvertisers = (query) => {
    const service = services.current.get("t1");

    const queryTrimmed = query.trim();
    if (queryTrimmed === "" && initialAdvertisers.current !== null) {
      return resetAdvertisers();
    }

    (async () => {
      HttpConnect.cancelRequest();
      setCampaignsLoading(false);

      setAdvertisersLoading(true);
      const response = await service.advertisers(agency, queryTrimmed);
      const formattedData = response.data.map(({ id, title }) => ({
        key: id,
        text: title,
        value: id,
      }));

      if (queryTrimmed === "" && initialAdvertisers.current === null) {
        initialAdvertisers.current = formattedData;
      }

      if (!_isMounted.current) {
        return;
      }

      setAdvertisers(formattedData);

      if (response.status === "Error") {
        console.error("[performSearchAdvertisers]", response.error.message);
      }
      if (query === "" && response.data.length === 1) {
        // biome-ignore lint/style/useNamingConvention: <server prop>
        updateValues({ advertiser_id: response.data[0].id });
      }
      setAdvertisersLoading(false);
    })();
  };

  const performSearchAdvertisersDebounced = useDebounce(Config.search_debounce_delay, performSearchAdvertisers);

  /**
   * Load campaigns from backend or, if possible, cache.
   * @param {string} query
   */
  const performSearchCampaigns = async (query) => {
    const service = services.current.get("campaigns");

    const queryTrimmed = query.trim();
    if (queryTrimmed === "" && initialCampaigns.current !== null) {
      return resetCampaigns();
    }

    try {
      if (!values.advertiser_id) return;

      HttpConnect.cancelRequest();
      setAdvertisersLoading(false);

      setCampaignsLoading(true);

      const params = {};
      if (queryTrimmed) params.campaign_name = queryTrimmed;

      const campaignsResp = await service.getByAdvertiser(values.advertiser_id, params);
      const nextCampaigns = campaignsResp.data.map(({ id, title }) => ({
        key: id,
        text: title,
        value: id,
      }));

      const selectedCampaigns = campaigns.filter(
        ({ key }) => values.campaign_ids.includes(key) && !nextCampaigns.map(({ key }) => key).includes(key),
      );

      if (queryTrimmed === "" && initialCampaigns.current === null) {
        initialCampaigns.current = nextCampaigns;
      }

      setCampaigns([...selectedCampaigns, ...nextCampaigns]);
    } catch (e) {
      console.error(e);
    }

    setCampaignsLoading(false);
  };

  const performSearchCampaignsDebounced = useDebounce(Config.search_debounce_delay, performSearchCampaigns);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <major refactoring required>
  useEffect(() => {
    initialCampaigns.current = null;
    clearCampaignsSearchQuery();
    setCampaigns([]);
    (async () => {
      await performSearchCampaigns("");
    })();
  }, [values.advertiser_id]);

  const trimmingSearch = (options, query) => {
    const re = new RegExp(escapeRegExp(query.trim()), "i");
    return options.filter((opt) => re.test(opt.text));
  };

  return (
    <>
      <Form
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <Form.Group>
          <Form.Field className="filter-field">
            <label>
              {intl.formatMessage({
                id: "LABEL_REPORTING_PERIOD",
                defaultMessage: "Date Window",
              })}
            </label>
            <Dropdown
              data-id="reporting_period"
              selection
              options={reportingPeriod}
              name="reporting_period"
              value={values.reporting_period}
              selectOnNavigation={false}
              onChange={(_e, data) => {
                updateValues({
                  // biome-ignore lint/style/useNamingConvention: <server prop>
                  reporting_period: data.value,
                });
              }}
            />
          </Form.Field>

          {values.reporting_period === "custom" && (
            <Form.Group>
              <Form.Field className="filter-field">
                <label>
                  {intl.formatMessage({
                    id: "LABEL_DATE_RANGE",
                    defaultMessage: "Date Range",
                  })}
                </label>
                <Form.Field className="date-picker">
                  <DatePicker
                    selected={values.start_date}
                    minDate={MINIMAL_START_DATE}
                    maxDate={values.end_date}
                    onChange={handleStartDateChange}
                    dateFormat={Config.dateTimeFormat}
                  />
                </Form.Field>
              </Form.Field>
              <Form.Field className="filter-field">
                <label>&nbsp;</label>
                <Form.Field className="date-picker">
                  <DatePicker
                    selected={values.end_date}
                    minDate={values.start_date}
                    maxDate={MAXIMAL_END_DATE}
                    onChange={handleEndDateChange}
                    dateFormat={Config.dateTimeFormat}
                  />
                </Form.Field>
              </Form.Field>
            </Form.Group>
          )}

          <Form.Field className="filter-field">
            <label>
              {intl.formatMessage({
                id: "LABEL_AGGREGATION",
                defaultMessage: "Aggregation",
              })}
            </label>
            <Dropdown
              data-id="time_rollup"
              selection
              options={timeRollup}
              name="time_rollup"
              value={values.time_rollup}
              selectOnNavigation={false}
              onChange={(_e, data) => {
                updateValues({
                  // biome-ignore lint/style/useNamingConvention: <server prop>
                  time_rollup: data.value,
                });
              }}
            />
          </Form.Field>

          <Form.Field className="filter-field">
            <label>
              {intl.formatMessage({
                id: "LABEL_ADVERTISER",
                defaultMessage: "Advertiser",
              })}
            </label>
            <Dropdown
              data-id="advertisers"
              placeholder={intl.formatMessage({
                id: "HINT_ADVERTISER",
                defaultMessage: "Select advertiser",
              })}
              noResultsMessage={intl.formatMessage({
                id: "EMPTY_SEARCH_RESULTS",
                defaultMessage: "No results found",
              })}
              search={!campaignsLoading && trimmingSearch}
              selection
              loading={advertisersLoading}
              options={advertisers}
              name="advertiser_id"
              value={values.advertiser_id}
              clearable
              selectOnNavigation={false}
              onChange={(_e, data) => {
                updateValues({
                  // biome-ignore lint/style/useNamingConvention: <server prop>
                  advertiser_id: data.value,
                  // biome-ignore lint/style/useNamingConvention: <server prop>
                  campaign_ids: [],
                });
              }}
              onSearchChange={(_event, { searchQuery }) => {
                performSearchAdvertisersDebounced(searchQuery);
              }}
              onBlur={() => {
                if (advertisers.length === 0) {
                  setAdvertisers(initialAdvertisers.current);
                }
              }}
            />
          </Form.Field>

          <Form.Field
            className="filter-field"
            style={{ flexGrow: 1 }}
          >
            <label>
              {intl.formatMessage({
                id: "LABEL_CAMPAIGNS",
                defaultMessage: "Campaigns",
              })}{" "}
              <Button
                compact
                size="mini"
                className={values.campaign_ids.length ? "btn-clear" : "btn-clear hidden"}
                type="button"
                style={{ margin: "-0.589286em 0.5625em" }}
                onClick={resetSelectedCampaigns}
              >
                {intl.formatMessage({
                  id: "BTN_CLEAR",
                  defaultMessage: "Clear",
                })}
              </Button>
            </label>
            <ScrollableDropdown
              id="campaign_dropdown"
              data-id="campaigns"
              defaulttrigger={
                <Label style={{ float: "left" }}>
                  {intl.formatMessage({
                    id: "VALUE_ALL_CAMPAIGNS",
                    defaultMessage: "All campaigns",
                  })}
                </Label>
              }
              noResultsMessage={intl.formatMessage({
                id: "EMPTY_SEARCH_RESULTS",
                defaultMessage: "No results found",
              })}
              multiple
              search={!advertisersLoading && trimmingSearch}
              className="selection scrollable-droprown"
              disabled={!values.advertiser_id}
              loading={campaignsLoading}
              options={[
                {
                  key: "ALL_CAMPAIGNS",
                  text: intl.formatMessage({
                    id: "VALUE_ALL_CAMPAIGNS",
                    defaultMessage: "All campaigns",
                  }),
                  value: "ALL_CAMPAIGNS",
                },
                ...campaigns,
              ]}
              name="campaign_ids"
              value={values.campaign_ids}
              searchQuery={campaignSearchQuery}
              onChange={(_e, input) => {
                updateValues({
                  // biome-ignore lint/style/useNamingConvention: <server prop>
                  campaign_ids: input.value.includes("ALL_CAMPAIGNS") ? [] : input.value,
                });
              }}
              onSearchChange={(_event, { searchQuery }) => {
                setCampaignSearchQuery(searchQuery);
                performSearchCampaignsDebounced(searchQuery);
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </>
  );
};
ReportParamsForm.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

export default ReportParamsForm;
