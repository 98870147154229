import React from "react";
import {Checkbox, Dropdown, Form, Icon, Popup, Radio} from "semantic-ui-react";
import {revenue_attributions} from "../../fixtures";
import {RevenueType} from "../../../../models/enum/pixelRevenueType";
import PixelFormContext from "../context";
import CommonService from "../../../../services/common";

import "./index.css";

const PixelRevenueCreateComponent = ({values, errors, onChange, updateValues}) => {
  let _isMounted = React.useRef(false);
  const {intl} = React.useContext(PixelFormContext);

  const [pageData, setPageData] = React.useState({
    "loading": true,
    "currencies": []
  });
  const services = React.useRef(
    new Map([
      ["common", new CommonService()]
    ])
  );

  React.useEffect(() => {
    (async () => {
      try {
        const r = await services.current.get("common").getCurrencies();

        setPageData({
          ...pageData,
          "loading": false,
          "currencies": r.data.map(({ id, title, currency_code, symbol }) => ({
            "key": id,
            "text": `${title} (${currency_code})`,
            "value": currency_code,
            "symbol": symbol,
          }))
        });
      } catch (e) {
        console.log(e);
      }
    })();

    return () => {
      _isMounted.current = false;
    };
  }, []);

  /**
   * Revenue type change handler
   * @param {Event} e
   * @param {Object} input
   */
  const onRevenueTypeChange = (e, input) => {
    const {value} = input;
    if(value === RevenueType.FIXED) {
      updateValues({
        "revenue_currency": "",
        "revenue_type": value
      });
    } else {
      updateValues({
        "revenue_currency_fixed": "",
        "revenue_type": value
      });
    }
  };

  return <><Form.Field
    inline
    className="revenue-attribution"
  >
    <label htmlFor="revenue_enabled_id">
      {intl.formatMessage({
        id: "LABEL_REVENUE_PAIR",
        defaultMessage: "Revenue Attribution Pair",
      })}
      <Popup
        inverted
        content={intl.formatMessage({
          id: "HINT_REVENUE_PAIR",
          defaultMessage: "Set the Revenue and Currency attribution values. These settings will automatically enable reporting for campaigns using these pixels.",
        })}
        size="mini"
        trigger={
          <Icon
            name="help circle"
            className="cursor-help"
          />
        }
      />
    </label>
    <Checkbox
      value={Number(!values.revenue_enabled)}
      onChange={onChange}
      defaultChecked={Boolean(values.revenue_enabled)}
      name="revenue_enabled"
      id="revenue_enabled_id"
    />
  </Form.Field>
  {Boolean(values.revenue_enabled) && <Form.Group className="pixel-revenue-block">
    <Form.Field
      inline
      required
      error={errors.hasOwnProperty("revenue")}
    >
      <label>
        {intl.formatMessage({
          id: "LABEL_REVENUE",
          defaultMessage: "Revenue",
        })}
      </label>
      <Dropdown
        selection
        placeholder={intl.formatMessage({
          id: "PLACEHOLDER_SELECT_AN_ATTRIBUTE",
          defaultMessage: "Select an Attribute"
        })}
        options={revenue_attributions}
        name="revenue"
        value={values.revenue}
        onChange={onChange}
      />
      <div className="custom-error">{errors["revenue"]}</div>
    </Form.Field>
    <Form.Field
      inline
      required
      error={errors.hasOwnProperty("revenue_currency")}
    >
      <label>
        {intl.formatMessage({
          id: "LABEL_CURRENCY",
          defaultMessage: "Currency",
        })}
      </label>
      {values.revenue_type === RevenueType.FIXED? <Dropdown
        selection
        placeholder={intl.formatMessage({
          id: "PLACEHOLDER_SELECT_AN_ATTRIBUTE",
          defaultMessage: "Select an Attribute"
        })}
        name="revenue_currency_fixed"
        options={pageData.currencies}
        loading={pageData.pageLoading}
        value={values.revenue_currency_fixed}
        onChange={onChange}
      /> : <Dropdown
        selection
        placeholder={intl.formatMessage({
          id: "PLACEHOLDER_SELECT_AN_ATTRIBUTE",
          defaultMessage: "Select an Attribute"
        })}
        name="revenue_currency"
        value={values.revenue_currency}
        options={revenue_attributions.filter(node => node.value !== values.revenue)}
        onChange={onChange}
      />}
      <div className="custom-error">{errors["revenue_currency"]}</div>
    </Form.Field>
    <Form.Field className="radio-group-control">
      <Radio
        name="revenue_type"
        label={intl.formatMessage({
          id: "LABEL_REVENUE_FIXED",
          defaultMessage: "Fixed",
        })}
        value={RevenueType.FIXED}
        checked={values.revenue_type === RevenueType.FIXED}
        onClick={onRevenueTypeChange}
      />
      <Radio
        name="revenue_type"
        label={intl.formatMessage({
          id: "LABEL_REVENUE_DYNAMIC",
          defaultMessage: "Dynamic",
        })}
        value={RevenueType.DYNAMIC}
        checked={values.revenue_type === RevenueType.DYNAMIC}
        onClick={onRevenueTypeChange}
      />
      <Popup
        inverted
        content={intl.formatMessage({
          id: "HINT_REVENUE_TYPE",
          defaultMessage: "Accepts currency in form of XXXX.XX, where cents are separated by period (.). The following formats are not accepted: X,XXX.XXX, XXXX,XX, X XXX.XX or X XXX,XX.",
        })}
        size="mini"
        trigger={
          <Icon
            name="help circle"
            className="cursor-help"
          />
        }
      />
    </Form.Field>
  </Form.Group>}</>
};

export default PixelRevenueCreateComponent;
